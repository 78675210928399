import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddAddress from "../../AddAddress/AddAddress";
import dot from "../../../Assets/img/menu_vertical.png";
import { deleteUserAddress, getAddressList } from "../../../Slices/auth";
import { toast } from "react-toastify";
import { BulletList } from 'react-content-loader'
const MyAddress = () => {

const [showAddAddress, setShowAddAddress] = useState(false)
const [showEditAddress, setShowEditAddress] = useState(false)
const [selectedAddress, setSelectedAddress] = useState({})
const [address, setAddress] = useState()
const [loading, setLoading] = useState(true)
const dispatch = useDispatch()

const getUserAddress = () => {
  dispatch(getAddressList())
  .then((response) => {
    if(response.payload.status === 200){
      // toast.success(response.payload.data.message)
      setAddress(response.payload.data.result.data)
      setLoading(false)
    }else{
      toast.error(response.payload.data.message)
      setLoading(false)
    }
  })
  .catch((err) => {
    toast.error(err)
    setLoading(false)
  })
}

useEffect(() => {
  getUserAddress()
// eslint-disable-next-line
},[])

const deleteAddress = (id) => {
  dispatch(deleteUserAddress(id))
  .then((response) => {
    toast.success(response.payload.data.message)
    getUserAddress()
  })
  .catch((err) => {
    toast.error(err)
  })
}

  return (
    <div className='profDetailIn'>
      {/* <div className='profdetHeadText'></div> */}
      <div className='profdetHeadText'>
          <h3> <span className="backbt desknone"><i className="fa fa-angle-left"></i></span> My Address</h3>
        
      </div>
      <div className='formOut profdet'>
        <form>
          <div className='formInText mb0 pb10'>
            <div className='inpuWidth addInput'>
              <label>Saved Address</label>
            </div>
            <div className='inpuWidth addInput'>
              <label
                className='textRight colChange'
                style={{ cursor: "pointer" }}
                onClick={() => setShowAddAddress(!showAddAddress)}
              >
                <span>+ Add New Address</span>
              </label>
            </div>
          </div>
          <div className='formInText mb0'>
            {!loading ? 
            address.length > 0 ? (
              address.map((list) => {
                return (
                  <div className='inpuWidth'>
                    <label className='addresText'>
                      <h5>{list.address_type}</h5>
                      <p>{list.location}</p>
                      <p>{list.block_shop_no}</p>
                      <p>{list.city}</p>
                      <p>{list.state}</p>
                      <p>{list.country}</p>
                      <div className='edit'>
                        <img src={dot} />
                        <ul className='edtDel'>
                          <li>
                            <span onClick={() => {setShowEditAddress(true); setSelectedAddress(list)}}>Edit</span>
                          </li>
                          <li>
                            <span onClick={() => deleteAddress(list.id)}>
                              Delete
                            </span>
                          </li>
                        </ul>
                      </div>
                    </label>
                  </div>
                );
              })
            ) : (
              <strong>No Address Found</strong>
            ): <BulletList />}
          </div>
        </form>
      </div>
      {(showAddAddress || showEditAddress) && (
        <AddAddress
          showAddAddress={showAddAddress}
          showEditAddress={showEditAddress}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          setShowAddress={setShowAddAddress}
          setShowEditAddress={setShowEditAddress}
          getUserAddressList={getUserAddress}
        />
      )}
    </div>
  );
};

export default MyAddress;