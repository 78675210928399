import React from 'react'
// import SalonCate1 from "../../../Assets/img/icons8-squared_menu.png";
// import catImg2 from "../../../Assets/img/car-wash.png";
// import catImg3 from "../../../Assets/img/vacuum.png";
// import catImg4 from "../../../Assets/img/window-cleaning.png";
// import catImg5 from "../../../Assets/img/Polish.png";
// import catImg6 from "../../../Assets/img/tire.png";
// import catImg7 from "../../../Assets/img/window-cleaning.png";
// import catImg1 from "../../../Assets/img/window-cleaning.png";

import catImg2 from "../../../Assets/img/carWash.png";
import catImg3 from "../../../Assets/img/vacuum.png";
import catImg4 from "../../../Assets/img/window-cleaning.png";
import catImg5 from "../../../Assets/img/sponge.png";
import catImg6 from "../../../Assets/img/tire.png";
import catImg7 from "../../../Assets/img/window-cleaning.png";
import Slider from 'react-slick';

// import Slider from "react-slick";
import { Link } from 'react-router-dom';
import "./salonCatSlider.scss"

const SalonCatSlider = () => {

    const settings = {
      className: "slider variable-width",
      dots: false,
      infinite: true,
      arrows:false,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true
    };

  return (
    <section className="iconSlider servListout">
    <Slider {...settings}>
    <div className='subImgIn'>
      <Link to='' className='subImg'>
        <img className="categry-img" src={catImg7} alt="Interior Wash" />
        <h6 className="categry-name">Categry 1</h6>
      </Link>
    </div>
    <div className='subImgIn'>
      <Link to='' className='subImg'>
        <img className="categry-img" src={catImg6} alt="Car Polish" />
        <h6 className="categry-name">Categry 2</h6>
      </Link>
    </div>
    <div className='subImgIn'>
      <Link to='' className='subImg'>
        <img className="categry-img" src={catImg5} alt="img1" />
        <h6 className="categry-name"> Categry 3</h6>
      </Link>
    </div>
    <div className='subImgIn'>
      <Link to='' className='subImg'>
        <img className="categry-img" src={catImg3} alt="img1" />
        <h6 className="categry-name"> Categry 4</h6>
      </Link>
    </div>
    <div className='subImgIn'>
      <Link to='' className='subImg'>
        <img className="categry-img" src={catImg2} alt="img1" />
        <h6 className="categry-name"> Categry 5</h6>
      </Link>
    </div>
    <div className='subImgIn'>
      <Link to='' className='subImg'>
        <img className="categry-img" src={catImg5} alt="img1" />
        <h6 className="categry-name"> Categry 6</h6>
      </Link>
    </div>
    <div className='subImgIn'>
      <Link to='' className='subImg'>
        <img className="categry-img" src={catImg7} alt="img1" />
        <h6 className="categry-name"> Categry 7</h6>
      </Link>
    </div>
    <div className='subImgIn'>
      <Link to='' className='subImg'>
        <img className="categry-img" src={catImg2} alt="img1" />
        <h6 className="categry-name"> Categry 5</h6>
      </Link>
    </div>
    <div className='subImgIn'>
      <Link to='' className='subImg'>
        <img className="categry-img" src={catImg5} alt="img1" />
        <h6 className="categry-name"> Categry 6</h6>
      </Link>
    </div>
    <div className='subImgIn'>
      <Link to='' className='subImg'>
        <img className="categry-img" src={catImg7} alt="img1" />
        <h6 className="categry-name"> Categry 7</h6>
      </Link>
    </div>
  </Slider>
  </section>
  )
}

export default SalonCatSlider