import React, { useEffect, useRef, useState } from 'react'
import './MyBookingUp.scss'
import file from "../../Assets/img/file.png";
import { useDispatch, useSelector } from 'react-redux';
// import { setShowLocationModal, setShowOptionModal } from '../../slices/service';
import PricIncDec from "../Common/PricIncDec/PricIncDec.jsx";
import flat from "../../Assets/img/flat.png";
import home from "../../Assets/img/home.png";
import pic from "../../Assets/img/Bitmap.png";
import star from "../../Assets/img/star.png";
import clock from "../../Assets/img/clock.png"
import qr from "../../Assets/img/qr.png"
import topImg from "../../Assets/img/Group3.png";
import service from "../../Assets/img/service.png";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { detailsBooking, handleCancelRequst } from '../../Slices/auth';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Skeleton } from '@mui/material';

const MyBookRequest = () => {
  const [show, setShow] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const reason = useRef(null)
  const [details, setDetails] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const setCancelRequst = (e) => {
    const formData = new FormData();
    formData.append("cancellation_reason", (reason.current.value || ''));
    formData.append("code", details.code);

    dispatch(handleCancelRequst(formData))
      .then((response) => {
        if (response.payload.status === 200) {
          toast.success(response.payload.data.message, {
            position: "top-center",
          });
          getBookigDetails()
        } else {
          toast.error(response.payload.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(".....");
      });
  };
  const getBookigDetails = () => {
    dispatch(detailsBooking(id))
      .then((response) => {
        console.log(response)
        setIsLoading(false)
        setDetails(response.payload.result.data)
      })
      .catch(() => {
        setIsLoading(false)
        toast.error('Failed To Fetch Data')
      })
  }
  useEffect(() => {
    getBookigDetails()
  }, [])

  return (

    <div className="container">
      <div className=''>
        <div className="main_book_section">
          <div className='book_section_back'>
            <span className='' onClick={() => navigate(-1)}>
              <i class="fa fa-angle-left" ></i>
            </span>
            <p>Booking details</p>
          </div>
          {!isLoading ? <div className='ServDetailPop'>
            <div className='summarLeft'>
              <div className='summarLeftIn'>
                <div className='summarLeftImgText'>
                  <div className='summarLeftImg'>
                    <img src={details?.service_details?.service_image_url} />
                  </div>
                  <div className='headerFilterInfo'>
                    <strong className='serviceName'>{details?.service_details?.service_name}</strong>
                    <p>
                      {details?.service_details?.description}                    </p>
                    <span className='readLesMore'>Read less</span>
                  </div>
                </div>
              </div>

              <div className='bookInfo'>
                <ul className='bookINfoIn'>
                  <li>
                    <h5>Job Id</h5>
                    <span>{details?.code}</span>
                  </li>
                  <li>
                    <h5>CAR</h5>
                    <span>{details?.car_details?.car_name} ({details?.car_details?.car_fuel_type})</span>
                  </li>
                  <li>
                    <h5>PRICE</h5>
                    <span>₹ {details?.total_price || 0}</span>
                  </li>
                  <li>
                    <h5>STATUS</h5>
                    {
                      details?.status_name=='Accepetd' ?   <span className="accep">Accepted</span> : details?.status_name=='Payment Requested' ? <span className="payReq">Payment Requested</span> : details?.status_name=='Payment Completed' ?  <span className="payComp">Payment Completed</span> :  details?.status_name=='Running' ?  <span className='running'>{details?.status_name}</span>: <span className='req'>{details?.status_name}</span>
                    }
                   
                  </li>
                </ul>
              </div>
              <div className='summarLeftAdd'>
                <ul className='listRadio addlocationradio'>
                  <li>
                    <ul className='listRadio  locationradioList'>
                      <li className='date'>
                        <label>
                          <h5>{moment(details.start_date).format('DD MMM')}</h5>
                        </label>
                      </li>
                      <li className='date'>
                        <label>
                          <h5>{moment(details.start_time, 'ddd DD-MMM-YYYY, hh A').format('hh:mm A')}</h5>
                        </label>
                      </li>
                      <li className='date'>
                        <label>
                          <h5>         {details?.duration}  Minutes</h5>
                        </label>
                      </li>
                      <li className='date'>
                        <label>
                          <h5>At {details?.location_type}</h5>
                        </label>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className='listRadio addlocationradio'>
                  <li className='addText'>
                    <label>
                      <h5>{details?.location_type}</h5>
                      <p>{details?.location}</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>

            <div className="book_right_wrap">

              {show ?
                <div className='book_right_block'>
                  <h2>Payment Summary</h2>
                  <div className='book_right_sec'>
                    <div className='book_right_item'>
                      <div className='book_right_inner_item'>
                        <p>Item total</p>
                        <h2>₹ {details?.payment_summary_data?.item_total || 0} </h2>
                      </div>
                      <div className='book_right_inner_item'>
                        <p>Tax</p>
                        <h2>₹ {details?.payment_summary_data?.tax_amount || 0}</h2>
                      </div>
                      <div className='book_right_inner_item '>
                        <p className='green_p'>Coupon Discount</p>
                        <h2 className='green_h2'>- ₹ {details?.payment_summary_data?.coupon_discount || 0}</h2>
                      </div>
                    </div>
                    <div className='book_right_item'>
                      <div className='book_right_inner_item'>
                        <h2>Total</h2>
                        <h2>₹ {details?.payment_summary_data?.final_amount || 0}</h2>
                      </div>
                      <div className='book_right_inner_item'>
                        <p>{(details?.payment_summary_data?.first_installment || 0) / (details?.payment_summary_data?.final_amount || 1) * 100}% amount paid</p>
                        <h2>- ₹ {details?.payment_summary_data?.first_installment || 0}</h2>
                      </div>
                    </div>
                    <div className='book_right_item'>
                      <div className='book_right_inner_item'>
                        <h2>Remaining Amount to Pay</h2>
                        <h2>₹ {details?.payment_summary_data?.second_installment || 0}</h2>
                      </div>
                      <div className='book_right_inner_item'>
                        <span>(You save ₹ {details?.payment_summary_data?.coupon_discount || 0} on this booking.)</span>
                      </div>
                    </div>
                  </div>
                  {
                    details?.status_name == 'Accepetd' ? <div className='book_right_cancel'>
                      <a className='cancel' href='#0' onClick={() => setShow(!show)}>Cancel</a>
                    </div> : details?.status_name == 'Payment Requested' ? <div className='book_right_cancel'>
                      <Link className='remaining' to={`/payment/${details.code}`} >Pay Remaining Amount</Link>
                    </div> : details?.status_name == 'Payment Completed' ? <div className='book_right_cancel'>
                      <a className='otp' href='#0'>OTP : 458685</a>
                    </div> : null
                  }

                </div>
                :
                <div className='book_submit_block' >
                  <div className='book_submit_wrap'>
                    <div className='book_submit_sec'>
                      <p>Reason For Cancelation</p>
                      <span>20% Amount you paid will not be rufunded.</span>
                    </div>
                    <textarea placeholder='Enter Reason Here…' ref={reason}></textarea>
                    <div className='book_right_cancel' onClick={setCancelRequst}>
                      <a >Submit</a>
                    </div>
                  </div>
                </div>}

            </div>
          </div> : <>
          <div className='ServDetailPop'>

            <div className='summarLeft'>

              <Skeleton variant="rectangular" height={500} sx={{borderRadius:'12px'}} />

            </div>
            <div className="book_right_wrap">

              <Skeleton variant="rectangular"  height={500} sx={{borderRadius:'12px'}} />
            </div>
          </div>

          </>}
        </div>
      </div>
    </div>
  );
}

export default MyBookRequest;

