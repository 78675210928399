import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BulletList } from 'react-content-loader'
import { addUserCars, deleteUserCars, getAllUsersCar } from "../../../Slices/auth";
import { Link } from "react-router-dom";
import { getBrands, getFuel } from './../../../Slices/home';
import Geosuggest from "react-geosuggest";

const MyCars = () => {
  const dispatch = useDispatch();
  const [allCars, setAllCars] = useState({})
  const [loading, setLoading] = useState(true)
  const [showAddCar, setShowAddCar] = useState(false)
  const [carDetails, setCarDetails] = useState({});
  const { carBrand, carFuel } = useSelector((state) => state.home)

  const getCars = () => {
    dispatch(getAllUsersCar())
    .then((response) => {
      if(response.payload.status === 200){
        setLoading(false)
        // toast.success(response.payload.data.message)
        setAllCars(response.payload.data.result.data)
      }
    })
    .catch((err) => {
      setLoading(false)
      toast.error(err)
    })
  }

  const handleDeleteCars = (id) => {
    dispatch(deleteUserCars(id))
    .then((response) => {
      if(response.payload.status === 200){
        toast.success(response.payload.data.message)
        getCars()
      }
    })
    .catch((err) => {
      toast.error(err)
    })
  }

  const handleAddCars = () => {
    dispatch(addUserCars(carDetails))
    .then((response) => {
      if(response.payload.status === 200){
        toast.success(response.payload.data.message)
        setCarDetails({})
        setShowAddCar(false)
        getCars()
      }else{
        toast.error("Something went wrong.")
      }
    })
    .catch((err) => {
      toast.error(err)
    })
  };

  const getLanLngForAddress = (e) => {
    if (e !== undefined) {
        let results = e.gmaps.hasOwnProperty("address_components");
  
        if (results) {
  
            let results = e.gmaps.address_components;
            setCarDetails({...carDetails, 
              latitude: e.location.lat.toString(),
              longitude: e.location.lng.toString(),
              location: e.label,
            })
        }
    }
  };

  useEffect(() => {
    getCars()
    dispatch(getBrands())
    dispatch(getFuel())
    // eslint-disable-next-line
  }, [showAddCar]);

  return (
    <div className='profDetailIn'>
      <div className="profdetHead mycar">
        <div className='profdetHeadText'>
          <h3>My Cars</h3>
        </div>
        <div className='save' onClick={()=> setShowAddCar(!showAddCar)}>
          <span>Add Car</span>
        </div>
      </div>
      <div className='formOut profdet favBox'>
        <form>
          <div className='formInText mycars'>
            {!loading ?
            allCars.length > 0 ? allCars.map((list) =>
            <div className='inpuWidth' key={list.id}>
              <div className='summarLeftIn mycarsLeftIN'>
                <div className='summarLeftImgText'>
                  <ul className='carsInfoList'>
                    <li>
                      <h5>Car Brand</h5>
                      <p>{list.car_name}</p>
                    </li>
                    <li>
                      <h5>Fuel Type</h5>
                      <p>{list.car_fuel_type}</p>
                    </li>
                  </ul>
                </div>
                <div className='summarLeftCount'>
                  <button type="button" className='mb10 smbtn altsub' onClick={() => handleDeleteCars(list.id)} >REMOVE</button>
                </div>
              </div>
            </div>
            ): "No Data Found" : <BulletList />}
          </div>
        </form>
      </div>
      {showAddCar && 
      <div className='container addForm'>
      <section className='ServDetailPopOut'>
        <div className='contianer-fluid ServDetailPop'>
          <div className='closeIcon' onClick={() => setShowAddCar(!showAddCar)}>
            <i className='fa fa-times'></i>
          </div>
          <div className='serveBack addressRemoveMob' >
            <Link to={""} className='backbt'>
              <i className='fa fa-angle-left'></i>
            </Link>
            <h6>Add Car</h6>
          </div>
          <div className='ServDetailHead mobnone' >
            <h2>
              <Link to={"/"} className='backbt'>
                <i className='fa fa-angle-left'></i>
              </Link>
              {"Add Car"}
            </h2>
          </div>
          <div className='formOut'>
            <form>
              <div className='formInText'>
                <div className='inpuWidth'>
                  <label className="required">Car Brand</label>
                  <select name="car_name" onChange={(e) => setCarDetails({...carDetails, car_name: e.target.value})}>
                  <option value={""} selected>{"Select"}</option>
                    {carBrand?.map((list, key) => {
                      return <option key={key} value={list.car_name}>{list.car_name}</option>
                    })}
                  </select>
                </div>
                <div className='inpuWidth'>
                  <label>Fuel Type</label>
                  <select onChange={(e) => setCarDetails({...carDetails, car_fuel_type: e.target.value})}>
                    <option value={""} selected>{"Select"}</option>
                  {carFuel?.map((list, key) => {
                      return <option key={key} value={list.name}>{list.name}</option>
                    })}
                  </select>
                </div>
                {/* <div className="inputWidth locationIco " style={{flex: "0 0 100%", padding: "10px 15px"}}>
                  <label>Location</label>
                  <Geosuggest
                      placeholder={"Enter Location"}
                      initialValue={carDetails.location && carDetails.location}
                      onSuggestSelect={(e) => getLanLngForAddress(e)}
                      onChange={(e) => {
                          setCarDetails({...carDetails, location: e});
                      }}
                      required
                  />
                </div> */}
              </div>
              <div className='buttonsec'>
                <input type='button' value={'+ Add Car'} onClick={handleAddCars} />
              </div>
            </form>
          </div>
        </div>
      </section>
      </div>
      }
    </div>
  );
};

export default MyCars;
