import React, { useEffect, useState } from "react";
import "./PackageDetail.scss";
import ServDetailSlider from "../PackageDetailSlider/PackageDetailSlider";
import car from "../../Assets/img/clock.png";
import { useDispatch } from "react-redux";
import { showScheduleOpt, showServiceDetails, showAddressOpt } from "../../Slices/home";

import service from "../../Assets/img/service.png";
import { useNavigate, useParams } from "react-router";
import { detailsPackage } from "../../Slices/auth";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";

const PackageDetail = () => {
    //   useEffect(() => {
    //     window.scrollTo(0, 0);
    //   }, []);
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [details, setDetails] = useState(null)

    const getPackageDetails = () => {
        dispatch(detailsPackage(id))
            .then((response) => {
                console.log(response)
                setIsLoading(false)
                setDetails(response.payload.result.data)
            })
            .catch(() => {
                setIsLoading(false)
                toast.error('Failed To Fetch Data')
            })
    }
    useEffect(() => {
        getPackageDetails()
    }, [])

    const dispatch = useDispatch();
    const navigate = useNavigate()
    return (
        <section className='package_details'>
            <div className='container'>
                <div className="package_wrap">
                    <div className="package_title">
                        <h3> <span className=" " onClick={() => navigate(-1)}><i className="fa fa-angle-left"></i></span> My Bookings</h3>
                    </div>
                    <div className="package_sec">
                        {
                            details ? <>
                                <div className="package_left">
                                    <div className='summarLeftImgText'>
                                        <div className='summarLeftImg'>
                                            <img src={details?.package_details?.package_image_url} />
                                        </div>
                                        <div className='contInfoIn'>
                                            <h3>                            {details?.package_details?.package_name}
                                            </h3>
                                            <p><div
                                                dangerouslySetInnerHTML={{ __html: details?.package_details?.package_description }} /></p>                                </div>
                                    </div>
                                    <div className="package_time">
                                        <div>
                                            <div className="package_timein">
                                                <span>Service Usage</span>
                                                <h3>  {details?.service_usage + " Times"}</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="package_timein">
                                                <span>Remaining </span>
                                                <h3>  {details?.service_usage_remaining + " Times"}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="packge_body_wash">
                                        <div className='packge_body_img'>
                                            <div className='packge_img1'>
                                                <img src={details?.service_details?.service_image_url} />
                                            </div>
                                            <div className='packge_conte1'>
                                                <h3>{details?.service_details?.service_name}</h3>
                                                <p><div
                                                    dangerouslySetInnerHTML={{ __html: details?.service_details?.description }} /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="packge_price">
                                        <ul>
                                            <li>
                                                <p>Package Id</p>
                                                <h3>{details?.code}</h3>
                                            </li>
                                            <li>
                                                <p>PRICE</p>
                                                <h3>₹ {details?.payment_summary_data?.item_total}</h3>
                                            </li>
                                            <li>
                                                <p>Package Frequency</p>
                                                <h3>{details?.reminder_name}</h3>
                                            </li>
                                            <li>
                                                <p>CAR</p>
                                                <h3>{details?.car_details?.car_name} ({details?.car_details?.car_fuel_type})</h3>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="packge_office">
                                        <div className="packge_office_block">
                                            <h2>{details?.location_type}</h2>
                                            <p>{details?.location}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="package_right">
                                    <div className="package_summary">
                                        <h3>Payment Summary</h3>
                                        <ul>
                                            <li>
                                                <div>
                                                    <p>Item total</p>
                                                    <span>₹ {details?.payment_summary_data?.item_total}</span>
                                                </div>
                                                <div>
                                                    <p>Tax</p>
                                                    <span>₹ {details?.payment_summary_data?.tax_amount}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <h3>Total</h3>
                                                    <span>₹ {details?.payment_summary_data?.total}</span>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="package_history">
                                            <h3>History</h3>
                                            <ul>
                                                <li>
                                                    <div>
                                                        <p>Date & Time</p>
                                                        <p>Status</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h6>1 July 2022 at 11:00 AM </h6>
                                                        <h4>Completed</h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h6>2 July 2022 at 11:00 AM </h6>
                                                        <h4>Completed</h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h6>3 July 2022 at 11:00 AM </h6>
                                                        <h4>Completed</h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h6>4 July 2022 at 11:00 AM </h6>
                                                        <h4>Completed</h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h6>5 July 2022 at 11:00 AM </h6>
                                                        <h4>Completed</h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h6>6 July 2022 at 11:00 AM </h6>
                                                        <h4>Completed</h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h6>7 July 2022 at 11:00 AM </h6>
                                                        <h4>Completed</h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <h6>8 July 2022 at 11:00 AM </h6>
                                                        <h4>Completed</h4>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="package_btn">
                                            <Link to='/packageDetailSchedule' state={{ serviceDetails: details }}>Book Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </> : <>
                               

                                    <div className='package_left'>

                                        <Skeleton variant="rectangular" height={500} sx={{ borderRadius: '12px' }} />

                                    </div>
                                    <div className="package_right">

                                        <Skeleton variant="rectangular" height={500} sx={{ borderRadius: '12px' }} />
                                    </div>
                                
                            </>
                        }

                    </div>
                </div>
            </div>
        </section>
    );
};

export default PackageDetail;
