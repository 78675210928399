import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import SalonCate1 from "../../Assets/img/icons8-squared_menu.png";
import Slider from "react-slick";
import $ from "jquery";
import "./Specialites.scss";
// import { getAllCategories } from "./../../Slices/home";
import { List } from 'react-content-loader';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

const Specialites = ({categories, isDashboardSectionsFetched}) => {
  const settings = {
    dots: false,
    className: "midParts",
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    arrows: false,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,  
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,  
            infinite: true,
            dots: false
            
          }
        }
      ]
};

  const dispatch = useDispatch();
  // const { categories, isCategoriesFetched } = useSelector((state) => state.home);

  useEffect(() => {
    $(".categoryTitle").each(function () {
      var html = $(this).html().split(" ");
      html = html[0] + "<br>" + html.slice(1).join(" ");
      $(this).html(html);
    });
  }, []);

  // useEffect(() => {
  //   dispatch(getAllCategories({type: "main"}));
  //   // eslint-disable-next-line
  // }, []);

  const { t, i18n } = useTranslation();  
  return (
    <section className='spaceialSect spaceSect container'>

        <div className="section-title-box">
            <h2 className='sectionTitle centerText'>
              {t('common.browse')} <span>{t('common.specialities')}</span>
            </h2>
            <p className='sectionSeeAll centerText'>
          <Link className="see-all-text" to={"/allSpecialities"}>{t('common.see_all')}</Link>
        </p>
        </div>
        
        <div className="specialities_list row">
        {isDashboardSectionsFetched ? (        
        categories.length !== 0 ? (
          <>   
          <Slider {...settings}> 
                <Link to={`/allSpecialities`} >
                   <div className="specialities_item">
                     <div className="specialities-item-img-box">
                        <img  src={require('../../Assets/img/cat/all.png')} alt={"img"} className='item-img'/>
                     </div>
                     <div className="specialities-item-name-box"> <h3 className="item-name"> All</h3></div>
                    </div>  
                    </Link>
              {categories?.map((list, key) => {
                return (
                 <>                 
                  <Link to={`/subSpecialities/${list.id}`} >
                   <div className="specialities_item">
                     <div className="specialities-item-img-box">
                        <img  src={list.category_image_url} alt={list.name+""} className='item-img'/>
                     </div>
                     <div className="specialities-item-name-box"> <h3 className="item-name">  {list.category_name}</h3></div>
                    </div>  
                    </Link>
                 </>
                );
              })}
            </Slider>
               </>
        ) : (
          <strong className="item-name">{t('common.no_specialities_found')}</strong>
        )
      ) : (
        <section className='container'>
          <List />
        </section>
      )}

</div>
      {/* <hr className="hr"/> */}
    </section>
  );
};

export default Specialites;