import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { editUserProfile, getUserProfile } from "../../../Slices/auth";
import { Facebook } from "react-content-loader";
import { useState } from "react";
import { useEffect } from "react";
import moment from 'moment';

const Profile = ({ loading, setLoading, profile, setProfile}) => {

  const dispatch = useDispatch();
  const [isEmailValid, setIsEmailValid] = useState(false)

  const editProfile = () => {
    if(!isEmailValid) {
      toast.error("Enter Valid Email")
      return null
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("first_name", profile.first_name);
    formData.append("last_name", profile.last_name);
    formData.append("email", profile.email);
    formData.append("gender", profile.gender);
    formData.append("birth_date", profile.birth_date);
    dispatch(editUserProfile(formData))
      .then((response) => {
        if (response.payload.status === 200) {
          toast.success(response.payload.data.message);
          dispatch(getUserProfile());
          setLoading(false);
        } else {
          toast.error(response.payload.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    const emailRegex = /\S+@\S+\.\S+/;
    if(emailRegex.test(profile.email && profile.email)){
      setIsEmailValid(true)
    } else {
      setIsEmailValid(false)
    }
    // eslint-disable-next-line
  },[])

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    const emailRegex = /\S+@\S+\.\S+/;

    switch(name) {
      case "first_name":
        setProfile({ ...profile, [name]: value.replace(/[0-9]/g, '') });
        break;
      case "last_name":
        setProfile({ ...profile, [name]: value.replace(/[0-9]/g, '') });
        break;
      case 'email':
        if(emailRegex.test(value)){
          setIsEmailValid(true)
        } else {
          setIsEmailValid(false)
        }
        setProfile({ ...profile, [name]: value });
        break;
      default:
        setProfile({ ...profile, [name]: value });
        break;
    }
    // setProfile({ ...profile, [name]: value });
  };
  return (
    <>
      {!loading ? (
        <div className='profDetailIn'>
          <div className='profdetHead'>
            <span className='myprofext'><span className="backbt desknone"><i className="fa fa-angle-left"></i></span>My Profile</span>
            <span className='save' onClick={editProfile}>
              Save
            </span>
          </div>
          <div className='profdetHeadText'>
            <span className='textalert'>Welcome Here !</span>
            <h2> {profile.name}</h2>
          </div>
          <div className='formOut profdet'>
            <form>
              <div className='formInText'>
                <div className='inpuWidth'>
                  <label>First Name</label>
                  <input
                    type='text'
                    name='first_name'
                    value={profile.first_name}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className='inpuWidth'>
                  <label>Last Name</label>
                  <input
                    type='text'
                    name='last_name'
                    value={profile.last_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className='formInText'>
                <div className='inpuWidth'>
                  <div className='formInText mb0 pb0'>
                    <label>Phone Number</label>
                    <div className='phLeft'>
                      <input
                        type={"text"}
                        disabled
                        name='country_code'
                        value={profile.country_code}
                      />
                    </div>
                    <div className='pHright'>
                      <input
                        type='text'
                        disabled
                        name='mobile_no'
                        value={profile.mobile_no}
                      />
                    </div>
                  </div>
                </div>
                <div className='inpuWidth'>
                  <label>Email</label>
                  <input
                    type='text'
                    name='email'
                    value={profile.email}
                    onChange={handleInputChange}
                  />
                  {(!isEmailValid && profile.email !== undefined && profile.email !== "") && <strong className='text-danger'>Enter Valid Email</strong>}
                </div>
              </div>
              <div className='formInText'>
                <div className='inpuWidth '>
                  <label>Gender (Optional)</label>
                  <select name='gender' onChange={handleInputChange}>
                    <option selected={profile.gender === "male"} value={"male"}>
                      Male
                    </option>
                    <option
                      selected={profile.gender === "female"}
                      value={"female"}
                    >
                      Female
                    </option>
                  </select>
                </div>
                <div className='inpuWidth dateImg'>
                  <label>Birthday (Optional)</label>
                  <input
                    type={"date"}
                    name='birth_date'
                    value={profile.birth_date}
                    max={moment().format("YYYY-MM-DD")}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <Facebook />
      )}
    </>
  );
};

export default Profile;
