import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import star from "../../Assets/img/star.png";
import car from "../../Assets/img/carIcon.png";
import packageImg from "../../Assets/img/package3.png";
import MyBookingUp from "../MyBookingUp/MyBookingUp";
import { useState } from "react";
import "./PramotionCar.scss";
import { getAllCoupons, getAllOffers } from "../../Slices/home";
import { Skeleton } from "@mui/material";

let colorArray=['','cuponBook_yello','cuponBook_green']
const OfferCar = () => {
    const dispatch = useDispatch()
    const [is_coupons, set_is_coupons] = useState(1)
    const { isgetAllCouponsFetched, coupons, isgetAllOffersFetched, offers } = useSelector((state) => state.home);
    console.log(isgetAllCouponsFetched, coupons, isgetAllOffersFetched, offers)
    useEffect(() => {
        dispatch(getAllCoupons({ country_id: "103" }))
        dispatch(getAllOffers({ country_id: "103" }))
    }, [])
    return (
        <div className='myProfileOut pt0 pramotion'>
            <div className='profDetailIn'>
                <div className='profdetHead  profdetHeadNoRev '>
                    <h3>Promotions</h3>
                    <ul className='rightTabBar'>
                        <li>
                            <Link onClick={(e) => { e.preventDefault(); set_is_coupons(1) }} to={"/"} className={is_coupons == 1 ? "active" : ""}>
                                Coupons
                            </Link>
                        </li>
                        <li>
                            <Link onClick={(e) => { e.preventDefault(); set_is_coupons(0) }} to={"/"} className={is_coupons == 0 ? "active" : ""}>
                                Offers
                            </Link>
                        </li>
                    </ul>
                </div>
                {is_coupons == 1 ?
                    <div className='formOut profdet '>
                        <div className='profdetHeadText'>Coupons for You</div>
                        <form>
                            <div className='formInText bookForm offerPack'>

                                {isgetAllCouponsFetched ? coupons?.map((singleCoupon,idx) => <div className='inpuWidth sumWid'>
                                    <div className='sumWidIn greenBack'>
                                        <div className='summarLeftImgText'>
                                            <div className='summarLeftImg'>
                                                <img src={singleCoupon?.coupon_image_url} alt='img' />
                                            </div>
                                        </div>
                                        <div className={`cuponBook ${colorArray[idx]}`}>
                                            <div className="cuponBook_inner">
                                                <h3>{singleCoupon?.coupon_description}<br /> <span>{singleCoupon?.coupon_discount}% OFF *</span></h3>
                                                <a href="#0">{singleCoupon?.coupon_code}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : <><Skeleton variant="rectangular" width={400} height={200} sx={{ margin: '12px', borderRadius: '12px' }} />
                                    <Skeleton variant="rectangular" width={400} height={200} sx={{ margin: '12px', borderRadius: '12px' }} />
                                    <Skeleton variant="rectangular" width={400} height={200} sx={{ margin: '12px', borderRadius: '12px' }} />
                                    <Skeleton variant="rectangular" width={400} height={200} sx={{ margin: '12px', borderRadius: '12px' }} />
                                    <Skeleton variant="rectangular" width={400} height={200} sx={{ margin: '12px', borderRadius: '12px' }} /></>}

                                {/* <div className='inpuWidth sumWid'>
                                    <div className='sumWidIn greenBack'>
                                        <div className='summarLeftImgText'>
                                            <div className='summarLeftImg'>
                                                <img src={packageImg} alt='img' />
                                            </div>
                                        </div>
                                        <div className="cuponBook">
                                            <div className="cuponBook_inner">
                                                <h3>Body Wash <br /> <span>20% OFF *</span></h3>
                                                <a href="#0">FLAT20FF</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='inpuWidth sumWid'>
                                    <div className='sumWidIn greenBack'>
                                        <div className='summarLeftImgText'>
                                            <div className='summarLeftImg'>
                                                <img src={packageImg} alt='img' />
                                            </div>
                                        </div>
                                        <div className="cuponBook cuponBook_yello">
                                            <div className="cuponBook_inner">
                                                <h3>Full Car Wash <br /> <span>40% OFF *</span></h3>
                                                <a href="#0">UPTO4OFF</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='inpuWidth sumWid'>
                                    <div className='sumWidIn greenBack'>
                                        <div className='summarLeftImgText'>
                                            <div className='summarLeftImg'>
                                                <img src={packageImg} alt='img' />
                                            </div>
                                        </div>
                                        <div className="cuponBook cuponBook_green">
                                            <div className="cuponBook_inner">
                                                <h3>Car Polishing <br /> <span>₹ 499 OFF *</span></h3>
                                                <a href="#0">GET499OFF</a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </form>
                    </div>
                    :
                    <div className='formOut profdet  profdet_offer'>
                        <div className='profdetHeadText'>Offers for You</div>
                        <form>
                            <div className='formInText bookForm offerPack'>
                                {
                                    offers?.map((singleOffer) => <div className='inpuWidth sumWid'>
                                        <div className='sumWidIn profdet_offer ' >
                                            <h3>{singleOffer?.offer_name}</h3>
                                            <p>{singleOffer?.offer_description}</p>
                                            <a href="#0">Grab Offer</a>
                                            <img className="car_img" src={singleOffer?.offer_banner_url} alt="car" />
                                        </div>
                                    </div>)
                                }
                                {/* <div className='inpuWidth sumWid'>
                                    <div className='sumWidIn profdet_offer'>
                                        <h3><span>Super</span> Saver Week</h3>
                                        <p>Flat <span>40%</span> OFF on all washing <br /> services</p>
                                        <a href="#0">Grab Offer</a>
                                        <img className="car_img" src={packageImg} alt="car" />
                                    </div>
                                </div>
                                <div className='inpuWidth sumWid'>
                                    <div className='sumWidIn profdet_offer profdet_offer1'>
                                        <h3><span>Super</span> Saver Week</h3>
                                        <p>Flat <span>20%</span> OFF on all washing <br /> services</p>
                                        <a href="#0">Grab Offer</a>
                                        <img className="car_img" src={packageImg} alt="car" />
                                    </div>
                                </div> */}
                            </div>
                        </form>
                    </div>
                }
                {/* {
                    is_coupons == 1 ?
                        <div className='formOut profdet '>
                            <div className='profdetHeadText'>Coupons for You</div>
                            <form>
                                <div className='formInText bookForm offerPack'>
                                    {coupons.map((list, key) => {
                                        return <div className='inpuWidth sumWid'>
                                            <div className='sumWidIn greenBack'>
                                                <div className='summarLeftImgText'>
                                                    <div className='summarLeftImg'>
                                                        <img src={list.coupon_image_url} alt='img'/>
                                                    </div>
                                                </div>
                                                <div className="cuponBook">
                                                    <h4>{list.coupon_name}</h4>
                                                    <p>{list.coupon_description}</p>
                                                    <h2>Code:{list.coupon_code}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </form>
                        </div>
                        :
                        <div className='formOut profdet '>
                            <div className='profdetHeadText'>Offers for You</div>
                            <form>
                                <div className='formInText bookForm offerPack'>
                                <div className='inpuWidth sumWid'>
                                            <div className='sumWidIn greenBack'>
                                                <div className='summarLeftImgText'>
                                                    <div className='summarLeftImg'>
                                                        <img src={packageImg} alt='img'/>
                                                    </div>
                                                </div>
                                                <div className="cuponBook">
                                                    <p>sjcbjsbcajbc</p>
                                                </div>
                                            </div>
                                        </div>
                                    {offers.map((list, key) => {
                                        return <div className='inpuWidth sumWid'>
                                            <div className='sumWidIn greenBack'>
                                                <div className='summarLeftImgText'>
                                                    <div className='summarLeftImg'>
                                                        <img src={list.offer_banner_url} alt='img'/>
                                                    </div>
                                                </div>
                                                <div className="cuponBook">
                                                    <h4>{list.offer_name}</h4>
                                                    <p>{list.offer_description}</p>
                                                    <Link to={"/"}>Grab Offer</Link>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </form>
                        </div>
                } */}
            </div>
        </div>
    );
};
export default OfferCar;