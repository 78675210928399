import React from 'react';
import './Price.scss';
import rupees from "../../../Assets/img/rupee-indian.png";

const Pricepackages = ({packagesPrice, packages}) => {
    return (
        <ul className='price '>
            <li>
            {packages.currency.symbol} {packages.discount_price != "" ? packages.discount_price : packages.price}
            </li>

            {
                packages.discount_price != "" ?
                    <>
                        <li>
        <span className='textCut price'>
            {packages.discount_price != "" ? packages.price : packages.discount_price}
        </span>
                        </li>
                        <li>
        <span className='textSave font-weight-bold  save-price'>
          Save {packages.discount_price}
        </span>
                        </li>
                    </> : ""
            }


        </ul>
    );
};

export default Pricepackages