import React, { useEffect, useState } from "react";
import "./Header.scss";
import logo from "../../../Assets/img/logo.png";
import car from "../../../Assets/img/car.png";
import search from "../../../Assets/img/ic_search.png";
import { Link, useNavigate } from "react-router-dom";
import icon1 from "../../../Assets/img/icons8-user.png";
import icon2 from "../../../Assets/img/icons8-maintenance.png";
import icon3 from "../../../Assets/img/icons8-heart.png";
import icon4 from "../../../Assets/img/icons8-home_address.png";
import icon7 from "../../../Assets/img/icons8-help.png";
import icon6 from "../../../Assets/img/icons8-bill.png";
import icon8 from "../../../Assets/img/Path.png";
import { addUserCars, getAllUsersCar, handleBookingDetails, logoutUser, showLogin, showRegister } from "../../../Slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation, withTranslation, Trans } from 'react-i18next';

import $ from "jquery";

const Header = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, userCars, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [carDetails, setCarDetails] = useState({});
  const [selectedCar, setSelectedCar] = useState()
  const [language, setLanguage] = useState(false);
  const selectedCarId = localStorage.getItem("car_id")

  const handleLanguage = () =>{
    if(language){
      setLanguage(false) 
      changeLanguage('en')
    }else{
      setLanguage(true)
      changeLanguage('ar')
    }
  }

  const changeLanguage = (lng) => { 
    i18n.changeLanguage(lng)
    document.body.dir = i18n.dir();
    // theme.direction = i18n.dir();
  }

  useEffect(() => {
    $(document).ready(function () {
      $(window).scroll(function(){
        var sticky = $('.headerTop'),
            scroll = $(window).scrollTop();
      
        if (scroll >= 100) sticky.addClass('fixedTop');
        else sticky.removeClass('fixedTop');
      });
      $("div.selected").on("click", function () {
        var hasActiveClass = $("div.select-box").hasClass("active");

        if (hasActiveClass === false) {
          var windowHeight = $(window).outerHeight();
          var dropdownPosition = $(this).offset().top;
          var dropdownHeight = 95; // dropdown height

          if (dropdownPosition + dropdownHeight + 50 > windowHeight) {
            $("div.select-box").addClass("drop-up");
          } else {
            $("div.select-box").removeClass("drop-up");
          }

          var currentUniversity = $(this).find("text").text().trim();

          $.each($("ul.select-list li"), function () {
            var university = $(this).text().trim();
            if (university === currentUniversity) $(this).addClass("active");
            else $(this).removeClass("active");
          });
        }

        $("div.select-box").toggleClass("active");
      });

      $("ul.select-list li").on("click", function () {
        var university = $(this).html();
        $("span.text").html(university);
        $("div.select-box").removeClass("active");
      });

      $("ul.select-list li").hover(function () {
        $("ul.select-list li").removeClass("active");
      });

      $(document).click(function (event) {
        if ($(event.target).closest("div.searchLocation").length < 1) {
          $("div.select-box").removeClass("active");
        }
      });
    });
    $(document).ready(function () {
      $("div.selectedbmw").on("click", function () {
        var hasActiveClass = $("div.select-boxbmw").hasClass("active");

        if (hasActiveClass === false) {
          var windowHeight = $(window).outerHeight();
          var dropdownPosition = $(this).offset().top;
          var dropdownHeight = 95; // dropdown height

          if (dropdownPosition + dropdownHeight + 50 > windowHeight) {
            $("div.select-boxbmw").addClass("drop-up");
          } else {
            $("div.select-boxbmw").removeClass("drop-up");
          }

          var currentUniversity = $(this).find("text").text().trim();

          $.each($("ul.select-listbmw li"), function () {
            var university = $(this).text().trim();
            if (university === currentUniversity) $(this).addClass("active");
            else $(this).removeClass("active");
          });
        }

        $("div.select-boxbmw").toggleClass("active");
      });

      $("ul.select-listbmw li").on("click", function () {
        var university = $(this).html();
        $("span.text").html(university);
        $("div.select-boxbmw").removeClass("active");
      });

      $("ul.select-listbmw li").hover(function () {
        $("ul.select-listbmw li").removeClass("active");
      });

      $(document).click(function (event) {
        if ($(event.target).closest("div.custom-selectBmw").length < 1) {
          $("div.select-boxbmw").removeClass("active");
        }
      });
    });
    $(document).on("click","button.btn.btn-sm.serFilBTn" || ".continueBtn",function(e) {
      e.stopPropagation();
      $("body").addClass("logSinoScroll");
     
   
    });
    // $(document).on("click"," .showAddMyProf",function() {
      
    //   $("body").removeClass("logSinoScroll");
    // });
    $(function() {
      $("body").click(function(){
        if (!$("section").hasClass("ServDetailPopOut")) {
            $("body").removeClass("logSinoScroll")
        } else if ($("section").hasClass("ServDetailPopOut")) {
          $("body").addClass("logSinoScroll")
        }
      });
      $("body").mouseenter(function(){
        if (!$("section").hasClass("ServDetailPopOut")) {
            $("body").removeClass("logSinoScroll")
        } else if ($("section").hasClass("ServDetailPopOut")) {
          $("body").addClass("logSinoScroll")
        }
      });
    });
    $(document).ready(function () {
      $("button.menuBar").click(function (e) {
        e.stopPropagation();
        $(".rightSideHead").addClass("intro");
        $("body").addClass("shadow noScroll");
        if( $(".searchCat").hasClass("addShow") ){
          $(".searchCat").removeClass("addShow");
        };
      });
      $("button.crossBar").click(function () {
        $(".rightSideHead").removeClass("intro");
        $("body").removeClass("shadow noScroll");
      });
      $(".profListIn li ").click(function () {
        $(".rightSideHead").removeClass("intro");
        $("body").removeClass("shadow noScroll");
      });
      $(" .bellNone a" ).click(function () {
        $(".rightSideHead").removeClass("intro");
        $("body").removeClass("shadow noScroll");
      });

      $(" .bellNone input" ).click(function () {
        $(".rightSideHead").removeClass("intro");
        $("body").removeClass("shadow noScroll");
      });
      $("button.searchMob").click(function (e) {
        e.stopPropagation();
        $(".searchCat").toggleClass("addShow");
      });
      $(document).click(function(e) {
        var container = $(".rightSideHead");
        if (!container.is(e.target) &&
          !container.has(e.target).length) {
            $(".rightSideHead").removeClass("intro");
            $("body").removeClass("shadow noScroll");
        }
      });
      $(document).click(function(e) {
        var container = $(".searchCat");
        if (!container.is(e.target) &&
          !container.has(e.target).length) {
            $(".searchCat").removeClass("addShow");
        }
        var search = $(".searchBar.mob");
        if (!search.is(e.target) &&
          !search.has(e.target).length) {
            $(".searchCat").removeClass("addShow");
        }
      });
      //   $("button.searchMob").click(function(){
      //       $(".searchCat").removeClass("addShow");
      //     });
      // $("button.btn.btn-sm.serFilBTn").click(function () {
      //   $("body").addClass("noScrollBtn");
      //   // $('body').addClass("noScroll");
      //   // $("body").addClass("shadow");
      // });
      // $(".ServDetailPop .closeIcon").click(function () {
      //   $("body").removeClass("noScrollBtn");
      //   // $('body').addClass("noScroll");
      //   // $("body").addClass("shadow");
      // });
    });
    $(document).ready(function () {
      $(function(){

        var $customSelect = $( '.custom-selectOption' );
        var $resetButton = $( '#resetButton' );
      
      
        $customSelect.each(function() {
          var classes = $( this ).attr( 'class' );
          var id = $( this ).attr( 'id' );
          var name = $( this ).attr( 'name' );
      
          var template =  '<div class="' + classes + '">';
          template += '<span class="custom-select-trigger">';
          template += '<span class="custom-select-trigger-text">' + $( this ).data( 'placeholder' ) + '</span>';
          template += '</span>';
          template += '<div class="custom-options">';
      
          $(this).find( 'option' ).each( function() {
            template += '<span class="custom-option" data-value="' + $( this ).attr( 'value' ) + '">' + $( this ).html() + '</span>';
          });
      
          template += '</div></div>';
          
          var customSelectWrapper = $( '<div class="custom-select-wrapper"></div>' );
          customSelectWrapper.css({
            '-webkit-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
            'user-select': 'none'
          });
      
          $( this ).wrap( customSelectWrapper );
          $( this ).after( template );
        });
      
      
        $( document ).on( 'click', function( e ){
          var eTarget = e.target;
      
          if( !$( eTarget ).closest( '.custom-select-wrapper' ).hasClass( 'custom-select-wrapper' ) ) {
            $( '.custom-selectOption' ).removeClass( 'opened' );
            customOptionsClosed();
          }
        });
      
      
        $( '.custom-select-trigger' ).on( 'click', function() {
          $( this ).parents( '.custom-selectOption' ).toggleClass( 'opened' );
      
          var timer;
          if( $( this ).parents( '.custom-selectOption' ).hasClass( 'opened' ) ){
            clearTimeout( timer );
      
            $( this )
              .parents( '.custom-selectOption' )
              .find( '.custom-options' )
              .stop()
              .css('display', 'block')
              .animate({
                'opacity': '1',
                'margin-top': '15px'
              },100 );
          }
      
          else{
            customOptionsClosed();
          }
      
        });
      
      
        $( '.custom-option' ).on( 'click', function() {
          $( this ).parents( '.custom-select-wrapper' ).find( 'select' ).val( $( this ).data( 'value' ) );
          $( this ).parents( '.custom-options' ).find( '.custom-option' ).removeClass( 'selection' );
          $( this ).addClass( 'selection' );
          $( this ).parents( '.custom-selectOption' ).removeClass( 'opened' );		
          $( this ).parents( '.custom-selectOption' ).find( '.custom-select-trigger-text' ).text( $( this ).text() );
          customOptionsClosed();
        });
      
      
        $resetButton.on('click', function() {
          $( '.custom-select-trigger-text' ).text( $customSelect.data( 'placeholder' ) );
        });
      
      
        function customOptionsClosed() {
          $('.custom-options')
            .stop()
            .animate({
              opacity: 0,
              'margin-top': '0'
            },100 );
      
          // var t = setTimeout(function(){
          //   $('.custom-options').css('display', 'none');
          // }, 500 );
        }
      
      });
    });
    $(document).ready(function(){
       

        if($('.voucherOut li.input input').val() !='' ) {
              $('.voucherOut li.input').addClass('applied');
        }
        $('.voucherOut li.input input').blur(function(){
            if($('.voucherOut li.input input').val() !='' ) {
              $('.voucherOut li.input').addClass('applied');
            }else{
              $('.voucherOut li.input').removeClass('applied');
            }   
        });
  });
  }, []);
  useEffect(() => {
    if(isLoggedIn) dispatch(getAllUsersCar())
    // eslint-disable-next-line
  },[])

  const handleLogout = () => {
    dispatch(logoutUser())
      .then((response) => {
        if (response.payload.status === 200) {
          toast.success(response.payload.data.message, {position: "top-center"});
          setTimeout(() => {
            navigate("/");
          }, 1500);
        } else {
          toast.error(response.payload.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleInputChange = (e) => {
    const { value, name, dataset } = e.target
    switch(name){
      case "car_name":
        setCarDetails({...carDetails, [name]: value})
        break;
      default:
        setCarDetails({...carDetails, car_fuel_type: dataset.value})
        break;
    }

};

  const handleAddCars = () => {
    dispatch(addUserCars(carDetails))
    .then((response) => {
      if(response.payload.status === 200){
        toast.success(response.payload.data.message)
      }else{
        toast.error("Please Login to Add Cars.")
      }
    })
    .catch((err) => {
      toast.error(err)
    })
  };

  const handleSelectCar = () => {
    toast.info("Default Car Selected")
    localStorage.setItem('car_id',parseInt(selectedCar))
    dispatch(handleBookingDetails({car_id: parseInt(selectedCar)}))
  }

  const { t, i18n } = useTranslation();
  
  return (
    <div className='headerTop'>
        <div className='container'>
          <div className='row justify-content-between align-items-center'>
            <div className='leftSideHead'>
              <button className='menuBar'>
                <i className='fa fa-bars'></i>
              </button>
              <Link to={"/"} className='logoOut'>
                <img src={logo} alt='logo' />
              </Link>
              <div className='searchBar mob'>
                <button type='submit' className='searchMob'>
                  <i className='fa fa-search'></i>
                </button>
              </div>
              <div className='bellNone'>
                <span>
                  <img src={car} alt='car' />
                </span>
              </div>
              <div className='searchCat searchCatMob'>
                <div className='searchBar'>             
                  <input
                    type='search'
                    placeholder={t("common.search_for")}
                  />
                  <button type='submit' className='btnSearch'>
                    <img src={search} alt='logo' />
                  </button>
                </div>
              </div>
            </div>
            <div className='rightSideHead'>
              <button className='crossBar'>
                <i className='fa fa-times'></i>
              </button>
                <div className='searchBar'>
                  <input
                    type='search'
                    placeholder={t("common.search_for")}
                  />
                  <button type='submit' className='btnSearch'>
                    <img src={search} alt='logo' />
                  </button>
                </div>
                <div className='searchLocation'>
                  <div className='selected'>
                    <p className='text'>
                      <b>{t("location")}</b>
                      <span>{t("location")}</span>
                    </p>
                  </div>
                  <div className='select-box'>
                    <ul className='select-list'>
                      <li data-row='0' data-value=''>
                        <p>
                        <b>{t('common.deliver_to')}</b>
                            <span>{t('common.empire_business')}</span>
                        </p>
                      </li>
                      <li data-row='1' data-value='BUET'>
                        <p>
                        <b>{t('common.deliver_to')}</b>
                            <span>{t('common.empire_business')}</span>
                        </p>
                      </li>
                      <li data-row='2' data-value='MBSTU'>
                        <p>
                           <b>{t('common.deliver_to')}</b>
                            <span>{t('common.empire_business')}</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              <div className='bellNone d-flex align-items-center mobDisnone'>
                <span>
                  <img src={car} alt='car' />
                </span>
                {userCars.length > 0 ? 
                <div className='carMenu'>
                  <h5>{t('common.select_car')}</h5>
                  {/* <div className='custom-selectBmw'>
                  </div> */}
                    <select onChange={(e) => setSelectedCar(e.target.value)}>
                      <option disabled value={""}>{t('common.select_car_default')}</option>
                      {userCars?.map((list, index) => {
                        return <option selected={list.id == selectedCarId ? true : false} key={index} value={list.id}>
                          {list.car_name + ` (${list.car_fuel_type})`}
                          </option>
                      })}
                    </select>
                  {/* <h5>Fuel Type</h5>
                  <ul className='listBrandCar'>
                    {carFuel?.map((list, key) => {
                      return <li key={key}>
                      <span data-value={list.name} onClick={handleInputChange}>{list.name}</span>
                    </li>
                    })}
                  </ul> */}
                  <input type='button' value='Select Car' onClick={() => handleSelectCar()} />
                </div> : 
                <div className="carMenu">
                  <strong>{"No Cars Found"}</strong><br/>
                  <Link to={"myProfile?page=cars"}>{"Add Cars"}</Link>
                </div>
                }
              </div>
              <ul className='linkSing mobnone'>
                {isLoggedIn ? (
                  <>
                    <li>
                      <Link to={"/myProfile?page=profile"}>{t('common.profile')}</Link>
                    </li>
                    <li style={{cursor: "pointer"}}>
                      <span onClick={() => handleLogout()}>{t('common.logout')}</span>
                    </li>
                  </>
                ) : (
                  <>
                  <li>
                    <Link to="login">{t('common.login')}</Link>
                  </li>
                  <li>
                    <Link to="registration">{t('common.sign_up')}</Link>
                  </li>

                  <li>
                  <div className="filter-box">
                    <div class="dropdown">
                      <button class="btn dropdown-toggle custom-dropdown language-dropdown"type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          
                          <img src={require("../../../Assets/img/language.png")} alt="" />
                        
                        <span className="discount-text selected-name">
                          {language ? "Arabic"  : "English" }
                        </span>
                      </button>
                      
                      <div class="dropdown-menu"aria-labelledby="dropdownMenuButton">
                        <button class="dropdown-item discount-text dropdown--item" href="#" 
                        // changeLanguage('en')
                        onClick={ (()=> handleLanguage ())} >
                          {language ? "English"  : "Arabic" }</button>
                      </div>
                    </div>
                    <span className="price-filter-text"> </span>
                </div>
                  </li>
                    {/* <li>
                      <a
                        href='javascript:void(0)'
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(showLogin(true));
                        }}
                      >
                        {t('common.login')}
                      </a>
                    </li>
                    <li>
                      <a
                        href='javascript:void(0)'
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(showRegister(true));
                        }}
                      >
                        {t('common.sign_up')}
                      </a>
                    </li> */}
                  </>
                )}

              </ul>
              <div className="profList">
              <div className='profListIn'>
                {isLoggedIn ? 
                  <div className='nameNum'>
                    <h3>{user.name}</h3>
                    <span>{user.country_code + " " + user.mobile_no} </span>
                  </div> : <div className="nameNum"><h4><div>{t('description.part2')}</div></h4></div>
                }
                <div>
                <div className="filter-box">
                    <div class="dropdown">
                      <button class="btn dropdown-toggle custom-dropdown language-dropdown"type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src={require("../../../Assets/img/language.png")} alt="" />
                        <span className="discount-text selected-name">
                          {language ? "Arabic"  : "English" }
                        </span>
                      </button>
                      
                      <div class="dropdown-menu"aria-labelledby="dropdownMenuButton">
                        <button class="dropdown-item discount-text dropdown--item" href="#" 
                          onClick={ (()=> handleLanguage ())} >
                          {language ? "English"  : "Arabic" }</button>
                      </div>
                    </div>
                    <span className="price-filter-text"> </span>
                </div>
                </div>
                <div className='flexMobCar'>
                  <div className='searchLocation'>
                    <div className='selected'>
                      <p className='text'>
                        <b> {t('common.sign_up')}</b>
                        <span>{t('common.empire_business')}</span>
                      </p>
                    </div>
                    <div className='select-box'>
                      <ul className='select-list'>
                        <li data-row='0' data-value=''>
                          <p>
                            <b>{t('common.deliver_to')}</b>
                            <span>{t('common.empire_business')}</span>
                          </p>
                        </li>
                        <li data-row='1' data-value='BUET'>
                          <p>
                          <b>{t('common.deliver_to')}</b>
                            <span>{t('common.empire_business')}</span>
                          </p>
                        </li>
                        <li data-row='2' data-value='MBSTU'>
                          <p>
                          <b>{t('common.deliver_to')}</b>
                            <span>{t('common.empire_business')}</span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='bellNone'>
                    <span href=''>
                      <img src={car} />
                    </span>
                    {userCars.length > 0 ? 
                    <div className='carMenu'>
                      <h5>{t('common.select_car')}</h5>
                      {/* <div className='custom-selectBmw'>
                      </div> */}
                        <select onChange={(e) => setSelectedCar(e.target.value)}>
                          <option value={""}>{t('common.select_car_default')}</option>
                          {userCars?.map((list, index) => {
                            return <option selected={list.id == selectedCarId ? true : false} key={index} value={list.id}>
                              {list.car_name + ` (${list.car_fuel_type})`}
                              </option>
                          })}
                        </select>
                      {/* <h5>Fuel Type</h5>
                      <ul className='listBrandCar'>
                        {carFuel?.map((list, key) => {
                          return <li key={key}>
                          <span data-value={list.name} onClick={handleInputChange}>{list.name}</span>
                        </li>
                        })}
                      </ul> */}
                      <input type='button' value='Select Car' onClick={() => handleSelectCar()} />
                    </div> : 
                    <div className="carMenu">
                      <strong>{"No Cars Found"}</strong><br/>
                      <Link to={"myProfile?page=cars"}>{"Add Cars"}</Link>
                    </div>
                    }
                  </div>
                </div>
                <div className='contetTextArea'>
                  <ul className='listName listHeight'>
                    {isLoggedIn && 
                    <>
                      <li>
                        <Link to={"/myProfile?page=profile"}>
                          <span className='showProfile'>
                            <i>
                              <img src={icon1} alt='icon' />
                            </i>
                             {t('common.my_profile')}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/myProfile?page=address"}>
                          <span className='showAddress'>
                            <i>
                              <img src={icon4} alt='icon' />
                            </i>
                            {t('common.my_address')}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/myProfile?page=booking"}>
                          <span className='showBooking'>
                            <i>
                              {" "}
                              <img src={icon6} alt='icon' />
                            </i>
                            {t('common.my_bookings')}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/myProfile?page=packages"}>
                          <span className='showFavorite'>
                            <i>
                              <img src={icon3} alt='icon' />
                            </i>
                            {t('common.my_packages')}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/myProfile?page=favorites"}>
                          <span className='showFavorite'>
                            <i>
                              <img src={icon3} alt='icon' />
                            </i>
                            {t('common.my_favourites')}
                          </span>
                        </Link>
                      </li>
                    </>
                    }
                    <li>
                      <li>
                        <Link to={"/myProfile?page=pramotion"}>
                          <span className='showFavorite'>
                            <i>
                              <img src={icon3} alt='icon' />
                            </i>
                            {t('common.promotion')}
                          </span>
                        </Link>
                      </li>
                      {/* <li>
                        <span className='showFavorite'>
                          <i>
                            <img src={icon3} alt='icon' />
                          </i>
                          My Addresss
                        </span>
                      </li> */}
                      <span>
                        <i>
                          {" "}
                          <img src={icon7} />
                        </i>
                        {t('common.help')}
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          <img src={icon2} />
                        </i>
                        {t('common.promotion')}
                      </span>
                    </li>
                  </ul>

                  {/* <ul className='linkSing'>
                    <li>
                      <a
                        href='javascript:void(0)'
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(showLogin(true));
                        }}
                      >
                        Login
                      </a>
                    </li>
                    <li>
                      <a
                        href='javascript:void(0)'
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(showRegister(true));
                        }}
                      >
                        Sign up
                      </a>
                    </li>
                  </ul> */}
                  <div className='logoutName'>
                    <ul className='listName'>
                      {isLoggedIn ? 
                        <li style={{ cursor: "pointer" }}>
                          <span onClick={() => handleLogout()}>
                            <i className='logoUT'>
                              <img src={icon8} />
                            </i>
                            {t('common.logout')}
                          </span>
                        </li> :                   <>
                    <li>
                      <span className='showFavorite' onClick={() => dispatch(showLogin(true))}>
                        <i>
                          <img src={icon3} alt='icon' />
                        </i>
                        {t('common.login')}
                      </span>
                    </li>
                    <li>
                    <span className='showFavorite' onClick={() => dispatch(showRegister(true))}>
                        <i>
                          <img src={icon3} alt='icon' />
                        </i>
                        {t('common.sign_up')}
                      </span>
                    </li>
                  </>
                    }
                    
                    </ul>
                  </div>
                </div>
              </div></div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Header;