import React, { useEffect, useState } from 'react'
import './ServiceDetailSchedule.scss'
import { useDispatch, useSelector } from 'react-redux';
import clock from "../../Assets/img/clock.png"
import { showAddressOpt, showServiceDetails,serviceChooseCar } from '../../Slices/home';
import { getScheduleServiceSlots, handleBookingDetails } from '../../Slices/auth';
import { toast } from 'react-toastify';
const ServiceDetailSchedule = () => {
    const dispatch = useDispatch()
    const todayDate = new Date().toLocaleDateString("af-ZA")
    const days = [...Array(7).keys()].map(days => new Date(Date.now() + 86400000 * days).toDateString().split(" "))

    const [selectedDate, setSelectedDate] = useState("")
    const [selectedTime, setSelectedTime] = useState("")

    useEffect(() => {
        setSelectedDate(todayDate)
        dispatch(getScheduleServiceSlots({start_date: todayDate, end_date: todayDate}))
        // eslint-disable-next-line
    },[])

    const getSlots = (date, month, year) => {
        const fullDate = new Date(date + month + year).toLocaleDateString('af-ZA')
        setSelectedDate(fullDate)
        setSelectedTime("")
        dispatch(getScheduleServiceSlots({start_date: fullDate, end_date: fullDate}))
    }

    const { scheduleSlots } = useSelector((state) => state.auth)

    const handleNextClick = () => {
        if(selectedDate === "" || selectedTime === ""){
            toast.error("Please Select Date & Time to Continue")
        } else {
            dispatch(handleBookingDetails({start_date: selectedDate, start_time: selectedTime}))
            dispatch(serviceChooseCar({type: "isSchedule",payload: true}))
        }
    }

  return (
      <section className='ServDetailPopOut servDetShe'>
        <div className='contianer-fluid ServDetailPop'>
        <div className='serveBack'>
            <h6 onClick={() => dispatch(showServiceDetails(true))} style={{cursor: "pointer"}}>
                <span className="backbt"><i className="fa fa-angle-left"></i></span>
                Schedule
            </h6>
            </div>
            <div className='closeIcon' onClick={() => dispatch(showServiceDetails(false))}>
                <i className='fa fa-times'></i>
            </div>
            <div className='contetTextArea'>
            <div className='headerFilterInfo'>
                                <strong className='serviceName'>
                                    Delux Body Wash
                                </strong>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book… 
                                    <a href="">Read more</a>
                                </p>
                                <div className='contInfo'>
                                    
                                <div className='contInfoIn'>
                                        <ul className='startTask'>
                                                
                                                <li>
                                                    
                                                    <img src={clock} alt="time"/>
                                                    <span className='time flex-grow-1'>
                                                    30 Minutes
                                                    </span>
                                            </li>
                                        </ul> 
                                    
                                        <ul className='price'>
                                            <li>
                                            ₹ 599
                                            </li>
                                            <li>
                                                        <span className='textCut'>₹ 799</span>
                                                    </li>
                                                    <li>
                                                        <span className='textSave'>
                                                        Save ₹ 200</span>
                                                    </li>
                                        </ul>
                                    </div> 
                                </div>
            </div>
            <div className='listMenu aftNone'>
                <ul className='listTex'>
                    <li><span className='active'>Schedule</span></li>
                </ul>
            </div>
            <div className='row listBox mb15'>
                <div className='leftBrandLocation'>
                    <h4>Select Date of Service <span>Agust 2022</span></h4>
                    <ul className='listRadio locationradio' >
                        {days.map((value) => {
                            return <li>
                                <input type="radio" 
                                onClick={() => getSlots(value[2], value[1], value[3])} 
                                name="date" />
                                <label>
                                    <span>{value[0]}</span>
                                    <h5>{value[2]}</h5>
                                </label> 
                            </li>
                        })}
                    </ul>
                </div>
            </div>
            <div className='row listBox aftNone mb0'>
                <div className='leftBrandLocation'>
                    <h4>Time to Start The Service</h4>
                    <ul className='listRadio locationradioList' >
                    {Object.keys(scheduleSlots).map((key, index) => {
                        return <> <strong>{key}</strong>
                        {scheduleSlots[key].map((list, id) => {
                           return <li key={id} >
                                <input type="radio" name="time" value={list} onClick={() => setSelectedTime(list)} />
                                <label>
                                    <span>{list}</span>
                                </label>
                            </li>
                        })}
                        </> 
                    }
                    )}
                    </ul>
                </div>
            </div>
            </div>
            <div className='detOpFoot'>
                 <div className='col priceNum'>
                        
                        <div className='totalPrice'>Total : <span className='price'> ₹ 599</span></div>
                    </div>
                <div className='col rightDet'>
                <button className='continueBtn' style={{cursor: "pointer"}} onClick={() => handleNextClick()} >
                        CONTINUE
                </button>
                </div>
            </div>
        </div>
      </section>
    )
  }
  
  export default ServiceDetailSchedule



