import React, { useEffect } from "react";
import "./ServiceListing.scss";
import saleTag from "../../Assets/img/icons8-price_tag.png";
import priceTag from "../../Assets/img/icons8-sale_price_tag.png";
import SalonCatSlider from "../Common/SalonCatSlider/salonCatSlider";
import ProductBox from "../ProductBox/ProductBox";
import service from "../../Assets/img/service.png";
import filtIcon from "../../Assets/img/icons8-filter.png";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getDashboardById } from "../../Slices/home";
import ServiceDetail from "../ServiceDetail/ServiceDetail";
import ServiceDetailSchedule from "../ServiceDetailSchedule/ServiceDetailSchedule";
import ServiceDetailAddress from "../ServiceDetailAddress/ServiceDetailAddress";
import PaymentOption from "../PaymentOption/PaymentOption";
import ConFirmBooking from "../ConFirmBooking/ConFirmBooking";
import { useState } from "react";
import { List } from "react-content-loader";

const SubServiceListing = () => {
  const {
    showServiceDetails,
    showScheduleOpt,
    showAddressOpt,
    showPaymentOpt,
    showConfirmBooking,
    categories,
    isCategoriesFetched
  } = useSelector((state) => state.home);

  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()



  useEffect(() => {
    // if(isCategoriesFetched){
    //   console.info("SubCate", categories[0].sub_categories.filter((list) => list.id == id).map((services) => services.services))
    // }
    // eslint-disable-next-line
  },[])
  return (
    <>
      <section>
        {isCategoriesFetched && categories[0].sub_categories.filter((list) =>
        list.id == id).map((services) => 
        <div className=' headerFilterOut container'>
          {console.info("subservice map", services)}
          <div className='borderBottomDesign titleHead'>
            <div className='spaceSect row mlr0 aligCent jSpaceBetween wid100'>
              <h2 className='sectionTitle centerText'>
                  <Link to={"javascript:void(0)"} className='backbt' onClick={() => navigate(-1)}>
                    <i className='fa fa-angle-left'></i> 
                    {services.category_name}
                  </Link>
                </h2>
                <div className='rightCont row '>
                    <div className='mobIcon'>
                      <img src={filtIcon} />
                    </div>
                    <div className='rightIn'>
                      <div className='selectDrop'>
                        <img src={priceTag} alt='priceTag' />

                        <select>
                          <option>Discount</option>
                        </select>
                      </div>
                      <div className='outPutsec'>
                        <div className='outPutsecIn'>
                          <img src={saleTag} alt='saleTag' />
                          <p>
                            Price Range : <b>₹500 - ₹1000</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
          <div className='row headerFilter'>
              {services && services.services.length > 0 ? services.services.map((services, key) => 
              <div className='col-md-3 col-sm-4 col-xs-6 headerFilterIn'>
                <ProductBox
                  services={services}
                  key={key}
                />
              </div>
              ) : <strong>{"No Data Found"} </strong>}
            </div>
        </div>
        )}

      {showServiceDetails && <ServiceDetail />}
      {showScheduleOpt && <ServiceDetailSchedule />}
      {showAddressOpt && <ServiceDetailAddress />}
      {showPaymentOpt && <PaymentOption />}
      {showConfirmBooking && <ConFirmBooking  />}
      </section>
    </>
  );
};

export default SubServiceListing;
