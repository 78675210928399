import React, { useEffect, useState } from "react";
import "./ServiceDetail.scss";
import ServDetailSlider from "../ServDetailSlider/ServDetailSlider";
import clock from "../../Assets/img/clock.png";
import { useDispatch, useSelector } from "react-redux";
import { showScheduleOpt, showServiceDetails, showAddressOpt,serviceChooseCar } from "../../Slices/home";
import { toast } from 'react-toastify';
import { handleBookingDetails, showLogin } from "../../Slices/auth";
import { addServiceToFavorite, removeServiceToFavorite } from '../../Slices/auth';
import { getServiceDetails } from '../../Slices/home';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const ServiceDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const { serviceDetails } = useSelector((state) => state.home)
  const { isLoggedIn, bookingDetails } = useSelector((state) => state.auth) 
  // const [addTofav, setAddToFav] = useState(false)

  const checkAuth = (page) => {
    if(isLoggedIn){
      switch(page){
        case "schedule":
          if(!bookingDetails.car_id){
            toast.error("Select your car first to book a service.")
          } else {
            dispatch(showScheduleOpt(true))
            dispatch(handleBookingDetails({service_id: serviceDetails?.id, duration: serviceDetails?.duration}))
          }
          break;
        case "book":
          if(!bookingDetails.car_id){
            toast.error("Select your car first to book a service.")
          } else {
            //dispatch(showAddressOpt(true))
            dispatch(serviceChooseCar({payload: true}))
            dispatch(handleBookingDetails({service_id: serviceDetails?.id, duration: serviceDetails?.duration}))
          }
          break;
          default:
            break
      }
    }
    if(!isLoggedIn){
      toast.error("Please Login For Service Booking.")
      dispatch(showServiceDetails(false))
      dispatch(showLogin(true))
    }
  }

  const handleAddToFavorite = (serviceId) => {
    if(isLoggedIn) {
      dispatch(addServiceToFavorite({service_id: serviceId}))
      .then((response) => {
        toast.success(response.payload.message)
        dispatch(getServiceDetails({id: serviceId, code: "103"}))
      })
      .catch((err) => {
        toast.error(err)
      })
    } else {
      toast.info("Login for Add to Favorite")
    }
  }

  const handleRemoveFavorite = (id) => {
    dispatch(removeServiceToFavorite(id))
    .then((response) => {
      // toast.info(response.payload.message)
      dispatch(getServiceDetails({id: id, code: "103"}))
    })
    .catch((err) => toast.error(err))
  }
  const navigate = useNavigate();
  console.log(serviceDetails,"serviceDetails");
  return (
    <>
    {/* New Design */}
    <section className="container service-details"> 
      <div className="service-details-title">
        <i class="fa fa-angle-left" onClick={() => navigate(-1)}></i>
        <span className="service-details-title-text">Service details</span>
      </div>
      <div className="service-details-body">
        <div className="row">
            <div className="col-12 col-lg-5">
                <div className="">
                    <img className="service-details-img" src={serviceDetails?.service_image_url} alt="" />
                </div>
            </div>
            <div className="col-12 col-lg-7 service-details-information">
                <div className="service-details-info">
                    <h6 className="service-details-name">{serviceDetails?.service_name}</h6>
                    <p className="service-details-description">{serviceDetails?.description}</p>
                </div>
                <div className="service-details-price-box">
                    {
                      serviceDetails?.offer_price ?
                      <><span className="service-details-price">{serviceDetails?.currency?.symbol} {" "} {serviceDetails?.offer_price}</span></>
                      :
                      <></>
                    }
                    {
                      serviceDetails?.price ?
                      <><span className="service-details-mrp">{serviceDetails?.currency?.symbol} {" "} {serviceDetails?.price}</span></> :
                      <></>
                    }
                    {
                    serviceDetails?.offer_discount_price ? 
                      <><span className="service-details-save-price">Save {serviceDetails?.currency?.symbol} {serviceDetails?.offer_discount_price}</span></>
                      : <></>
                    }
                    
                </div>
                <div className="service-details-time">
                    <img src={clock} alt="" width={15} height={15}/>
                    <span className="time">{serviceDetails?.duration} Minutes. </span>
                </div>
                <div className="service-details-book-box">
                      <Link to={"/ChooseProvider"}>
                        <button className="book-button"> BOOK NOW </button>
                      </Link>
                    <div className="wish-list-box">
                        <div>
                            {
                              serviceDetails?.is_favorite ?
                                <>
                                  <img width={25} src={require("../../Assets/img/active-heart.png")} alt=""  onClick={() => { handleRemoveFavorite(serviceDetails.id)}} />
                                </>
                                :
                                <><img width={25} src={require("../../Assets/img/heart.png")} alt=""  onClick={() => { handleAddToFavorite(serviceDetails.id) }} /></>
                            }                           
                        </div>
                        <span className="add-to-Favourites-text">Add to Favourites</span>
                    </div>
                </div>  
                {
                   serviceDetails?.services_includes?
                   <>
                   <div className="service-details-more-info">
                    <h4 className="service-details-more-info-title">Service Includes</h4>
                    <div className="service-details-more-sub-info">
                      {
                        serviceDetails?.services_includes.map((item,index)=>{
                          return(
                            <>
                              <div key={index}>
                                <h5 className="sub-tltle">{item.attribute_name.attribute_name}</h5>
                                <p className="sub-dec">{item.product_attribute_text}</p>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                   </>
                   :
                   <></>
                }
                
            </div>
        </div>
      </div>
    </section>

    {/* Old Design */}
    {/*}
      <section className='ServDetailPopOut serviceD'>
      <div className='contianer-fluid ServDetailPop'>
      <div className='serveBack'>
            <h6 >
              <span className="backbt"><i className="fa fa-angle-left"></i></span>
              Service Detail
            </h6>
            </div>
        <div
          className='closeIcon'
          onClick={() => dispatch(showServiceDetails(false))}
        >
          <i className='fa fa-times'></i>
        </div>
        <div className='contetTextArea'>
          <div className='ServDetImg'>
            <ServDetailSlider image={serviceDetails} />
          </div>
          <div className='headerFilterInfo'>
            <div className='contInfo'>
              <div className='contInfoIn'>
                <h3>{serviceDetails?.service_name}</h3>
                <div dangerouslySetInnerHTML={{__html: serviceDetails?.description}} />
                <ul className='price'>
                  <li>
                    {serviceDetails?.currency?.symbol}
                    {serviceDetails?.offer_price != "" ? serviceDetails?.offer_price : serviceDetails?.price}
                  </li>
                  {
                    serviceDetails?.offer_price != "" ?
                        <>
                          <li>
                            <span className='textCut'> {serviceDetails?.offer_price != "" ? serviceDetails?.price : serviceDetails?.offer_price}</span>
                          </li>
                          <li>
                            <span className='textSave'>Save {serviceDetails?.offer_discount_price}</span>
                          </li>
                    </>:""
                  }

                </ul>
                <ul className='startTask'>
                  <li>
                    <img src={clock} alt='time' />
                    <span className='time flex-grow-1'>
                      {serviceDetails?.duration} Min.
                    </span>
                  </li>
                </ul>
              </div>
              <div className='fleCol'>
                <button
                  className='btn btn-sm serFilBTn'
                  onClick={() => checkAuth("book")}
                >
                  BOOK
                </button>
                <button
                  className='btn btn-sm serFilBTn serFilBTnSedul'
                  onClick={() => checkAuth("schedule")}
                >
                  SCHEDULE
                </button>
              </div>
            </div>
          </div>
          <div className='includeServe'>
            <h4>
              Service Includes</h4>
            <ul className='listText'>
              {serviceDetails?.services_includes?.length > 0 && 
              serviceDetails?.services_includes?.map((item) => {
                return <li>
                  <h6>{item.product_attribute_text}</h6>
                  {item.attribute_name.attribute_name||""}
                </li>
              })}
            </ul>
          </div>

          <button
            className='footModBtn'
            onClick={() => dispatch(showScheduleOpt(true))}
          >
            Book Now
          </button>
            <button className='footModBtn'  onClick={() => dispatch(setShowOptionModal(true)) }>Book Now</button> 
        </div>
      </div>
      </section>   */}
    </>
  );
};

export default ServiceDetail;
