import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import clock from "../../Assets/img/clock.png";
import "./AddressOptions.scss"

export default function AddressOptions() {
    const { serviceDetails } = useSelector((state) => state.home)
    const [location,setLocation] = useState("Outlet");
    const navigate = useNavigate();
    const [selectaddress,setSelectedAddress] = useState("");
  return (
    <>
    <section className="container service-details"> 
    <div className="service-details-title" >
      <i class="fa fa-angle-left" onClick={() => {navigate(-1)}}></i>
      <span className="service-details-title-text">Location</span>
    </div>

    <div className="service-details-body">
      <div className="row">
      <div className="col-12 col-lg-5">
              <div className="">
                  <img className="service-details-img" src={require("../../Assets/img/service.png")} alt="" />
              </div>
              <div className='service-details-information marginTop-19 Schedule'>
                <div className="service-details-info">
                    <h6 className="service-details-name">Delux Body Wash</h6>
                    <p className="Schedule-service-details-description">Perfect for clients who need their interior cleaned but want most of the focus on the exterior of their vehicle. Lorem Ipsum is simply dummy text…</p>
                </div>
                <div className="service-details-time">
                    <img src={clock} alt="" width={15} height={15}/>
                    <span className="time">35 Minutes.</span>
                </div>
              </div>
          </div>

          <div className="col-12 col-lg-7 ">
            <div className="choose-provider address-options">
                <div className='schedule-title-box'>
                    <div>
                        <span className='schedule-box p-0'>Location & Address</span>
                    </div>    
                </div>
                <div className="choose-body">
                      <div className="choose-body-title">
                        <span className="choose-car-here p-0">Choose Location</span>
                      </div>
                      <div className='pr-3 pb-3 pl-3'>
                        <div className='address-option-list'>
                          <div className={`${location === 'Outlet' ? 'address-option-item-active' : 'address-option-item'}`} onClick={()=>{ setLocation("Outlet") }}>
                                <img width={55} height={55} src={require('../../Assets/img/flat.png')} alt="" />
                                <span className={`${location === 'Outlet' ? 'address-option-item-text-active' : 'address-option-item-text'}`}>At Outlet</span>
                          </div>
                          <div className={`${location === 'Home' ? 'address-option-item-active' : 'address-option-item'}`} onClick={()=>{ setLocation("Home") }}>
                                <img width={55} height={55} src={require('../../Assets/img/home.png')} alt="" />
                                <span className={`${location === 'Home' ? 'address-option-item-text-active' : 'address-option-item-text'}`}>At Home</span>
                          </div>
                        </div>
                  </div>
                  </div>  
                
                  <div className='p-1'>
                  </div>
                  <div className='select-address'>
                      <h3 className='select-address-Saved'>Saved Address</h3>
                      <h6 className='select-address-Add'>+ Add New Address</h6>
                  </div>
                 <div className='address-list'>
                     <div className={`${selectaddress === "Home" ?  "address-item address-item-active" : ""} address-item`}>
                       <div className='d-flex align-items-center radio--box'> 
                       <div className='radio-body' onClick={()=>{setSelectedAddress("Home")}}>
                            {selectaddress === "Home" ? <> <div className='radio-dot'></div></> :""}
                        </div>
                        <div className='address-info'>
                          <h3 className='address-title'>Home</h3>
                          <p className='address-text'>G-57, Goving Park, Ghodasar Road, manekchowk,Ahmedabad-380050</p>
                          <h6 className='address-mobile'>Mo : +91 8141000000</h6>
                        </div></div>
                        <img width={30} height={30} src={require('../../Assets/img/menu.png')} alt="" />
                      </div>   
                      <div className={`${selectaddress === "Office" ?  "address-item address-item-active" : ""} address-item`}>
                       <div className='d-flex align-items-center radio--box'> 
                        <div className='radio-body' onClick={()=>{setSelectedAddress("Office")}}>
                            {selectaddress === "Office" ? <> <div className='radio-dot'></div></> :""}
                        </div>
                        <div className='address-info'>
                          <h3 className='address-title'>Office</h3>
                          <p className='address-text'>G-57, Goving Park, Ghodasar Road, manekchowk,Ahmedabad-380050</p>
                          <h6 className='address-mobile'>Mo : +91 8141000000</h6>
                        </div></div>
                        <img width={30} height={30} src={require('../../Assets/img/menu.png')} alt="" />
                      </div>   
                 </div>
                <div className='choose-provider-footer'>
                   <span className='choose-provider-price'>Total : ₹ 499</span>
                   <Link to={"/paymentOption"}>
                    <button className='next-button'>CONTINUE</button>
                   </Link>
                </div> 
          </div>
          </div>
      </div>
    </div>
  </section>
    </>
  )
}
