import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import clock from "../../Assets/img/clock.png";
import { listBooking } from "../../Slices/auth";
import home from "../../Assets/img/home.png";
import pic from "../../Assets/img/Bitmap.png";
import star from "../../Assets/img/star.png";
import qr from "../../Assets/img/qr.png"
import service from "../../Assets/img/service.png";
import { Link, useNavigate } from 'react-router-dom';
import "./MyBookings.scss";

import MyBookingAccept from "../MyBookingUp/MyBookingUp";
import moment from "moment";
import { Skeleton } from "@mui/material";

const MyBookings = () => {
  const [activeTab, setActiveTab] = useState({
    current: true, completed: false, expired: false, loading: true
  })
  const [popup, setPopup] = useState("");
  const [detail, Setdetail] = useState("");
  const [isLoading, setisLoading] = useState(true)
  const [selectedTabListing, setSelectedTabListing] = useState([])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleTabChange = (tab) => {
    setisLoading(true)
    switch (tab) {
      case "current":
        setActiveTab({ current: true, completed: false, expired: false, loading: true })
        dispatch(listBooking("current"))
          .then((response) => {
            setisLoading(false)
            setSelectedTabListing(response.payload.result.data)
            setActiveTab({ current: true, completed: false, expired: false, loading: false })
          })
          .catch(() => {
            setisLoading(false)
            setSelectedTabListing([])
            setActiveTab({ current: true, completed: false, expired: false, loading: false })
          })
        break;
      case "completed":
        setActiveTab({ current: false, completed: true, expired: false, loading: true })
        dispatch(listBooking("completed"))
          .then((response) => {
            setisLoading(false)
            setSelectedTabListing(response.payload.result.data)
            setActiveTab({ current: false, completed: true, expired: false, loading: false })
          })
          .catch(() => {
            setisLoading(false)
            setActiveTab({ current: false, completed: true, expired: false, loading: false })
            setSelectedTabListing([])
          })
        break;
      case "expired":
        setActiveTab({ current: false, completed: false, expired: true, loading: false })
        dispatch(listBooking("expired"))
          .then((response) => {
            setisLoading(false)
            setSelectedTabListing(response.payload.result.data)
            setActiveTab({ current: false, completed: false, expired: true, loading: false })
          })
          .catch(() => {
            setSelectedTabListing([])
            setisLoading(false)
            setActiveTab({ current: false, completed: false, expired: true, loading: false })
          })
        break;
      default:
        break;
    }
  }

  const handleClick = (id) => {
    setPopup(id);
  };


  useEffect(() => {

    dispatch(listBooking("current"))
      .then((response) => {
        setisLoading(false)
        setActiveTab({ ...activeTab, loading: false })
        setSelectedTabListing(response.payload.result.data)
      })
      .catch(() => {
        setisLoading(false)
        setSelectedTabListing([])
        setActiveTab({ ...activeTab, loading: false })
      })
    // eslint-disable-next-line
  }, [])




  return (
    <div className='myProfileOut pt0'>
      <div className='profDetailIn'>
        <div className='profdetHead profdetHeadNoRev '>
          <h3> <span className="backbt desknone"><i className="fa fa-angle-left"></i></span>My Bookings</h3>
          <ul className='rightTabBar'>
            <li onClick={() => handleTabChange("current")}>
              <a
                href='javascript:void(0)'
                className={`upcoming ${activeTab.current && "active"}`}
              >
                Current
              </a>
            </li>

            <li onClick={() => handleTabChange("completed")}>
              <a
                href='javascript:void(0)'
                className={`completed ${activeTab.completed && "active"}`}
              >
                Completed
              </a>
            </li>
            <li onClick={() => handleTabChange("expired")}>
              <a
                href='javascript:void(0)'
                className={`expire ${activeTab.expired && "active"}`}
              >
                Cancelled
              </a>
            </li>
          </ul>
        </div>


        {/* 
        <div className='profdetHeadText'>


          {popup === '' ? "" :

            <MyBookingAccept handleClick={handleClick} Details={selectedTabListing} Key={popup} />

          }
        </div> */}



        <div className='formOut profdet' >
          <form>
            <div className='formInText bookForm'>
              {
                !isLoading ? selectedTabListing?.map((singleBooking) => <div className='inpuWidth sumWid' onClick={() => navigate(`/bokingDetails/${singleBooking.code || 1}`)} >
                  <div className='sumWidIn' >
                    <div className='summarLeftImgText'>
                      <div className='summarLeftImg'>
                        <img src={singleBooking?.service_details?.service_image_url} />
                      </div>
                      <div className='headerFilterInfo'>
                        <strong className='serviceName'>
                          {singleBooking?.service_details?.service_name}
                        </strong>
                        <div className="textEllips"
                          dangerouslySetInnerHTML={{ __html: singleBooking?.service_details?.description }} />
                        <div className='contInfo'>
                          <div className='contInfoIn'>

                            <ul className='startTask'>
                              <li>
                                <img src={clock} alt='time' />
                                <span className='time flex-grow-1'>
                                  {singleBooking?.duration + "min"}
                                </span>
                                {/* <span>30 Min.</span> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <h4 className="stretched-linksssss---" style={{'cursor':"pointer"}} key={"__"+key} onClick={() => setPopup(key)}> Details </h4> */}
                    {/* <h4 className="stretched-linksssss---" style={{ 'cursor': "pointer" }} > Details </h4> */}
                    <div className='bookInfo'>
                      <ul className='bookINfoIn'>
                        <li>
                          <h5>Job Id</h5>
                          {/* <span>HFGAS51653</span> */}
                          <span>{singleBooking.code}</span>
                        </li>
                        <li>
                          <h5>CAR </h5>
                          {/* <span>BMW (GAS)</span> */}
                          {singleBooking.car_details != null ? <span>{singleBooking.car_details.car_name + `(${singleBooking.car_details.car_fuel_type})`}</span> : ""}
                        </li>
                        <li>
                          <h5>PRICE</h5>
                          <span>₹ {singleBooking.total_price}

                          </span>
                          {/* <span>₹ 430</span> */}
                        </li>
                        <li>
                          <h5>STATUS</h5>
                          {
                            singleBooking?.status_name == 'Accepetd' ? <span className="green_span">Accepted</span> : singleBooking?.status_name == 'Payment Requested' ? <span className="parpel_span">Payment Requested</span> : singleBooking?.status_name == 'Payment Completed' ? <span className="completed_span">Payment Completed</span> : <span className='req status'>{singleBooking?.status_name}</span>
                          }
                          {/* <span className="yellow_span">Requested</span> */}
                        </li>
                      </ul>
                    </div>
                    <div className='summarLeftAdd'>
                      <ul className='listRadio addlocationradio'>
                        <li className=''>
                          <ul className='listRadio  locationradioList'>
                            <li className='date'>
                              <label>
                                <h5>{moment(singleBooking.start_date).format('DD MMMM')}</h5>
                                {/* <h5> 26 August</h5> */}
                              </label>
                            </li>
                            <li className='date'>
                              <label>
                                <h5>{moment(singleBooking.start_time, 'ddd DD-MMM-YYYY, hh A').format('hh:mm A')}</h5>
                                {/* <h5>02:00 PM</h5> */}
                              </label>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>) : <>
                  <Skeleton variant="rectangular" width={400} height={400} sx={{ margin: '12px', borderRadius: '12px' }} />
                  <Skeleton variant="rectangular" width={400} height={400} sx={{ margin: '12px', borderRadius: '12px' }} />
                  <Skeleton variant="rectangular" width={400} height={400} sx={{ margin: '12px', borderRadius: '12px' }} />
                  <Skeleton variant="rectangular" width={400} height={400} sx={{ margin: '12px', borderRadius: '12px' }} />
                  <Skeleton variant="rectangular" width={400} height={400} sx={{ margin: '12px', borderRadius: '12px' }} /></>
              }



            </div>
          </form>
        </div>

      </div>
    </div>
  );
};

export default MyBookings;
