import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../Services/auth.service";

export const userSignIn = createAsyncThunk(
    "auth/userSignIn",
    async (data, thunkAPI) => {
      try {
        const response = await authService.userSignIn(data);
        sessionStorage.setItem("token", response.data.result.token)
        localStorage.setItem("user", JSON.stringify(response.data.data))
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const userExist = createAsyncThunk(
    "auth/checkUserExist",
    async (data, thunkAPI) => {
      try {
        const response = await authService.checkUserExist(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getCountryList = createAsyncThunk(
    "auth/getCountryList",
    async (thunkAPI) => {
      try {
        const response = await authService.countryList();
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getUserProfile = createAsyncThunk(
    "auth/getUserProfile",
    async (thunkAPI) => {
      try {
        const response = await authService.userProfile();
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const editUserProfile = createAsyncThunk(
    "auth/editUserProfile",
    async (data, thunkAPI) => {
      try {
        const response = await authService.editProfile(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getAddressList = createAsyncThunk(
    "auth/getAddressList",
    async (thunkAPI) => {
      try {
        const response = await authService.addressList();
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const deleteUserAddress = createAsyncThunk(
    "auth/deleteUserAddress",
    async (data, thunkAPI) => {
      try {
        const response = await authService.deleteAddress(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const addUserAddress = createAsyncThunk(
    "auth/addUserAddress",
    async (data, thunkAPI) => {
      try {
        const response = await authService.addAddress(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const editUserAddress = createAsyncThunk(
    "auth/editUserAddress",
    async (data, thunkAPI) => {
      try {
        const response = await authService.editAddress(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getAllUsersCar = createAsyncThunk(
    "auth/getAllUsersCar",
    async (thunkAPI) => {
      try {
        const response = await authService.getAllCars();
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const deleteUserCars = createAsyncThunk(
    "auth/deleteUserCars",
    async (data, thunkAPI) => {
      try {
        const response = await authService.deleteCars(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const addUserCars = createAsyncThunk(
    "auth/addUserCars",
    async (data, thunkAPI) => {
      try {
        const response = await authService.addCars(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const logoutUser = createAsyncThunk(
    "auth/logoutUser",
    async (thunkAPI) => {
      try {
        const response = await authService.logout();
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getOtp = createAsyncThunk(
    "auth/getOtp",
    async (data, thunkAPI) => {
      try {
        const response = await authService.getOtp(data);
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const verifyOtp = createAsyncThunk(
    "auth/verifyOtp",
    async (data, thunkAPI) => {
        try {
            const response = await authService.verifyOtp(data);
            if(response.data.result.token){
                sessionStorage.setItem("token", response.data.result.token)
            }
            // if(response.data.result.data){
            //     localStorage.setItem("user", JSON.stringify(response.data.result.data))
            // }
            return response.data
        } catch(error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const registerUser = createAsyncThunk(
    "auth/registerUser",
    async (data, thunkAPI) => {
        try {
            const response = await authService.registerUser(data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const createBooking = createAsyncThunk(
    "auth/createBooking",
    async (data, thunkAPI) => {
        try {
            const response = await authService.bookService(data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const createPackageBooking = createAsyncThunk(
  "auth/createPackageService",
  async (data, thunkAPI) => {
      try {
          const response = await authService.bookpackageService(data);
          return response.data
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data)
      }
  }
)

export const GetSpList = createAsyncThunk(
  "auth/getSpList",
  async (data, thunkAPI) => {
      try {
          const response = await authService.getSpList(data);
          return response.data
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data)
      }
  }
)

export const GetpackageService = createAsyncThunk(
  "auth/getSpList",
  async (data, thunkAPI) => {
      try {
          const response = await authService.getpackageService(data);
          return response.data
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data)
      }
  }
)

export const createPackageDetails = createAsyncThunk(
  "auth/createPackageDetails",
  async (data, thunkAPI) => {
      try {
          const response = await authService.packageService(data);
          return response.data
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data)
      }
  }
)

export const listBooking = createAsyncThunk(
    "auth/listBooking",
    async (data, thunkAPI) => {
        try {
            const response = await authService.getBookingList(data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)
export const detailsBooking = createAsyncThunk(
    "auth/detailsBooking",
    async (data, thunkAPI) => {
        try {
            const response = await authService.getBookingDetails(data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)
export const detailsPackage = createAsyncThunk(
    "auth/detailsPackage",
    async (data, thunkAPI) => {
        try {
            const response = await authService.getPackageDetails(data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const packagelistBooking = createAsyncThunk(
  "auth/listBooking",
  async (data, thunkAPI) => {
      try {
          const response = await authService.getpackageBookingList(data);
          return response.data
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data)
      }
  }
)

export const listFavorite = createAsyncThunk(
    "auth/listFavorite",
    async (data, thunkAPI) => {
        try {
            const response = await authService.getUserFavoriteList(data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const addServiceToFavorite = createAsyncThunk(
    "auth/addServiceToFavorite",
    async (data, thunkAPI) => {
        try {
            const response = await authService.addFavorite(data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const removeServiceToFavorite = createAsyncThunk(
    "auth/removeServiceToFavorite",
    async (data, thunkAPI) => {
        try {
            const response = await authService.removeFavorite(data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const getScheduleServiceSlots = createAsyncThunk(
    "auth/getScheduleServiceSlots",
    async (data, thunkAPI) => {
        try {
            const response = await authService.getScheduleSlots(data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const handlePaymentSummaryDetails = createAsyncThunk(
    "auth/handlePaymentSummaryDetails",
    async (data, thunkAPI) => {
        try {
            const response = await authService.addPaymentSummaryDetails(data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const handleCouponApply = createAsyncThunk(
    "auth/addCouponApply",
    async (data, thunkAPI) => {
        try {
            const response = await authService.addCouponApply(data);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const handleCancelRequst = createAsyncThunk(
  "auth/handleCancelRequst",
  async (data, thunkAPI) => {
      try {
          const response = await authService.setCancelRequst(data);
          return response.data
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data)
      }
  }
)

export const payRemainPayment = createAsyncThunk(
  "auth/payRemainPayment",
  async (data, thunkAPI) => {
      try {
          const response = await authService.payRemainPayment(data);
          return response.data
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data)
      }
  }
)


// temp date
const todayDate = new Date().toLocaleDateString("af-ZA")
const initialState = {
    user: sessionStorage.getItem("user") !== null ? JSON.parse(sessionStorage.getItem("user")) : {},
    user_car_id_default:null,
    showLogin: false,
    showRegister: false,
    showOTP: false,
    isLoggedIn: sessionStorage.getItem("token") ? true : false,
    userCars: [],
    bookingDetails: {start_date: todayDate, start_time: "12:00", car_id: localStorage.getItem("user_car_id_default"),serviceProvider:0},
    paymentsummary: {},
    bookingListing: {current: [], completed: [], expired: []},
    scheduleSlots: {},
    packageServiceBooking: {},
    packageService: {},
    spList: []

}

const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        showLogin: (state, action) => {
            state.showLogin = action.payload
            state.showRegister = false
            state.showOTP = false
        },
        showRegister: (state, action) => {
            state.showLogin = false
            state.showOTP = false
            state.showRegister = action.payload
        },
        showOtp: (state, action) => {
            state.showOTP = action.payload
            state.showLogin = false
            state.showRegister = false
        },
        handleBookingDetails: (state, action) => {
          state.bookingDetails = {...state.bookingDetails, ...action.payload}
        },
        handleServiceProvider: (state, action) => {
          console.log(action.payload," action.payload")
          state.bookingDetails.serviceProvider = action.payload
        },
        handleResetBookingDetails: (state) => {
          state.bookingDetails = initialState.bookingDetails
        }
    },
    extraReducers: {
        [userSignIn.pending]: (state) => {
            state.isLoggedIn = false
        },
        [userSignIn.fulfilled]: (state, action) => {
            sessionStorage.setItem("token", action.payload.data.result.token)
            state.isLoggedIn = true
        },
        [userSignIn.rejected]: (state) => {
            state.isLoggedIn = false
        },
        [getOtp.fulfilled]: (state) => {
            state.showOTP = true
        },
        [getOtp.rejected]: (state) => {
            state.showOTP = false
        },
        [getUserProfile.pending]: (state) => {
            state.user = {}
        },
        [getUserProfile.fulfilled]: (state, action) => {
            sessionStorage.setItem("user", JSON.stringify(action.payload.data.result.userData))
            state.user = action.payload.data.result.userData
            state.user_car_id_default = action.payload.data.result.userData.user_car_details_default.id||null;
            localStorage.setItem("user_car_id_default", JSON.stringify(action.payload.data.result.userData.user_car_details_default.id||null));
        },
        [getUserProfile.rejected]: (state) => {
            state.user = {}
        },
        [logoutUser.fulfilled]: (state) => {
          sessionStorage.clear()
          state.isLoggedIn = false
          state = initialState
        },
        [getAllUsersCar.fulfilled]: (state, action) => {
          state.userCars = action.payload.data.result.data
        },
        [listBooking.pending]: (state) => {
          state.bookingListing = {current: [], completed: [], expired: []}
        },
        [listBooking.fulfilled]: (state, action) => {
          switch(action.meta.arg){
            case("current"):
              state.bookingListing.current = action.payload.result.data 
              break;
            case("completed"):
              state.bookingListing.completed = action.payload.result.data
              break;
            case("expired"):
              state.bookingListing.expired = action.payload.result.data
              break;
            default:
              break;
          }
        },
        [listBooking.rejected]: (state) => {
          state.bookingListing = {current: [], completed: [], expired: []}
        },
        [getScheduleServiceSlots.pending]: (state) => {
          state.scheduleSlots = {}
        },
        [getScheduleServiceSlots.fulfilled]: (state, action) => {
          state.scheduleSlots = action.payload.result.data
        },
        [getScheduleServiceSlots.rejected]: (state) => {
          state.scheduleSlots = {}
        },
    }
})

export const {
  showLogin,
  showRegister,
  showOtp,
  handleBookingDetails,
  handleServiceProvider,
  handleResetBookingDetails,
} = authSlice.actions;

const { reducer } = authSlice
export default reducer