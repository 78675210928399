import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./AddAddress.scss";
import { Link } from "react-router-dom";
// import { setShowAddAddress, addAddress } from "../../slices/user";
import { toast } from "react-toastify";
import { addUserAddress, getUserProfile } from "../../Slices/auth";
import { editUserAddress } from './../../Slices/auth';
import Geosuggest from "react-geosuggest";
const AddAddress = ({setShowAddress, setShowEditAddress, showAddAddress, showEditAddress, selectedAddress, setSelectedAddress, getUserAddressList}) => {

  const [address, setAddress] = useState({...selectedAddress})

  const dispatch = useDispatch()

  const handleInputChange = (e) => {
    const { value, name } = e.target
    setAddress({...address, [name]: value})
};

const handleAddAddress = () => {
  if(showAddAddress){
      dispatch(addUserAddress(address))
      .then((response) => {
        if(response.payload.status === 200){
          response.payload.data.message.split(',').map((err) => toast.success(err))
          setShowAddress(false)
          dispatch(getUserProfile())
          getUserAddressList()
        } else {
          response.payload.message.split(',').map((err) => toast.error(err))
        }
      })
      .catch((err) => {
        toast.error(err)
      })
  }
  if(showEditAddress){
      dispatch(editUserAddress(address))
      .then((response) => {
        console.info("edit add", response)
        if(response.payload.status === 200){
          response.payload.data.message.split(',').map((err) => toast.success(err))
          setShowEditAddress(false)
          setSelectedAddress({})
        } else {
          response.payload.message.split(',').map((err) => toast.error(err))
        }
      })
      .catch((err) => {
        toast.error(err)
      })
    }
}

const handleClose = () => {
  if(showAddAddress){
    setShowAddress(false)
  }
  if(showEditAddress){
    setShowEditAddress(false)
  }
}

const getLanLngForAddress = (e) => {
  if (e !== undefined) {
      let results = e.gmaps.hasOwnProperty("address_components");

      if (results) {
          var city = "";
          var state = "";
          var country = "";
          var postal_code = "";
          var block_no = "";

          let results = e.gmaps.address_components;
          console.info("Geo Results", results)

          for (var i = 0; i < results.length; i++) {
              if (results[i].types[0] === "country") {
                  country = results[i].long_name;
              }
              if (results[i].types[0] === "administrative_area_level_1") {
                  state = results[i].long_name;
              }
              if (results[i].types[0] === "locality") {
                  city = results[i].long_name;
              }
              if (results[i].types[0] === "postal_code") {
                  postal_code = results[i].long_name;
              }
              if (results[i].types[0] === "sublocality_level_1") {
                  block_no = results[i].long_name;
              }
          }
          setAddress({...address, 
            latitude: e.location.lat.toString(),
            longitude: e.location.lng.toString(),
            block_shop_no: block_no,
            location: e.label,
            city: city,
            state: state,
            country: country,
            pin_code: postal_code,
          })
      }
  } else {
      setAddress({...address,
          city: "",
          state: "",
          country: "",
          pin_code: "",
      });
  }
};

  return (
    <div className='container addForm'>
      <section className='ServDetailPopOut'>
        <div className='contianer-fluid ServDetailPop'>
          <div className='closeIcon' onClick={() => handleClose()}>
            <i className='fa fa-times'></i>
          </div>
          <div className='serveBack addressRemoveMob' style={{cursor:"pointer"}} onClick={() => handleClose()}>
            <Link to={""} className='backbt'>
              <i className='fa fa-angle-left'></i>
            </Link>
            <h6>{showAddAddress ? " Add Address" : "Edit Address"}</h6>
          </div>
          <div className='ServDetailHead'>
            <h2>
              <Link to={"/"} className='backbt addressRemoveMob'>
                <i className='fa fa-angle-left'></i>
              </Link>
              {showAddAddress ? "Add New Address" : "Edit Address"}
            </h2>
          </div>
          <div className='formOut'>
            <form>
              <div className='formInText'>
                <div className='inpuWidth locationIco'>
                  <label className="required">Location <strong className='text-danger'>*</strong></label>
                  <Geosuggest
                      placeholder={"Enter address"}
                      initialValue={address.location && address.location}
                      onSuggestSelect={(e) => getLanLngForAddress(e)}
                      onChange={(e) => {
                          setAddress({...address, location: e});
                      }}
                      required
                  />
                </div>
                <div className="inpuWidth">
                  <label>{"Block No/Shop No."} <strong className='text-danger'>*</strong></label>
                  <input type='text' placeholder="Home, Office etc" name='block_shop_no' value={address.block_shop_no} onChange={handleInputChange} />
                </div>
              </div>
              <div className='formInText'>
                <div className='inpuWidth'>
                  <label>Province (Optional)</label>
                  <input type='text' name="province" value={address.province} className='addIcon' onChange={handleInputChange} />
                </div>
                <div className='inpuWidth '>
                  <label>City <strong className='text-danger'>*</strong></label>
                  <input type={"text"} value={address.city} readOnly />
                </div>
              </div>
              {/* <div className='formInText'>
                <div className='inpuWidth'>
                  <label>
                    House No.<span className='redText'>*</span>
                  </label>
                  <input type='text' />
                </div>
                <div className='inpuWidth'>
                  <label>Street Details</label>
                  <input type='text' name='address_2' />
                </div>
              </div>
              <div className='formInText'>
                <div className='inpuWidth'>
                  <label>Landmark</label>
                  <input type='text' name='landmark' />
                </div>
                <div className='inpuWidth'>
                  <label>
                    Area Details<span className='redText'>*</span>
                  </label>
                  <input type='text' />
                </div>
              </div> */}
              <div className='formInText'>
                <div className='inpuWidth '>
                  <label>State <strong className='text-danger'>*</strong></label>
                  <input type={"text"} value={address.state} readOnly />
                </div>
                <div className='inpuWidth'>
                  <label>
                    Country<strong className='text-danger'>*</strong>
                  </label>
                  <select>
                    <option> India</option>
                    <option> India</option>
                    <option> India</option>
                   </select>
                  {/* <input type={"text"} value={address.country} readOnly /> */}
                </div>
              </div>
              <div className="d-flex ">
                <div className={address.address_type === "home" ? "addressCapsule testClass" : "addressCapsule"} onClick={() => setAddress({...address, address_type: "home"})}>
                  <strong>Home</strong>
                </div>
                <div className={address.address_type === "office" ? "addressCapsule testClass" : "addressCapsule"} onClick={() => setAddress({...address, address_type: "office"})}>
                  <strong>Office</strong>
                </div>
              </div>

              <div className='buttonsec'>
                <input type='button' value={showAddAddress ? '+ Add Address' : "Edit Address"} onClick={() => handleAddAddress()} />
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddAddress;
