import React, {useEffect} from "react";
import "./MidSlider.scss";
import catImg1 from "../../Assets/img/coupon1.png";
import catImg2 from "../../Assets/img/coupon2.png";
import catImg3 from "../../Assets/img/coupon3.png";
import {Link} from "react-router-dom";
import { List } from 'react-content-loader';
import Slider from "react-slick";
import {useDispatch, useSelector} from "react-redux";
import {getAllCoupons, getAllOffers, getDashboard} from "../../Slices/home";
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
// import LazyLoad from 'react-lazyload';
// import { useSelector } from "react-redux";
// import Skeleton from 'react-loading-skeleton'
// import 'react-loading-skeleton/dist/skeleton.css'
const MidSlider = () => {
    const settings = {
        dots: false,
        className: "midParts",
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        arrows: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,  
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,  
                infinite: true,
                dots: false
                
              }
            }
          ]
   
    };
    //   const { homeOfferSlider, isHomeOfferSliderFetched } = useSelector((state) => state.home)
    const {
        isgetAllCouponsFetched,
        coupons
    } = useSelector((state) => state.home);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllCoupons({country_id: "103"}))
    }, [])
    return (
        <>
        
        <section className="coupons-section">
            <div className="container">
                <div className="section-title-box m-0">
                    <h2 className='sectionTitle centerText'>Coupons</h2>
                    <p className='sectionSeeAll centerText m-0'>
                        <Link className="see-all-text" to={"/"}>SEE ALL</Link>
                    </p>
                </div>
                <hr />

                {
                  coupons ? 
                  <>
                   <Slider {...settings}>
                    {coupons && coupons?.map((list, index) => {
                      return(
                          <> 
                          <Link to={`/subSpecialities/${list?.id}`}> 
                              <img className="slider-coupons-item" src={list?.coupon_image_url}  alt="coupon-img"/> 
                          </Link>
                          </>
                        )
                    })} 
                      {coupons && coupons?.map((list, index) => {
                   return(
                      <> <img className="slider-coupons-item" src={list?.coupon_image_url}  alt=""/> </>
                    )
                    })} 
                </Slider>
                  </> :
                  <section className='container'>
                    <List />
                  </section>
                }
               
            </div>  
        </section>
        
          {/* <section className='middleSlider'>
            <div div className='container'>
            <div className='row mlr0'>
              {isHomeOfferSliderFetched ?
                <Slider {...settings}>
                  {homeOfferSlider.length && homeOfferSlider.map((list) => {
                    return <div className='carouselPadding'>
                    <Link to={`/offer-listing/${list.id}`} className='carouselImg'>
                      <LazyLoad>
                        <img className="carouselSliderImg" alt='img1' src={list.offer_banner_url} />
                      </LazyLoad>
                    </Link>
                  </div>
                  })}
                  <div className='carouselPadding'>
                    <Link to='' className='carouselImg'>
                      <LazyLoad>
                        <img className="carouselSliderImg" alt='img1' src={catImg2} />
                      </LazyLoad>
                    </Link>
                  </div>
                  <div className='carouselPadding'>
                    <Link to='' className='carouselImg'>
                      <LazyLoad>
                        <img className="carouselSliderImg" alt='img1' src={catImg2} />
                      </LazyLoad>
                    </Link>
                  </div>
                  <div className='carouselPadding'>
                    <Link to='' className='carouselImg'>
                      <LazyLoad>
                        <img className="carouselSliderImg" alt='img1' src={catImg2} />
                      </LazyLoad>
                    </Link>
                  </div> 
                </Slider>
              : <Skeleton count={5} />}
               </div> 
             </div>
        </section> */}
          {/* <section className='middleSlider spaceSect '>
              <div className="  titleHead container">
                  <h2 className='sectionTitle centerText'>
                      Coupons
                  </h2>
                  <p className='sectionSeeAll centerText'>
                      <Link to={"/"}>
                          See All
                      </Link>
                  </p>
              </div>
              <div className='container  '>

                  <Slider {...settings}>

                      {coupons?.map((list, key) => {
                          return (
                              <div className='text-center' key={key}>
                                  <Link to={`/subSpecialities/${list.id}`}>
                                      <div className='imagOut'>
                                          <img
                                              className='categoryCard img-radius mx-auto'
                                              src={list.coupon_image_url}
                                              alt='img1'
                                          />
                                      </div>
                                      <span className='categoryTitle'>
                        </span>
                                  </Link>
                              </div>
                          );
                      })}

                  </Slider>
              </div>
          </section>  */}
        </>
       
    );
};

export default MidSlider;
