import React from 'react'
import './MyBookingUp.scss'
import file from "../../Assets/img/file.png";
import { useDispatch, useSelector } from 'react-redux';
// import { setShowLocationModal, setShowOptionModal } from '../../slices/service';
import PricIncDec from "../Common/PricIncDec/PricIncDec.jsx";
import flat from "../../Assets/img/flat.png";
import home from "../../Assets/img/home.png";
import pic from "../../Assets/img/Bitmap.png";
import star from "../../Assets/img/star.png";
import clock from "../../Assets/img/clock.png"
import qr from "../../Assets/img/qr.png"
import topImg from "../../Assets/img/Group3.png"
import service from "../../Assets/img/service.png";
import { Link } from 'react-router-dom';
const MyBookingRunning = () => {
    // const dispatch = useDispatch()
    // const { serviceDetail } = useSelector((state) => state.service.serviceDetails)

    // const calculateDiscount = (price, offerPrice) => {
    //     const discount = price - offerPrice
    //     return discount
    // }

  return (
    <section className='ServDetailPopOut serviceOpt myBook'>

    <div className='contianer-fluid ServDetailPop'>
           
        <div className='closeIcon' >
            <i className='fa fa-times'></i>
        </div>
        
        <div className='contetTextArea'>
            
            <div className='summarLeft'>
                <div className='summarLeftIn'>
                    <div className='summarLeftImgText'>
                        <div className='summarLeftImg'>
                            <img src={service}/>
                        </div>
                        <div className='headerFilterInfo'>
                            <strong className='serviceName'>
                            Delux Body Wash
                            </strong>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy...</p>
                            <span className='readLesMore'>Read less</span>
                        </div>
                    </div>
                </div>

                <div className="bookInfo">
                    <ul className="bookINfoIn">
                      <li>
                        <h5>Job Id</h5>
                        <span>HFGAS51653</span>
                      </li>
                      <li>
                        <h5>CAR</h5>
                        <span>BMW (GAS)</span>
                      </li>
                      <li>
                        <h5>PRICE</h5>
                        <span>₹ 430</span>
                      </li>
                      <li>
                        <h5>STATUS</h5>
                        <span className="running status">Running</span>
                      </li>
                    </ul>
                </div>
                <div className='summarLeftAdd'>
                    <ul className='listRadio addlocationradio'>
                       
                        <li>
                            <ul className='listRadio  locationradioList'>
                                    <li className='date'>
                                        <label>
                                            <h5>26 August</h5>
                                        </label>
                                    </li>
                                    <li className='date'>
                                        <label>
                                            <h5>02:00 PM</h5>
                                        </label>
                                    </li>
                                    <li className='date'>
                                        <label>
                                            <h5>45 Minutes</h5>
                                        </label>
                                    </li>
                                    <li className='date'>
                                        <label>
                                            <h5>At Home</h5>
                                        </label>
                                    </li>
                            </ul>
                        </li>
                    </ul>
                        <ul className='listRadio addlocationradio'>
                            <li className='addText'>
                            
                                <label>
                                    <h5>Outlet Address <span><i className="fa fa-location-arrow" aria-hidden="true"></i>Navigate Now</span></h5>
                                    <p>B-214, Empire Business Hub, Opp. Shakti Farm, Science City, Ahmedabad - 380060</p>
                                </label>
                            </li>
                        </ul>
                </div>
                <div className='summarRight'>
                            <h3>Payment Summary </h3>
                            <ul>
                                <li><span>Item total</span> <b>₹ 1,198</b></li>
                                <li><span>Tax</span> <b>₹ 100</b></li>
                                <li className='totalPrice'>
                                    <span>Coupon Discount (GET100OFF)</span>
                                    <b>₹ 1,458</b>
                                </li>
                                <li><b>Total</b> <b>₹ 430</b></li>
                                <li><span>20% amount paid</span> <b>- ₹ 86</b></li>
                                <li className='textBold'><b>Remaining Amount to Pay<i>(You save ₹ 100 on this booking.)</i></b> <b>₹ 344</b></li>
                            </ul>
                </div>
            </div>
           
        </div>
    </div>
    </section>
    //   <section className='ServDetailPopOut serviceOpt myBook'>

    //     <div className='contianer-fluid ServDetailPop'>
               
    //         <div className='closeIcon' onClick={() => dispatch(setShowOptionModal(false))}>
    //             <i className='fa fa-times'></i>
    //         </div>
            
    //         <div className='contetTextArea'>
                
    //             <div className='summarLeft'>
    //                 <div className='summarLeftIn'>
    //                     <div className='summarLeftImgText'>
    //                         <div className='summarLeftImg'>
    //                             <img src={""}/>
    //                         </div>
    //                         <div className='headerFilterInfo'>
    //                             <strong className='serviceName'>
    //                             Delux Body Wash
    //                             </strong>
    //                             <div className='contInfo'>
                                    
    //                                 <div className='contInfoIn'>
    //                                     <ul className='startTask'>
    //                                         <li>
    //                                         <a href="">
    //                                         <img src={star} alt="rating"/>
    //                                                 <span className=' rating'>4.5</span>
    //                                             </a> 
    //                                         </li>
    //                                         <li>
    //                                         <img src={clock} alt="time"/>
    //                                             <span className='time flex-grow-1'>
    //                                                 30 Min
    //                                             </span>
    //                                         </li>
    //                                     </ul> 
                                    
    //                                     <ul className='price'>
    //                                         <li>
    //                                         ₹ 599
    //                                         </li>
    //                                         <li>
    //                                             <span className='textCut'>₹ 799</span>
    //                                         </li>
    //                                         <li>
    //                                             <span className='textSave'>
    //                                             Save ₹ 200</span>
    //                                         </li>
    //                                     </ul>
    //                                 </div> 
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className='summarLeftCount'>
    //                         <span className='idName'>ID : 5484 515 545</span>
    //                     </div>
    //                 </div>
    //                 <div className='summarLeftAdd'>
    //                     <ul className='listRadio addlocationradio'>
                            
    //                         <li className='quant'>
    //                             <h4>Quantity</h4>
    //                             <label>
    //                                 <span>2</span>
    //                             </label>
    //                         </li>
    //                         <li className='homeLoc'>
    //                             <h4>Location</h4>
    //                             <label>
    //                                 <img src={home}/>
    //                                 <span>At Home</span>
    //                             </label>
    //                         </li>
    //                         <li>
    //                             <h4>Schedule</h4>
    //                             <ul className='listRadio  locationradioList'>
    //                                 <li className='date'>
    //                                     <label>
    //                                         <span>Wed</span>
    //                                         <h5>25</h5>
    //                                     </label>
    //                                 </li>
    //                                 <li className='time'>
    //                                     <label>
    //                                         <p>07:00 AM</p>
    //                                     </label>
    //                                 </li>
    //                             </ul>
    //                         </li>
    //                         <li className='status comp'>
    //                             <h4>Status</h4>
    //                             <label>
    //                                 <span>EXPIRED</span>
    //                             </label>
    //                         </li>
    //                         <li className='qr'>
    //                             <h4>QR/OTP</h4>
    //                             <label>
    //                                 <img src={qr}/>
    //                                 <span>575621</span>
    //                             </label>
    //                         </li>
    //                         {/* <li className='addText'>
    //                         <h4>Address</h4>
    //                             <label>
    //                                 <h5>Raj Patel</h5>
    //                                 <p>G-57, Goving Park, Ghodasar Road, manekchowk,Ahmedabad-380050
    //                                     <span>Mo : +91 8141000000</span></p>
    //                             </label>
    //                         </li> */}
    //                     </ul>
    //                     <h4>Address</h4>
    //                         <ul className='listRadio addlocationradio'>
    //                             <li className='addText'>
                                
    //                                 <label>
    //                                     <h5>Raj Patel</h5>
    //                                     <p>G-57, Goving Park, Ghodasar Road, manekchowk,Ahmedabad-380050
    //                                         <span>Mo : +91 8141000000</span></p>
    //                                 </label>
    //                             </li>
    //                         </ul>
    //                 </div>
    //                 <div className='optionText'>
    //                         <h4>Options</h4>
    //                         <p>L’Oreal Paris Black, L’Oreal Paris Golden</p>
    //                 </div>
    //                 <div className='optionText'>
    //                         <h4>Reference Image</h4>
    //                         <ul>
    //                             <li><img src={pic}/></li>
    //                             <li><img src={pic}/></li>
    //                             <li><img src={pic}/></li>
                                
    //                         </ul>
    //                 </div>
    //                 <div className='summarRight'>
    //                             <h3>Payment Summary <Link to={"/"} className="viewRe">View Reciept</Link></h3>
    //                             <ul>
    //                                 <li><span>Item total x 2</span> <b>₹ 1,198</b></li>
    //                                 <li><span>{"L’Oreal Paris > Black"}</span> <b>₹ 100</b></li>
    //                                 <li><span>{"L’Oreal Paris > Golden"}</span> <b>₹ 160</b></li>
    //                                 <li className='totalPrice'>
    //                                     <span>Total</span>
    //                                     <b>₹ 1,458</b>
    //                                 </li>
    //                             </ul>
    //                 </div>
    //             </div>
               
    //         </div>
    //     </div>
    //     </section>
    )
  }
  
  export default MyBookingRunning


