import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LogoText from "../Common/LogoText/LogoText.jsx";
import { toast } from 'react-toastify';
import './Login.scss'

import { getCountryList, showLogin, showOtp, showRegister, userExist, userSignIn } from '../../Slices/auth.js';
import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import firebaseConfig from '../../Config/firebaseConfig.js';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';

const Login = ({ handleInputChange, user, setUser, initialUserValue }) => {
  const dispatch = useDispatch()
  const [countryList, setCountryList] = useState([])
  const [requiredCountryCode, setRequiredCountryCode] = useState()
  const [requiredMobileNo, setRequiredMobileNo] = useState()
  const [country_code, setCountry_code] = useState()
  const { t, i18n } = useTranslation();
  const [phone, setPhone] = useState();
  const [otp, setOtp] = useState('');
  const navigate = useNavigate()
  const showOTP = useSelector((state) => state.auth.showOTP)
  // console.log(state)

  // useEffect(() => {
  //   if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  //     "recaptcha-container",
  //     {
  //       size: "invisible",
  //       callback: function(response) {
  //         submitPhoneNumberAuth();
  //       },
  //     }
  //   );
  //   // eslint-disable-next-line
  // },[])

  // const submitPhoneNumberAuth = async () => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       var phoneNumber = user.country_code + user.mobile_no;
  //       var appVerifier = window.recaptchaVerifier;
  //       firebase
  //         .auth()
  //         .signInWithPhoneNumber(phoneNumber, appVerifier)
  //         .then(function(confirmationResult) {
  //           window.confirmationResult = confirmationResult;
  //           resolve(confirmationResult);
  //           toast.success("OTP Sent Successfully.")
  //           dispatch(showOtp(true))
  //         })
  //         .catch(function(error) {
  //           console.log(error);
  //           // toast.error(error.message);
  //           reject(error.message);
  //         });
  //     } catch (error) {
  //       console.log(error);
  //       reject(error.message);
  //     }
  //   });
  // };


  const checkUserMobNumber = (e) => {
    console.log(user)
    e.preventDefault();
    if (user.country_code === "") {
      setRequiredCountryCode(false)
    } else {
      setRequiredCountryCode(true)
    }
    if (user.mobile_no === "") {
      setRequiredMobileNo(false)
    } else {
      setRequiredMobileNo(true)
    }
    if (user.mobile_no !== "" && user.mobile_no.length < 10) {
      toast.error("Please Enter Valid Mobile Number")
    }
    if (requiredCountryCode && requiredMobileNo && user.mobile_no.length === 10) {
      dispatch(userExist(user))
        .then((response) => {
          if (response.payload.status === 200) {
            if (response.payload.data.result.is_exists === false) {
              toast.error(response.payload.data.message, { autoClose: 7000 })
              user.mobile_no = ""
              setTimeout(() => {
                dispatch(showRegister(true))
              }, 2000)
            } else {
              // alert("GTG -> SignIn API")
              // submitPhoneNumberAuth()
              dispatch(showOtp(true))
            }
          } else {
            // toast.error(response.payload.message,{autoClose: 7000})
          }
          console.info(response)
        })
        .catch((err) => {
          toast.error(err)
        })
    }
  }


  const getCountryCode = () => {
    dispatch(getCountryList())
      .then((response) => {
        setCountryList(response.payload.data.result.data)
      })
  }


  const handleVerifyOTP = () => {
    if (otp !== "111111") {
      toast.error("Enter Valid OTP")
      return null
    }
    dispatch(userSignIn(user))
      .then((response) => {
        if (response.payload.status === 200) {
          toast.success(response.payload.data.message, { position: "top-center" })
          setTimeout(() => {
            dispatch(showOtp(false))
            navigate('/')
          }, 1500)
        } else {
          toast.error(response.payload.data.message)
        }
      })
  }


  useEffect(() => {
    getCountryCode()
    // eslint-disable-next-line
  }, [])


  const handleChange = (e) => {
    const result = e.target.value.replace(/\D/g, '');
    setPhone(result);
  };

  const handleChangeopt = (otp) => {
    setOtp(otp);
  };

  return (
    <>
      <div className="main-box">
        <div className="container">
          <div className='login-header'>
            <h3 className="login-title"> Login</h3>
          </div>
        </div>

        {!showOTP ? <div className='container login-box'>
          <div className='row justify-content-between'>
            <div className='col-12 col-xl-5 login-welcome-section'>
              <h2 className='login-welcome'>Welcome Back!</h2>
              <p className='login-opportunity'>Don’t miss your next opportunity. Sign In to stay updated on your proffesional world.</p>
            </div>

            <div className='col-12 col-xl-7 login-field-box'>
              <div>
                <div className='label-body'>
                  <label className='login-phone'>Phone Number</label>
                </div>

                <div className='phone-field-body'>
                  <div className='dialcode-body'>
                    <select name='country_code' className='login-phone-dialcode bg-white' onChange={handleInputChange}>
                     
                      <option selected value={"Select Country Code"}>Select Code
                      </option>
                     
                      <option  value={"+91"}>{"IN (+91)"}
                      </option>
                      {countryList?.map((list) => {
                        return (
                          <option key={list.id} value={"+" + list.phone_code}>{`${list.country_code
                            } (${"+" + list.phone_code})`}</option>
                        );
                      })}
                    </select>

                  </div>
                  <div className='phone-body'>
                    <input type="text" id="fname" name='mobile_no' className='login-phone-field bg-white' placeholder="Phone" value={phone} onChange={handleInputChange} />
                  </div>
                </div>
              </div>


              <div className='mt-3 mb-2'>
                <div className='line-h'></div>
              </div>

              <div className='col-12 p-0' onClick={(e) => checkUserMobNumber(e)}>
                <button className="get-otp-button"><span className='button-text'  > Get OTP </span></button>

                <div className='skip-login'>
                  <Link to={"#"} className='skip-login-text'>Skip login & go to dashboard</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

          :

          <div className='container login-box '>
            <div className='row justify-content-between'>
              <div className='col-12 col-xl-5 login-welcome-section'>
                <h2 className='login-welcome'>Welcome Back!</h2>
                <p className='login-opportunity'>Don’t miss your next opportunity. Sign In to stay updated on your proffesional world.</p>
              </div>

              <div className='col-12 col-xl-7 login-field-box'>
                <div className='label-body mt-3'>
                  <label className='login-phone'>Phone Number</label>
                  <Link to={"/"} className='login-label'>Change Number</Link>
                </div>
                <div className='phone-field-body'>
                  <div className='dialcode-body'>
                    <input type="text" id="fname" name="Phone" className='login-phone-dialcode' disabled placeholder={user?.country_code} style={{ textAlign: 'center' }} />
                  </div>
                  <div className='phone-body'>
                    <input type="text" id="fname" name="Phone" className='login-phone-field' disabled placeholder="Phone" value={user?.mobile_no} />
                  </div>
                </div>

                <div className='inpuWidth fullwidth'>
                  <div className='formInText mb0 pb0 m0'>
                    <label>
                      Type OTP Here
                      <span style={{ cursor: "pointer" }}>Resend</span>
                    </label>
                    <div className='optValue'>
                    <OtpInput
                            value={otp}
                            onChange={(value)=> setOtp(value)}
                            numInputs={6}
                            separator={<span />}
                          />
                    </div>
                  </div>
                </div>

                <div className='mt-3 mb-2'>
                  <div className='line-h'></div>
                </div>

                <div className='col-12 p-0'>
                  <button className="get-otp-button"><span className='button-text' onClick={handleVerifyOTP}>Validate</span></button>
                  <div className='skip-login'>
                    <Link to={"#"} className='skip-login-text'>Skip login & go to dashboard</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>}

        {/* <div className='container login-box'>
            <div className='row'>
              <div className='col-12 col-xl-6'>
                    <h2 className='login-welcome'>Welcome Back!</h2>
                    <p className='login-opportunity'>Don’t miss your next opportunity. Sign In to stay updated on your proffesional world.</p>
              </div>
              <div className='col-12 col-xl-6'>
                    <h3 className='login-phone'>Phone Number</h3>
                    <input type="text" id="fname" name="Phone" className='login-phone-field' placeholder="Phone"/>
                  
                    <div className='col-12 p-0'> 
                        <h3 className='login-phone'>Type OTP Here</h3>
                        <div className='d-flex justify-content-between'>
                          <input type="text" maxLength={1} id="otp1" name="otp" className='login-otp-field' onKeyUp={(e)=>otp_validate(e)}/>
                          <input type="text" maxLength={1} id="otp2" name="otp" className='login-otp-field' onKeyUp={(e)=>otp_validate(e)}/>
                          <input type="text" maxLength={1} id="otp3" name="otp" className='login-otp-field' onKeyUp={(e)=>otp_validate(e)}/>
                          <input type="text" maxLength={1} id="otp4" name="otp" className='login-otp-field' onKeyUp={(e)=>otp_validate(e)}/>
                          <input type="text" maxLength={1} id="otp5" name="otp" className='login-otp-field' onKeyUp={(e)=>otp_validate(e)}/>
                          <input type="text" maxLength={1} id="otp6" name="otp" className='login-otp-field' onKeyUp={(e)=>otp_validate(e)}/>
                        </div>
                    </div>
                    <hr />
                    <div className='col-12 p-0'> 
                    <button className="get-otp-button">Get OTP</button>
                    <div className='skip-login'>
                      <Link to={"#"} className='skip-login-text'>Skip login & go to dashboard</Link>
                    </div>
                </div>
              </div>
            </div>
          
              
           
        </div> */}
      </div>

      {/*<div className='container mt-3'>
      <section className='ServDetailPopOut login'>
        <div className='contianer-fluid ServDetailPop'>
          <div className='closeIcon' onClick={() => {dispatch(showLogin(false)); setUser(initialUserValue)}}>
            <i className='fa fa-times'></i>
          </div>
          <div className='ServDetailPopScrollIn'>
          <LogoText isLoggedIn={true}/>
          <div className='formOut'>
            <form onSubmit={(e) => checkUserMobNumber(e)}>
              <div className='formInText'>
                <label>{t('common.phone_number')}</label>
                <div className='phLeft'> */}
      {/* <input type='tel' name='country_code' value={"+91"} /> */}
      {/*<select name='country_code' onChange={handleInputChange}>
                   
                    <option selected value={""}>
                    {t('common.select_code')}
                    </option>
                    <option  value={"+91"}>{"IN (+91)"}
                    </option>
                    {countryList?.map((list) => {
                      return (
                        <option key={list.id} value={"+" + list.phone_code}>{`${
                          list.country_code
                        } (${"+" + list.phone_code})`}</option>
                      );
                    })}
                  </select>
                  {((!requiredCountryCode && requiredCountryCode !== undefined) && user.country_code === "") && <strong className='text-danger'>
                  {t('common.country_code_required')}
                    </strong>}
                </div>
                <div className='pHright'>
                  <input
                    placeholder="e.g.9997823498"
                    type='tel'
                    name='mobile_no'
                    value={user.mobile_no}
                    onChange={(e) => handleInputChange(e)}
                    maxLength={10}
                    
                  />
                  {(!requiredMobileNo && requiredMobileNo !== undefined && user.mobile_no === "") && <strong className='text-danger'>
                  {t('common.mobile_code_required')}                    
                    </strong>}
                </div>
                  </div>*/}
      {/* Add a container for reCaptcha */}
      {/*} <div id="recaptcha-container" />
              <div className='buttonsec'>
                <input
                  type='submit'
                  value='Get OTP'
                  onClick={(e) => checkUserMobNumber(e)}
                />
              </div>
            </form>
            <span
              className='skipText'
              style={{ cursor: "pointer" }}
              onClick={() => {dispatch(showLogin(false)); setUser(initialUserValue); dispatch(showRegister(true))}}
            >
              {t('common.sign_in_here')}
            </span>
          </div>
          </div>
        </div>
      </section>
                </div>*/}
    </>

  );
}

export default Login