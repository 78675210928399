import React from 'react'
import Banners from './Banners/Banners'
import Specialites from './Specialites/Specialites';
import TopSerices from './TopSerices/TopSerices';
import TopPackages from './TopPackages/TopPackages';
import DowloadApp from './DowloadApp/DowloadApp';
import MidSlider from './MidSlider/MidSlider';
import OffersSlider from './OffersSlider/OffersSlider';
import ServiceDetail from './ServiceDetail/ServiceDetail';
import {useSelector, useDispatch} from 'react-redux';
import ServiceDetailSchedule from './ServiceDetailSchedule/ServiceDetailSchedule';
import ServiceDetailAddress from './ServiceDetailAddress/ServiceDetailAddress';
import ServiceDetailChooseCar from './ServiceDetailChooseCar/ServiceDetailChooseCar';
import PaymentOption from './PaymentOption/PaymentOption';
import ConFirmBooking from './ConFirmBooking/ConFirmBooking';
import {useEffect} from 'react';
import {getAllOffers, getDashboard} from '../Slices/home';
import {getUserProfile} from '../Slices/auth';
import { useTranslation, withTranslation, Trans } from 'react-i18next';

const Home = () => {
    const {
        showServiceDetails,
        showScheduleOpt,
        showAddressOpt,
        showPaymentOpt,
        showConfirmBooking,
        dashboardSections,
        isDashboardSectionsFetched,
        isgetAllOffersFetched,
        offers,
        ServiceChooseCar
    } = useSelector((state) => state.home);

    const {isLoggedIn} = useSelector((state) => state.auth)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDashboard({country_id: "103"}))

        navigator.geolocation.getCurrentPosition(function (position) {
            console.info("Latitude is :", position.coords.latitude);
            console.info("Longitude is :", position.coords.longitude);
        });
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isLoggedIn) dispatch(getUserProfile())
        // eslint-disable-next-line
    }, [isLoggedIn])
        
    return (
        <div>
            <Banners/>
            <Specialites categories={dashboardSections.speciality} isDashboardSectionsFetched={isDashboardSectionsFetched}/>
            <TopSerices dashboardSections={dashboardSections.trending_services} isDashboardSectionsFetched={isDashboardSectionsFetched} sections={false}/> 
            <MidSlider/>
            <TopSerices dashboardSections={dashboardSections.trending_services} isDashboardSectionsFetched={isDashboardSectionsFetched} sections={false}/>
                    {/* <TopSerices dashboardSections={dashboardSections.section} isDashboardSectionsFetched={isDashboardSectionsFetched} sections={true}/> */}
                    {/* <TopSerices dashboardSections={dashboardSections.trending_services} isDashboardSectionsFetched={isDashboardSectionsFetched} sections={false}/>  */}
                    {/* <TopSerices dashboardSections={dashboardSections.trending_services} isDashboardSectionsFetched={isDashboardSectionsFetched} sections={false}/>  */}
                    {/* <OffersSlider /> */}
             <TopPackages dashboardSections={dashboardSections.packages} isDashboardSectionsFetched={isDashboardSectionsFetched} sections={true}/> 
             <TopSerices dashboardSections={dashboardSections.trending_services} isDashboardSectionsFetched={isDashboardSectionsFetched} sections={false}/>
             <DowloadApp/>

            {/* {showServiceDetails && <ServiceDetail/>}
            {showScheduleOpt && <ServiceDetailSchedule/>}
            {ServiceChooseCar && <ServiceDetailChooseCar/>}
            {showAddressOpt && <ServiceDetailAddress/>}
            {showPaymentOpt && <PaymentOption/>}
            {showConfirmBooking && <ConFirmBooking/>} */}

        </div>
    )
}

export default Home