import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import clock from "../../../Assets/img/clock.png"
import { listFavorite, removeServiceToFavorite } from '../../../Slices/auth';

const MyFavorite = () => {
  const dispatch = useDispatch()
  const [favoriteList, setFavoriteList] = useState({loading: true, list: []})

  useEffect(() => {
    getFavoriteList()

    // eslint-disable-next-line
  },[])

  const { list } = favoriteList

  const getFavoriteList = () => {
    dispatch(listFavorite())
    .then((response) => {
      setFavoriteList({loading: false, list: response.payload.result.data})
      // toast.success(response.payload.message)
    })
    .catch((err) => {
      setFavoriteList({...favoriteList, loading: false})
      console.error(err)
    })
  }

  const handleRemoveFavorite = (e, id) => {
    e.preventDefault()
    dispatch(removeServiceToFavorite(id))
    .then((response) => {
      toast.info(response.payload.message)
      getFavoriteList()
    })
    .catch((err) => toast.error(err))
  }

  return (
    <div className='profDetailIn'>
      <div className='profdetHeadText'>
          <h3><span className="backbt desknone"><i className="fa fa-angle-left"></i></span>My Favourites</h3>
        
      </div>
      <div className='formOut profdet favBox'>
        <form>
          <div className='formInText'>
            {!favoriteList.loading ? (
              favoriteList.list && favoriteList.list.length > 0 ? (
                list.map((favService, key) => 
                <div className='inpuWidth' key={key}>
                  <div className='summarLeftIn'>
                    <div className='summarLeftImgText'>
                      <div className='summarLeftImg'>
                        
                        <img src={favService.service_image_url} alt='img' />
                      </div>
                      <div className='headerFilterInfo'>
                        <strong className='serviceName'>{favService.service_name}</strong>
                        <div className='contInfo'>
                          <div className='contInfoIn'>
                            <ul className='price'>
                              <li>{"₹ 599"}</li>
                              <li>
                                <span className='textCut'>₹ 799</span>
                              </li>
                              <li>
                                <span className='textSave'>Save ₹ 200</span>
                              </li>
                            </ul>
                            <ul className='startTask'>
                              <li>
                                <img src={clock} alt='time' />
                                <span className='time flex-grow-1'>
                                  {favService.duration + " Min"}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='summarLeftCount'>
                      <button className='mb10 smbtn altsub' 
                        onClick={(e) => handleRemoveFavorite(e, favService.id)}>
                        REMOVE
                      </button>
                      <span className='smbtn' style={{ cursor: "pointer" }}>
                        BOOK
                      </span>
                    </div>
                  </div>
                </div>
                )
              ) : (
                <strong>No Service Found in your Favorite List.</strong>
              )
            ) : (
              <strong>Loading...</strong>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default MyFavorite