import React, { useEffect, useState } from 'react'
import './PackageDetailSchedule.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { setShowScheduleModal, getTimeSlots, setServiceValues } from '../../slices/service';
// import calculateDiscount from '../../helper/calculateDiscount';
import car from "../../Assets/img/carIcon.png"
import { toast, ToastContainer } from 'react-toastify';
import { showAddressOpt, showServiceDetails } from '../../Slices/home';
import clock from "../../Assets/img/clock.png";



const PackageDetailSchedule = () => {
    // const { serviceDetails } = useSelector((state) => state.home)
    const navigate = useNavigate();
    const [date, setDate] = useState(-1);
    const [time, setTime] = useState("");
    const dispatch = useDispatch()
    const {state} = useLocation()
    const todayDate = new Date().toLocaleDateString("af-ZA");
    const days = [...Array(7).keys()].map((days) =>
        new Date(Date.now() + 86400000 * days).toDateString().split(" ")
    );
    let serviceDetails=state.serviceDetails.service_details
    console.log(state.serviceDetails)
    return (
        <>
            <section className="container service-details">
                <div className="service-details-title">
                    <i class="fa fa-angle-left" onClick={() => navigate(-1)}></i>
                    <span className="service-details-title-text">Schedule</span>
                </div>

                <div className="service-details-body">
                    <div className="row">
                        <div className="col-12 col-lg-5">
                            <div className="">
                                <img className="service-details-img" src={serviceDetails?.service_image_url} alt="" />
                            </div>

                            <div className='service-details-information marginTop-19 Schedule'>

                                <div className="service-details-info">
                                    <h6 className="service-details-name">{serviceDetails?.service_name}</h6>
                                    <p className="Schedule-service-details-description">{serviceDetails?.description}</p>
                                </div>
                                <div className="service-details-time">
                                    <img src={clock} alt="" width={15} height={15} />
                                    <span className="time">{serviceDetails?.duration} Minutes. </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-7 choose-provider">
                            <div className='schedule-title-box'>
                                <div>
                                    <span className='schedule-box'>Schedule</span>
                                </div>
                                <div className='date-box'>
                                    <div className='date-bar'>
                                        <span className='selec-date-text'>Select Date of Service</span>
                                        <span className='date'>August 2022</span>
                                    </div>
                                    <div className='date-list'>
                                        {
                                            [...Array(8)].map((item, index) => {
                                                return (
                                                    <div className={`${index === date ? `date--box-active` : `date--box`}`} onClick={() => { setDate(index) }}>
                                                        <span className='day-name'>Web</span>
                                                        <span className='date-day'>{index + 1}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className='time--box'>
                                    <span className='time-title'>Time to Start The Service</span>
                                    <div className='time-list'>
                                        {["07:00 AM", "08:00 AM", "09:00 AM", "10:00 AM", "11:00 AM", "12:00 PM", "01:00 PM", "02:00 PM", "03:00 PM", "04:00 PM", "05:00 PM", "06:00 PM"].map((item, index) => {
                                            return (
                                                <div className={`${time === item ? "time-item-active" : "time-item"}`} onClick={(() => setTime(item))}  >{item}</div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className='choose-provider-footer'>
                                <span className='choose-provider-price'>Total : ₹ 499</span>
                                <Link to="/chooseCar">
                                    <button className='next-button'>CONTINUE</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Old Design */}
            {/*
      <section className='ServDetailPopOut packDetShe'>
        <ToastContainer />
        <div className='contianer-fluid ServDetailPop'>
        <div className='serveBack'>
            <h6 onClick={() => dispatch(showServiceDetails(true))} style={{cursor: "pointer"}}>
                <Link to={""} className="backbt"><i className="fa fa-angle-left"></i></Link>
                Schedule
            </h6>
            </div>
            <div className='closeIcon' onClick={() => dispatch(showServiceDetails(false))}>
                <i className='fa fa-times'></i>
            </div>
            <div className='contetTextArea'>
                <div className='headerFilterInfo'>
                                <strong className='serviceName'>
                                    {/* {serviceDetail.service_name} */}
            {/*  Vacuum seats, carpet, floor mat, between and under...
                                </strong>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book…</p>
                                <div className='contInfo'>
                                    
                                <div className='contInfoIn'>
                                        <ul className='startTask'>
                                                
                                                <li>
                                                    
                                                    <img src={car} alt="time"/>
                                                    <span className='time flex-grow-1'>
                                                    30 Times
                                                        {/* {serviceDetail.service_duration} */}
            {/* </span>
                                            </li>
                                        </ul> 
                                    </div> 
                                </div>
            </div>
            <div className='listMenu aftNone'>
                <ul className='listTex'>
                    <li><span className='active'>Schedule</span></li>
                </ul>
            </div>
            <div className='row listBox mb15'>
                <div className='leftBrandLocation'>
                    <h4>Select Date of Service <span>August 2022</span></h4>
                    <ul className='listRadio locationradio' >
                        
                        <li>
                                <input type="radio"  name="date"/>
                                <label>
                                    <span>wed</span>
                                    <h5>26</h5>
                                </label>
                            </li>
                            <li>
                                <input type="radio"  name="date"/>
                                <label>
                                    <span>Thu</span>
                                    <h5>27</h5>
                                </label>
                            </li>
                            <li>
                                <input type="radio"  name="date"/>
                                <label>
                                    <span>Fre</span>
                                    <h5>28</h5>
                                </label>
                            </li>
                    </ul>
                </div>
            </div>
            <div className='row listBox aftNone mb0'>
                <div className='leftBrandLocation'>
                    <h4>Choose Prefered Time Slot</h4>
                    <ul className='listRadio locationradioList' >
                        
                    
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>07:00 AM</span>
                            </label>
                        </li>
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>08:00 AM</span>
                            </label>
                        </li>
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>09:00 AM</span>
                            </label>
                        </li>
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>10:00 AM</span>
                            </label>
                        </li>
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>11:00 AM</span>
                            </label>
                        </li>
                        <li >
                            <input type="radio" name="time" />
                            <label>
                                <span>07:00 AM</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
            <div className='detOpFoot'>
                 <div className='col priceNum'>
                        {/* 
                        <div className='totalPrice'>Total : <span className='price'> ₹ {total}</span></div> */}

            {/*   <div className='totalPrice'>Total : <span className='price'> ₹ 599</span></div>
                    </div>
                <div className='col rightDet'>
                <button style={{cursor: "pointer"}} onClick={() => dispatch(showAddressOpt(true))} >
                        CONTINUE
                    </button>
                </div>
            </div>
        </div>
        </section>*/}
        </>

    )
}

export default PackageDetailSchedule



