import React from "react";
import "./ServiceDetailAddress.scss";
import { useDispatch, useSelector } from "react-redux";
import home from "../../Assets/img/home.png";
import clock from "../../Assets/img/clock.png";
import office from "../../Assets/img/flat.png";
import {
  showAddressOpt,
  showPaymentOpt,
  showScheduleOpt,
  showServiceDetails,
  serviceChooseCar
} from "../../Slices/home";
import { useState } from "react";
import { handleBookingDetails, handleResetBookingDetails } from "../../Slices/auth";
import AddAddress from "../AddAddress/AddAddress";
import { toast } from "react-toastify";
import { useEffect } from "react";
const ServiceDetailAddress = () => {
  const dispatch = useDispatch();
  const { isSchedule, serviceDetails } = useSelector((state) => state.home);
  const { user_address } = useSelector((state) => state.auth.user)
  const { bookingDetails } = useSelector((state) => state.auth)
  const [locationType, setLocationType] = useState({home: true, outlet: false})
  const [showAddAddress, setShowAddAddress] = useState(false)


  const handleLocationSelect = (location) => {
    if(location === "home"){
      setLocationType({home: true, outlet: false})
      dispatch(handleBookingDetails({location_type: "home"}))
    }
    if(location === "outlet"){
      setLocationType({home: false, outlet: true})
      dispatch(handleBookingDetails({location_type: "outlet"}))
    }
  }

  const handleNextClick = () => {
    if(bookingDetails.location_type === undefined){
      toast.error("Select Location to Continue")
    } else if((locationType.home && bookingDetails.location === undefined)){
      toast.error("Select Address to Continue")
    } else {
       dispatch(showPaymentOpt(true)); 
      //dispatch(serviceChooseCar({type: "isSchedule",payload: true})) 
      dispatch(handleBookingDetails({total_price: serviceDetails.price}))
    }

  }

  const handleClose = () => {
    dispatch(showAddressOpt(false))
    dispatch(handleResetBookingDetails())
  }


  useEffect(() => {
    dispatch(handleBookingDetails({location_type: "home"}))
    // eslint-disable-next-line
  },[])


  return (
    <section className='ServDetailPopOut servAdd'>
      <div className='contianer-fluid ServDetailPop'>
        <div className='serveBack'>

          <h6
            style={{ cursor: "pointer" }}
            onClick={() =>
              isSchedule
                ? dispatch(showScheduleOpt(true))
                : dispatch(showServiceDetails(true))
            }
          >
            <span className='backbt'>
              <i className='fa fa-angle-left'></i>
            </span>
            Location
          </h6>
        </div>
        <div
          style={{ cursor: "pointer" }}
          className='closeIcon'
          onClick={() => handleClose()}
        >
          <i className='fa fa-times'></i>
        </div>
        <div className='contetTextArea' style={{height: `${locationType.outlet ? '22em' : '30em'}`}}>
          <div className='headerFilterInfo'>
            <strong className='serviceName'>
              {serviceDetails.service_name}
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                when an unknown printer took a galley of type and scrambled it to make a type specimen book…<a>Read More</a></p>
            </strong>
            <div className='contInfo'>
              <div className='contInfoIn'>
                <ul className='startTask'>
                  <li>
                    <img src={clock} alt='time' />
                    <span className='time flex-grow-1'>
                      {" "}
                      {serviceDetails.duration + " Minutes"}
                    </span>
                  </li>
                </ul>

                <ul className='price'>
                  <li>
                    {serviceDetails.currency.symbol}
                    {serviceDetails.offer_price != "" ? serviceDetails.offer_price : serviceDetails.price}
                  </li>
                  {
                    serviceDetails.offer_price != "" ?
                        <>
                          <li>
                            <span className='textCut'> {serviceDetails.offer_price != "" ? serviceDetails.price : serviceDetails.offer_price}</span>
                          </li>
                          <li>
                            <span className='textSave'>Save {serviceDetails.offer_discount_price}</span>
                          </li>
                        </>:""
                  }

                </ul>
              </div>
            </div>
          </div>
          <div className='listMenu'>
          <ul className='listTex paym'>
            <li>
              <a href='' className='active'>
              Location
              </a>
            </li>
          </ul>
        </div>
          <div className='listMenu listLOc'>
            <h4>Choose Location</h4>
            <ul className='listTex'>
              <li>
                <span className='active'></span>
              </li>
            </ul>
            <ul className='listRadio addlocationradio'>
              <li className='homeLoc' onClick={() => handleLocationSelect("home")}>
                <label className={locationType.home && "selected"}>
                  <img src={home} alt='home' />
                  <span>At Home</span>
                </label>
              </li>
              <li className='homeLoc' onClick={() => handleLocationSelect("outlet")}>
                <label className={locationType.outlet && "selected"}>
                  <img src={office} alt='home' />
                  <span>At Outlet</span>
                </label>
              </li>
            </ul>
          </div>
          {locationType.home && 
            <div className='row listBox'>
              <div className='leftBrandLocation'>
                <h4>Saved Address</h4>
                <ul className='listRadio adressradio'>
                  <li style={{ cursor: "pointer" }}  onClick={() => setShowAddAddress(!showAddAddress)}>
                    + Add New Address
                  </li>
                  {(user_address && user_address.length > 0) && user_address?.map((list, key) => {
                  return <li key={key} 
                  onClick={() => dispatch(handleBookingDetails({location: list.location, address_id: list.id, country_id: "103"}))}>
                    <input type='radio' name='address'  />
                    <label>
                      <h5>{list.block_shop_no}</h5>
                      <p>
                          {list.location}<br/>
                          {list.address_type}
                          {/* <span>Mo : missing in api</span> */}
                      </p>
                    </label>
                  </li>
                  })}
                  {(user_address && user_address.length === 0) && <strong>No Address found.</strong>}
                </ul>
              </div>
            </div>
          }
          <div className='detOpFoot'>
            <div className='col priceNum'>
              <div className='totalPrice'>
                Total : 
                <span className='price'>
                  {serviceDetails.currency.symbol}
                  {serviceDetails.offer_price != "" ? serviceDetails.offer_price : serviceDetails.price}
                </span>
              </div>
            </div>
            <div className='col rightDet'>
              <button
                style={{ cursor: "pointer" }}
                onClick={() => handleNextClick()}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
      {showAddAddress && <AddAddress
        showAddAddress={showAddAddress}
        showEditAddress={false}
        selectedAddress={{}}
        setShowAddress={setShowAddAddress}
        setShowEditAddress={null} 
      />}
    </section>
  );
};

export default ServiceDetailAddress;
