import React from "react";
import "./ProductBox.scss";
import LazyLoad from "react-lazyload";
import Price from "../Common/Price/Price.jsx";
import Button from "../Common/Button/Button";
import clock from "../../Assets/img/clock.png";
import { Link ,useParams } from "react-router-dom";
const ProductBox = ({ services, key }) => {
  return (
    <>
      <div className="product" key={key}>
        <div className="product-img-box">
          <img
            src={services.service_image_url}
            className="product-img"
            alt="img1"
          />
        </div>

        <div className="title-box ">
          <h3 className="title">{services.service_name}</h3>
          <div
            className="card-info"
            dangerouslySetInnerHTML={{ __html: services.description }}
          />
        </div>

        <div className="headerFilterInfo">
          <div className="contInfo">
            <div className="price-box">
              <span className="price">{services.price}</span>
              {services.mrp ? (
                <>
                  
                  <span className="mrp">₹ {services.mrp}</span>
                </>
              ) : (
                <></>
              )}
              {services.save_price ? (
                <>
                  
                  <span className="save-price">
                    ₹ {services.save_price || 55}
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="book-box">
              <div className="time-box">
                <img src={clock} alt="" />
                <span className="time">{services.duration} Minutes</span>
              </div>
              <div>
                <Button serviceId={services.id} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='box-item col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2'>
                              <img src={"/static/media/service.88ed75372ffbe7d100f7.png"} alt="" />
                              <div className='title-box'>
                                <h3 className='title'>{services.title}</h3>
                                <p className='card-info'>{services.info}</p>
                              </div>

                              <div className='price-box'>
                                  <span className='price'>₹ {services.price}</span>
                                  {services.mrp ? <> <span className='mrp'>₹ {services.mrp}</span></> : <></>}
                                  {services.save_price ? <> <span className='save-price'>₹ {services.save_price}</span></> : <></>}
                              </div>
                              <div className='book-box'>
                                  <div className='time-box'>
                                    <img src={services.icon} alt="" />
                                    <span className='time'>35 Minutes</span></div>
                                  <div>
                                    <Link  to={"/"} className="book-button">
                                      BOOK
                                    </Link>
                                  </div>
                              </div>
                    </div> */}
    </>
  );
};

export default ProductBox;
