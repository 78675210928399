import React, { useEffect, useState } from "react";
import "./PaymentRemaining.scss";
import { useDispatch, useSelector } from "react-redux";
import clock from "../../Assets/img/clock.png";
import paytm from "../../Assets/img/paytm.png";
import stripe from "../../Assets/img/stripe.png";
import cashon from "../../Assets/img/cashon.png";
import payU from "../../Assets/img/payU.png";
import voucher from "../../Assets/img/voucher.png";
import {
  showAddressOpt,
  showConfirmBooking,
  showPaymentOpt,
} from "../../Slices/home";
import {
  createBooking,
  handleResetBookingDetails,
  handlePaymentSummaryDetails,
  editUserProfile,
  getUserProfile,
  addCouponApply,
  handleCouponApply,
  detailsBooking,
  payRemainPayment,
} from "../../Slices/auth";
import { toast } from "react-toastify";

import $ from "jquery";
import { useNavigate, useParams } from "react-router-dom";
const PaymentRemaining = () => {
  const dispatch = useDispatch();
  const [paymentDetails, setpaymentDetails] = useState({});
  const [count, setCount] = useState(0);
  const [coupon, setCoupon] = useState('');
  const [couponDetails, SetcouponDetails] = useState({});
  const [details, setDetails] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const [tipValue, setTipValue] = useState({
    percentage: 0,
    value: 0,
    type: 0
  })
  const { serviceDetails } = useSelector((state) => state.home);
  const { bookingDetails } = useSelector((state) => state.auth);
  const { id } = useParams()

  // console.log(serviceDetails, bookingDetails)
  // useEffect(() => {
  //   PaymentSummaryDetails();
  // }, []);

  // const PaymentSummaryDetails = () => { 
  //   const formData = new FormData();
  //   formData.append("country_id", 103);
  //   formData.append("total_price", serviceDetails.offer_price != ""
  //     ? serviceDetails.offer_price
  //     : serviceDetails.price
  //   );
  //   if (couponDetails && couponDetails.coupon_code) {
  //     const coupon_value = {
  //       total_price: serviceDetails.price,
  //       discount_amount: couponDetails.discount_amount || "",
  //       final_amount: couponDetails.final_amount,
  //       coupon_code: couponDetails.coupon_code,
  //       coupon_name: couponDetails.coupon_name,
  //       // "coupon_id": couponDetails.id
  //     };
  //     formData.append("coupon_array", JSON.stringify(coupon_value));
  //   }
  //   dispatch(handlePaymentSummaryDetails(formData))
  //     .then((response) => {
  //       console.log(response)
  //       setpaymentDetails({ ...response.payload.result.data });
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       toast.error(err);
  //       // setLoading(false);
  //     });
  // };

  // const couponApply = () => {
  //   const formData = new FormData();
  //   formData.append("country_id", 103);
  //   formData.append("service_id", serviceDetails.id);
  //   formData.append("coupon_code", coupon);
  //   dispatch(handleCouponApply(formData))
  //     .then((response) => {
  //       SetcouponDetails({ ...response.payload.result.data });

  //       setTimeout(() => {
  //         PaymentSummaryDetails();
  //       }, 100);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       toast.error(err);
  //       // setLoading(false);
  //     });
  // };

  // const handleBookService = () => {
  //   const formData = new FormData();
  //   const data = { ...serviceDetails, ...bookingDetails };

  //   const PaymentDetails = { payment_summary: JSON.stringify(paymentDetails) };

  //   var dataDetails = {
  //     ...data,
  //     ...PaymentDetails,
  //     car_id: bookingDetails.car_id,
  //   };

  //   dispatch(createBooking(dataDetails))
  //     .then((response) => {
  //       if (response.meta.rejectedWithValue) {
  //         toast.error(response.payload.message);
  //       } else {
  //         toast.success(response.payload.message);
  //         dispatch(showConfirmBooking(true));
  //       }
  //     })
  //     .catch((err) => console.info("Booking Err", err));
  // };

  // const handleClose = () => {
  //   dispatch(showPaymentOpt(false));
  //   dispatch(handleResetBookingDetails());
  // };

  // const is_cuponeadded = () => {
  //   return (
  //     <>
  //       {paymentDetails.is_coupon_applied == true ? (
  //         <li key="dsadsad">
  //           <span key="dsadsad">{"80% amount after service"}</span>
  //           <b key="item_total2">- ₹ {paymentDetails.total ?? ""}</b>
  //         </li>
  //       ) : (
  //         ""
  //       )}
  //     </>
  //   );
  // };
  const getBookigDetails = () => {
    dispatch(detailsBooking(id))
      .then((response) => {
        console.log(response)
        setIsLoading(false)
        setDetails(response.payload.result.data)
      })
      .catch(() => {
        setIsLoading(false)
        toast.error('Failed To Fetch Data')
      })
  }
  const handleTip = (tipvalue, type) => {
    if (type) {
      setTipValue((old) => {
        return {
          ...old,
          value: tipvalue,
          percentage: 0,
          type: 1
        }
      })
    } else {
      setTipValue((old) => {
        return {
          ...old,
          percentage: tipvalue,
          type: 0
        }
      })
    }
  }

  const handleRemainingPayment = () => {
    let body = {
      code: id,
      is_tip_applied: (details?.payment_summary_data?.final_amount || 0) * tipValue.percentage / 100 || tipValue.value || 0 ? true : false,
      tip_data: JSON.stringify({ "tip_amount": (details?.payment_summary_data?.final_amount || 0) * tipValue.percentage / 100 || tipValue.value || 0, "type": !tipValue?.type ?"percentage":'', "percentage": tipValue?.percentage, "total": details?.payment_summary_data?.item_total || 0, "final_amount": details?.payment_summary_data?.total || 0 })
    }
    dispatch(payRemainPayment(body)).then((response)=>console.log(response))
  }
  useEffect(() => {
    getBookigDetails()
  }, [])
  return (
    <div className="payment_wrapper">
      <div className="container">
        <div className="listMenu">
          <span className="book_section_back_icon" onClick={() => navigate(-1)}>
            <i class="fa fa-angle-left"  ></i>
          </span>
          <a href="" className="active">
            Payment
          </a>
        </div>
        <div className="paymeny_section">
          <div className="payment_left">
            <div className="contetTextArea">


              <div className="payment_body_wash">
                <h2>{serviceDetails?.service_name}</h2>
                <span>{serviceDetails?.description} <a href="#0">Read More</a> </span>
                <p> <span></span> 35 Minutes</p>
              </div>

              <div className="listBox">
                <p>Payment</p>
                <div className="leftBrandLocation">
                  <h4>Payment Options</h4>
                  <ul className="listRadio locationradio payMentOpt">
                    <li>
                      <input type="radio" name="payment" />
                      <label className="strip">
                        <img src={stripe} />
                        <span>Stripe</span>
                      </label>
                    </li>
                    <li>
                      <input type="radio" name="payment" />
                      <label className="paytm">
                        <img src={paytm} />
                        <span>Paytm</span>
                      </label>
                    </li>
                    <li>
                      <input type="radio" name="payment" />
                      <label className="payu">
                        <img src={payU} />
                        <span>PayU Money</span>
                      </label>
                    </li>
                    <li>
                      <input type="radio" name="payment" />
                      <label className="cod">
                        <img src={cashon} />
                        <span>C.O.D</span>
                      </label>
                    </li>
                  </ul>
                  <div className="listBox">
                    <ul className="voucherOut">
                      <li><p>Add TIP</p></li>
                      <li>
                        <div className="payment_pr">
                          <p onClick={() => handleTip(10, 0)} className={`${(tipValue.type == 0 && tipValue.percentage == 10 && 'active')}`}>10%</p>
                          <p onClick={() => handleTip(15, 0)} className={`${(tipValue.type == 0 && tipValue.percentage == 15 && 'active')}`}>15%</p>
                          <p onClick={() => handleTip(20, 0)} className={`${(tipValue.type == 0 && tipValue.percentage == 20 && 'active')}`}>20%</p>
                        </div>
                      </li>
                      <li className="input">
                        <input
                          type="text"
                          name="last_name"
                          placeholder="Enter Amount Here…"
                          defaultValue={coupon}
                          onChange={(e, value, d) => {
                            setCoupon(e.target.value);
                          }}
                        />
                      </li>

                      <li className="btnClass">
                        <button
                          onClick={(e) => {
                            handleTip(coupon, 1);
                          }}
                        >
                          Add
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="payment_right">
            <div className="summarRight">
              <h3>Payment Summary</h3>
              <ul>
                <li>
                  <span>Item total</span>
                  <b key="item_total">
                    ₹ {details?.payment_summary_data?.item_total || 0}
                  </b>
                </li>
                <li>
                  <span>Tax</span>
                  <b key="item_total">
                    ₹  {details?.payment_summary_data?.tax_amount || 0}
                  </b>
                </li>
                <li className="green_li">
                  <span>Coupon Discount </span>
                  <b key="item_total">
                    ₹ {details?.payment_summary_data?.coupon_discount || 0}
                  </b>
                </li>
                {paymentDetails.is_tax_applied == true ? (
                  <li>
                    <span>Tax</span>
                    <b key="item_total1">₹ {paymentDetails.tax_amount || ""}</b>
                  </li>
                ) : (
                  ""
                )}

                {/* {paymentDetails.is_coupon_applied == true ? (
                  <li key={paymentDetails.total}>
                    <span key={paymentDetails.total}>
                      {"Coupon Discount (" + paymentDetails.coupon_data.coupon_code + ")"}
                    </span>
                    <b key={paymentDetails.total}>
                      - ₹ {paymentDetails.total ?? ""}
                    </b>
                  </li>
                ) : (
                  ""
                )} */}

                <li className="totalPrice" >
                  <span>Total</span>
                  <b>₹{details?.payment_summary_data?.final_amount || 0}</b>
                </li>
                <li>
                  <span>{(details?.payment_summary_data?.first_installment || 0) / (details?.payment_summary_data?.final_amount || 1) * 100}% amount paid</span>
                  <b key="item_total">
                    ₹ {details?.payment_summary_data?.first_installment || 0}
                  </b>
                </li>
                <li>
                  <span>TIP {tipValue.type == 0 ? '(' + tipValue.percentage + '% of Total)' : ''}</span>
                  <b key="item_total">
                    ₹ {(details?.payment_summary_data?.final_amount || 0) * tipValue.percentage / 100 || tipValue.value || 0}
                  </b>
                </li>
                {/* <li className="totalPrice">
                  </li> */}
                {/* <li>
                    <span>{"80% amount after service"}</span>
                    <b>- ₹ {paymentDetails.second_installment ?? ""} </b>
                  </li> */}

                <li className="totalPrice">
                  <span>Remaining Amount to Pay</span>
                  <b>₹ {(Number(details?.payment_summary_data?.second_installment || 0)) + (Number((details?.payment_summary_data?.final_amount || 0) * tipValue.percentage / 100 || tipValue.value))} </b>
                </li>
                <li>
                  <span className="small_green_span">(You save ₹ {details?.payment_summary_data?.coupon_discount || 0} on this booking.)</span>
                </li>
              </ul>
            </div>
            <div className="detOpFoot">
              <div className="col priceNum">
                <div className="totalPrice">
                  Total :
                  <span className="price" key="item_total5">
                    {/* {serviceDetails.currency.symbol + " " + paymentDetails.total} */}
                  </span>
                </div>
              </div>
              <div className="col rightDet">
                <button
                  style={{ cursor: "pointer" }}
                  // onClick={() => dispatch(showConfirmBooking(true))}
                  // onClick={() => handleBookService()}
                  onClick={handleRemainingPayment}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentRemaining;
