import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Checkbox } from "@mui/material";
export default function Registration() {
  const [startDate, setStartDate] = useState(new Date());
  const [isChecked, setIsChecked] = useState(false);
  return (
    <>
      <div className="signup-main-box">
        <div className="container">
          <div className="signup-header">
            <h3 className="signup-title"> SIGNUP</h3>
          </div>
        </div>

        <div className="container signup-box">
          <div className="row justify-content-between">
            <div className="col-12 col-xl-5 signup-welcome-section">
              <h2 className="signup-welcome">Welcome!</h2>
              <p className="signup-opportunity">
                Don’t miss your next opportunity. Sign In to stay updated on
                your proffesional world.
              </p>
            </div>
            <div className="col-12 col-xl-7 signup-field-box">
              <div className="row main-field-box">
                <div className="col-12 col-sm-6">
                  <div className="field-box">
                    <label className="form-label" id="first_name">First Name</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="text-field"
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="field-box">
                    <label className="form-label" id="last_name">Last Name</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="text-field"
                    />
                  </div>
                </div>
              </div>

              <div className="row main-field-box">
                <div className="col-12">
                  <div className="field-box">
                    <label className="form-label">Email (Optional)</label>
                    <input
                      type="email"
                      placeholder="Email"
                      className="text-field"
                    />
                  </div>
                </div>
              </div>

              <div className="row main-field-box">
                <div className="col-12">
                  <div className="field-box">
                    <label className="form-label">Phone Number</label>
                    <div className="phone-main-box">
                      <div className="dialcode-box">
                        <input
                          type="Text"
                          placeholder="+91"
                          className="text-field color-grey"
                        />
                      </div>
                      <div className="phone-box">
                        <input
                          type="Text"
                          placeholder="Phone"
                          className="text-field color-grey"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row main-field-box">
                <div className="col-12 col-sm-6">
                  <div className="field-box">
                    <label className="form-label">Gender (Optional)</label>
                    <select id="gender" name="gender" className="text-field">
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="field-box ">
                    <label className="form-label">Birthday (Optional)</label>
                      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="text-field"/>
                  </div>
                </div>
              </div>

              <div className="team-main-box">
                <div className="row ">
                  <div className="col-12 team-box">
                    <label>
                      <input type="checkbox" className="d-none" onChange={() => { setIsChecked(!isChecked);}}/>
                      <span className={`checkbox ${isChecked ? "checkbox--active" : ""}`} aria-hidden="true"/>
                    </label>
                    <div>
                      <p className="mb-0 subcribe">
                        Subscribe for Newsletter & Promotional Offers
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div>
                      <p className="privacy">
                          by proceeding, you agree to brand’s 
                        <Link to={"#"} className="privacy-link">
                           conditions of uses 
                        </Link>
                         and 
                        <Link to={"#"} className="privacy-link">
                           privacy policy 
                        </Link> 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-3 mb-2'>
                <div className='line-h'></div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button className="validate-button">Validate</button>
                </div>
              </div>

              <div className="skip-login">
                  <Link to={"#"} className='skip-login-text'>Skip login & go to dashboard</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container signup-box">
          <div className="row">
            <div className="col-12 col-xl-6 info-section">
              <h2 className="signup-welcome">Welcome Back!</h2>
              <p className="signup-opportunity">
                Don’t miss your next opportunity. Sign In to stay updated on
                your proffesional world.
              </p>
            </div>

            <div className="col-12 col-xl-6">
              <div className="row">
                <div className="col-12 col-xl-6">
                  <h3 className="login-field-label">First Name</h3>
                  <input
                    type="text"
                    id="fname"
                    name="first_name"
                    className="text-field"
                    placeholder="Enter your first name"
                  />
                </div>
                <div className="col-12 col-xl-6">
                  <h3 className="login-field-label">Last Name</h3>
                  <input
                    type="text"
                    id="lname"
                    name="last_name"
                    className="text-field"
                    placeholder="Enter your last name"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 ">
                  <h3 className="login-field-label">{`Email (Optional)`}</h3>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="text-field"
                    placeholder="Enter your email"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h3 className="login-field-label">{`Phone Number`}</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-2 pr-0">
                  <input
                    type="number"
                    id="dialcode"
                    name="dialcode"
                    className="text-field"
                    placeholder="+91"
                  />
                </div>
                <div className="col-10">
                  <input
                    type="number"
                    id="number"
                    name="Phone_number"
                    className="text-field"
                    placeholder="Enter your phone"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 pr-0">
                  <h3 className="login-field-label">{`Gender (Optional)`}</h3>
                  <select id="gender" name="gender" className="dropdown-field">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col-6">
                  <h3 className="login-field-label">{`Birthday (Optional)`}</h3>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="text-field"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="d-flex team">

                    <div className="team-checkbox"> </div>
                     <input class="" type="checkbox" value="" id="" /> 
                    <span className="subscribe-text">Subscribe for Newsletter & Promotional Offers</span>
                  </div>
                  <p className="by-procee"> 
                    by proceeding, you agree to brand’s
                    <Link to={"/"}><span className="team-text"> conditions of uses </span> </Link>and
                    <Link to={"/"}><span className="team-text"> privacy policy </span></Link>
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button className="validate-button">Validate</button>
                </div>
              </div>
              <div className='skip-login'>
                      <Link to={"#"} className='skip-login-text'>Skip login & go to dashboard</Link>
              </div>
            </div>
          </div>
        </div>  */}
    </>
  );
}
