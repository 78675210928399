import React from 'react';
import './Banners.scss';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Banner1 from "../../Assets/img/slider.png";

const Banners = ({ topSlider }) => {
  var settings = {
    dots: true,
    className: "slideIn",
    // infinite: true,
    // slidesToShow: 1,
    // autoplay: false,
    // speed: 1500,
    // autoplaySpeed: 3500,
    // cssEase: "ease-out",
    // pauseOnHover: true,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: true
    //     }
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide:1
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     }
    //   }
    // ]
  };
  return (
    <section>
    <Slider {...settings}>
      {/* {topSlider && topSlider.map((list) => {
        return <Link to={"/"} className='slideIn'>
        <img src={list.slider_image_web} alt="banner" /> 

      <div className='offerBanner'>
        <p>flat 50% discount on trending FASHION WEAR</p>
    </div>
      </Link>
      })} */}

      {/* <div className='slideIn'>
          <div className='slider-text'>
            <h6 className='slider-text-1'>MODERN EQUIPEMENT</h6>
            <h3 className='slider-text-2'>Quality Service For You</h3>
            <h3 className='slider-tetx-3'>Flat 40% OFF on all washing services</h3>
            <div>
              <Link to="/" className='explore-btn'>
              <span className='explore-btn-text'>Explore Now</span>
              </Link>
            </div>
           
          </div>
        <img src={Banner1}/> 
      </div> */}

      {
        [...Array(6)].map(() => (
          <>
            <div className='slideIn'>
                  <div className='slider-text'>
                    <h6 className='slider-text-1'>MODERN EQUIPEMENT</h6>
                    <h3 className='slider-text-2'>Quality Service For You</h3>
                    <h3 className='slider-tetx-3'>Flat 40% OFF on all washing services</h3>
                    <div>
                      <Link to="/" className='explore-btn'>
                      <span className='explore-btn-text'>Explore Now</span>
                      </Link>
                    </div>
                  </div>
                <img src={Banner1} className="img"/> 
              </div>
          </>
        ))
      }
  </Slider>
  </section>
  );
}
export default Banners;