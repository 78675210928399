import React from 'react';
import './TopPackages.scss';
import { Link } from 'react-router-dom';
import ProductBox from '../ProductBox/ProductBox'
import ProductBoxPackages from '../ProductBox/ProductBoxPackages'
import "react-slick/lib/slider";
import { List } from 'react-content-loader';
import Slider from "react-slick";
import { useTranslation, withTranslation, Trans } from 'react-i18next';

const TopPackages = ({ dashboardSections, isDashboardSectionsFetched, sections }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:4,
    slidesToScroll: 1,
    className: "pwaSlide",
    
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
       {
         breakpoint: 767,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1
         }
       },

     ]
  };

  const { t, i18n } = useTranslation();
  
  return (
    <>
    {sections && 
      <section>
          { dashboardSections && dashboardSections.length == 0 ? <strong>{t('common.no_data_Found')}</strong> : 
          <>
          { 
                <div className='producrCateIn spaceSect container'>
                    <div className="section-title-box"> 
                        <h2 className='sectionTitle centerText'>Top Packages</h2>
                        <span className='sectionSeeAll centerText'>
                          <Link className="see-all-text" to={`/serviceListingss/${1}`}>{t('common.see_all')}</Link>
                        </span>
                    </div>

                  {/* <div className='borderBottomDesign titleHead topborder'> 
                    <h2 className='sectionTitle centerText'>
                        {t('common.see_all')}
                    </h2>
                    <p className='sectionSeeAll centerText'>
                      <Link to={`/serviceListingss/1`}>{t('common.see_all')}</Link>
                    </p> 
                  {/* </div> */}
                  <div className='container-fluid proItems'>
                    <div className='mySlider'>
                    <Slider {...settings} > 
                        {dashboardSections?.map((packages, key) => (
                        <div className='col-md-rev proBox'>
                            <ProductBoxPackages
                              packages={packages}
                              key={key}
                            />
                        </div>
                        ))}
                        </Slider>
                    </div>
                  </div>
                </div>   
          }
          </>
      }
      </section>
    }
    
      {/* <div className='service-box'>
      <div className='container'>
        <hr className="hr"/>
          <div className="section-title-box"> 
              <h2 className='sectionTitle centerText'>Top<span>Packages</span>
              </h2>
              <span className='sectionSeeAll centerText'>
                <Link className="see-all-text" to={"/allSpecialities"}>SEE ALL</Link>
              </span>
          </div>
          <hr className="hr"/>
      </div>
      <div className='container'>
      <div className='box'>
          <div className='box-item-list row'>
              {data.map((item,index) => (
                  <>  
                    <div className='box-item col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2'>
                              <img src={item.img}  alt="" />
                              <div className='title-box'>
                                <h3 className='title'>{item.title}</h3>
                                <p className='card-info'>{item.info}</p>
                              </div>

                              <div className='price-box'>
                                  <span className='price'>₹ {item.price}</span> 
                              </div>
                              <div className='book-box'>
                                  <div className='time-box'>
                                    <img src={item.icon} alt="" />
                                    <span className='time'>30 Minutes</span></div>
                                  <div>
                                    <Link to={"/"} className="book-button">
                                      BOOK
                                    </Link>
                                  </div>
                              </div>

                    </div>
                  </>
                ))}
                
          </div>
      </div>
      </div>
      </div> */}
    </>
  );
}

export default TopPackages
