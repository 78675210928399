import React, { Suspense, useState } from "react";
import './App.css';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BASE_URL from "./Config/Config.json"
import axios from "axios"
import './Assets/scss/Common.scss'
import Home from "./Components/Home";
import AllSpecialities from "./Components/AllSpecialities/AllSpecialities"
import ServiceListing from "./Components/ServiceListing/ServiceListing"
import ServiceSummary from "./Components/ServiceSummary/ServiceSummary"
import MyProfile from "./Components/MyProfile/MyProfile"
import Header from "./Components/Common/Header/Header";
import Footer from "./Components/Common/Footer/Footer";
import Login from "./Components/Login/Login";
import { useSelector } from 'react-redux';
import RegisterForm from "./Components/RegisterForm/RegisterForm";
import Registration from "../src/Components/RegisterForm/Registration";
import OtpForm from "./Components/OtpForm/OtpForm";
import 'react-toastify/dist/ReactToastify.css'
import FooterMobileMenu from './Components/Common/FooterMobileMenu/FooterMobileMenu';
import AboutUs from "./Components/CmsPages/AboutUs";
import { ToastContainer } from 'react-toastify';
import SubSpecialities from "./Components/AllSpecialities/SubSpecialities";
import SubServiceListing from "./Components/ServiceListing/SubServiceListing";
import MyAddress from './Components/MyProfile/components/MyAddress'
import MyBookings from './Components/MyBookings/MyBookings';
import HelpPage from './Components/Help&Support/HelpPage';
import { useTranslation } from 'react-i18next';
import ServiceDetail from "./Components/ServiceDetail/ServiceDetail"
import ChooseProvider from "./Components/ChooseProvider/ChooseProvider"
import PackageDetailSchedule from './Components/PackageDetailSchedule/PackageDetailSchedule'
import AddressOptions from './Components/AddressOptions/AddressOptions'
// import { useTheme } from '@material-ui/core/styles';
// import AboutUs from "./Components/CMSPages/AboutUs";
// import Privacy from './Components/CMSPages/Privacy';
// import Faq from './Components/CMSPages/Faq';
// import ContactUs from "./Components/CMSPages/ContactUs";
import ServiceDetailChooseCar from './Components/ServiceDetailChooseCar/ServiceDetailChooseCar'
import MyBookingRunning from "./Components/MyBookingUp/MyBookingRunning";
import MyBookRequest from "./Components/MyBookingUp/MyBookRequest";
import PaymentOption from "./Components/PaymentOption/PaymentOption";
import PackageConFirmBooking from "./Components/PackageConFirmBooking/PackageConFirmBooking";
import PackageDetail from "./Components/PackageDetail/PackageDetail";

import ConFirmBooking from "./Components/ConFirmBooking/ConFirmBooking";
import PaymentRemaining from "./Components/PaymentRemaining/PaymentRemaining";
import PackageBoked from "./Components/PackageBooked/PackageBooked";

axios.defaults.baseURL = BASE_URL.SERVICE_MARKETPLACE
axios.defaults.headers.post['Content-Type'] = 'application/json';

function App() {
  const { showLogin, showRegister, showOTP } = useSelector((state) => state.auth)
  const [user, setUser] = useState({device_token: "FSRSYSYYSY", device_type: "ios", mobile_no: "", isEmailValid: false, first_name: "", last_name: "", email: "", country_code: ""})
  const initialUserValue = {device_token: "FSRSYSYYSY", device_type: "ios", mobile_no: "", country_code: ""}
  const [isEmailValid, setIsEmailValid] = useState(false)

  const { t, i18n } = useTranslation();
  // const theme = useTheme();
  document.body.dir = i18n.dir();

  const changeLanguage = (lng) => { 
    i18n.changeLanguage(lng)
    document.body.dir = i18n.dir();
    // theme.direction = i18n.dir();
  }

  const handleInputChange = (e) => {
    const { value, name } = e.target
    const emailRegex = /\S+@\S+\.\S+/;
    let data = user;
    switch (name) {
        // case 'name':
        //     data.name = value.replace(/[0-9]/g, '');
        //     break;
        case 'first_name':
            data.first_name = value.replace(/[0-9]/g, '');
            break;
        case 'last_name':
            data.last_name = value.replace(/[0-9]/g, '');
            break;
        case 'email':
          if(emailRegex.test(value)){
            setIsEmailValid(true)
          } else {
            setIsEmailValid(false)
          }
            data.email = value;
            break;
        case 'mobile_no':
            data.mobile_no = value.replace(/\D/g, '');
            break;
        case 'refer_code':
            data.refer_code = value;
            break;
        default:
            data[name] = value
            break;
    }
    setUser(() => {return { ...user, ...data}});
};
  return (
    <Router>

      {/* <div style={{display: "table" }}>
        <div style={{ display: "table-row" }}>
          <div style={{ display: "table-cell",padding: 5}}>
          <button onClick={() => changeLanguage('en')}>English</button>
          </div>
          <div style={{ display: "table-cell",padding: 5}}>
          <button onClick={() => changeLanguage('ar')}>Arabic</button>
            </div>
        </div>
      </div> */}

      <ToastContainer />
      <Header />
      <Suspense fallback='Loading...'>
        {/* {showLogin ? <Login user={user} handleInputChange={handleInputChange} setUser={setUser} initialUserValue={initialUserValue} /> : showOTP ? <OtpForm user={user} handleInputChange={handleInputChange} initialUserValue={initialUserValue} setUser={setUser} /> : "" }
        {showRegister && <RegisterForm user={user} handleInputChange={handleInputChange} setUser={setUser} initialUserValue={initialUserValue} isEmailValid={isEmailValid} />} */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='allSpecialities' element={<AllSpecialities />} />
          <Route path='subSpecialities/:id' element={<SubSpecialities />} />
          <Route path='serviceListing/:id' element={<ServiceListing />} />
          <Route path='subServices/:id' element={<SubServiceListing />} />
          <Route path='serviceSummary' element={<ServiceSummary />} />
          <Route path='helpPage' element={<HelpPage />} />
          <Route path='myProfile' element={<MyProfile />} />
          <Route path='myAddress' element={<MyAddress />} />
          <Route path='cms' element={<AboutUs />} />


          <Route path='bokingDetails/:id' element={<MyBookRequest />} />
          <Route path='payment/:id' element={<PaymentRemaining />} />

          <Route path='a/:id' element={<PackageDetail />} />
          <Route path='package_booked' element={<PackageBoked />} />
        
          


          

          

          <Route path='login' element={<Login user={user} handleInputChange={handleInputChange} setUser={setUser} initialUserValue={initialUserValue}  />} />
          <Route path='registration' element={<Registration />} />
          {/* <Route path='cms/about-us' element={<AboutUs />} />
          <Route path='cms/terms-of-use' element={<ContactUs />} />
          <Route path='cms/privacy-policy' element={<Privacy />} />
          <Route path='cms/policies' element={<Faq />} /> */}
          <Route path='chooseCar' element={<ServiceDetailChooseCar />} />
          <Route path='running' element={<MyBookingRunning />} />
          <Route path='confirm' element={<PackageConFirmBooking />} />
          <Route path='ServiceDetail' element={<ServiceDetail />} />
          <Route path='ChooseProvider' element={<ChooseProvider />} />
          <Route path='packageDetailSchedule' element={<PackageDetailSchedule/>} />
          <Route path='address-options' element={<AddressOptions/>} />
          <Route path='paymentOption' element={<PaymentOption/>} />
          <Route path='booking-confirmed' element={<ConFirmBooking/>} />
          
        </Routes>

      </Suspense>
      <FooterMobileMenu />
      <Footer />
    </Router>
  );
}

export default App;

// import { useTranslation, withTranslation, Trans } from 'react-i18next';
// const { t, i18n } = useTranslation();