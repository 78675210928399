import React, { useEffect, useState }  from 'react'
import './Footer.scss';
import fb from "../../../Assets/img/icons8-facebook.png";
import insta from "../../../Assets/img/icons8-instagram.png";
import tweet from "../../../Assets/img/icons8-twitter.png";
import youtube from "../../../Assets/img/icons8-youtube.png";
import logo from "../../../Assets/img/logo.png";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { getCMSPageList } from '../../../Slices/home';

const Footer = () => {
  const dispatch = useDispatch()
  const [cmsPages, setCMSPages] = useState()

  useEffect(()=> {
    
    $(document).ready(function(){
      
      $('h3').click(function(e) {
        e.preventDefault();
      
        let $this = $(this);
      
        if ($this.next().hasClass('show')) {
            $this.next().removeClass('show');
            $this.next().slideUp(350);
        } else {
            $this.parent().parent().find('.list').removeClass('show');
            $this.parent().parent().find('.list').slideUp(350);
            $this.next().toggleClass('show');
            $this.next().slideToggle(350);
        }
    });
  });
 },[])

 useEffect(() => {
  dispatch(getCMSPageList())
  .then((response) => setCMSPages(response.payload.data.result.data))
  .catch((err) => setCMSPages())
  // eslint-disable-next-line
 },[])
  return (
    <footer >
    <div className='container'>
      <div className='row'>
        <div className='col'>
            <a href="" className="footLog"><img src={logo} alt="logo"/></a>
            <p>Lorem  Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
        </div>
        <div className='col-md-2'>
            <h3 id="fmenu1">KNOW MORE <span className='rightDown'><i className='fa fa-angle-down'></i></span></h3>
            <ul className='list fmenu1'>
              {cmsPages?.map((list, key) => {
                return <li key={key}><Link to={`cms?slug=${list.slug}`}>{list.title}</Link></li>
              })}
              {/* <li><a href="">About Us</a></li>
              <li><a href="">Terms of Use</a></li>
              <li><a href="">Privacy Policy</a></li>
              <li><a href="">Return Policy</a></li>
              <li><a href="">FAQ</a></li> */}
            </ul>
        </div>
        <div className='col-md-2'>
        <h3 id="fmenu2">TRENDING SERVICES <span className='rightDown'><i className='fa fa-angle-down'></i></span></h3>
            <ul className='list fmenu2'>
              <li><a href="">Delux Body Wash</a></li>
              <li><a href="">Full Car Deep Cleaning & Polish</a></li>
              <li><a href="">3M™ Car Rubbing & Polishing</a></li>
              <li><a href="">Exterior Polish + Basic Interior</a></li>
              <li><a href="">Car Interior Wash</a></li>
            </ul>
        </div>
        <div className='col-md-3'>
        <h3 id="fmenu3">HAPPY TO SERVE YOU <span className='rightDown'><i className='fa fa-angle-down'></i></span></h3>
            <ul className='list fmenu3'>
              <li><a href="">Contact Us</a></li>
              <li><a href="">Your Account</a></li>
              <li><a href="">Your Bookings</a></li>
              <li><a href="">Help</a></li>
            </ul>
        </div>
      </div>
    </div>
    <div className='container'>
      <div className='row'>
        <ul className='socialIcon'>
          <li><a href=""><img src={fb} alt="fb" /></a></li>
          <li><a href=""><img src={tweet} alt="twetter" /></a></li>
          <li><a href=""><img src={youtube} alt="youtube" /></a></li>
          <li><a href=""><img src={insta} alt="insta" /></a></li>
        </ul>
      </div>
    </div>
    <div className='container copyRight'>
        <p>@2022 , All Copyrights reserved by GreenShine</p>
    </div>
  </footer>
  )
}

export default Footer
