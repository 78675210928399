import { Alert } from "@mui/material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import homeServices from "../Services/home.service";

export const getBrands = createAsyncThunk(
    "home/getBrands",
    async (thunkAPI) => {
      try {
        const response = await homeServices.getCarsBrand();
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getFuel = createAsyncThunk(
    "home/getFuel",
    async (thunkAPI) => {
      try {
        const response = await homeServices.getCarsFuel();
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getAllCategories = createAsyncThunk(
    "home/getAllCategories",
    async (data, thunkAPI) => {
      try {
        const response = await homeServices.getCategories(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getCMSPageList = createAsyncThunk(
    "home/getCMSPageList",
    async (thunkAPI) => {
      try {
        const response = await homeServices.getCMSPages();
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getDashboard = createAsyncThunk(
    "home/getDashboard",
    async (data, thunkAPI) => {
      try {
        const response = await homeServices.getDashboardSection(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getDashboardById = createAsyncThunk(
    "home/getDashboardById",
    async (data, thunkAPI) => {
      try {
        const response = await homeServices.getDashboardSectionById(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getServiceDetails = createAsyncThunk(
    "home/getServiceDetails",
    async (data, thunkAPI) => {
      try { 
        const response = await homeServices.getServiceDetails(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getCMSPageContent = createAsyncThunk(
    "home/getCMSPageContent",
    async (data, thunkAPI) => {
      try {
        const response = await homeServices.getCMSContent(data);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
);

export const getAllOffers = createAsyncThunk(
    "home/getAllOffers",
    async (data, thunkAPI) => {
        try {
            const response = await homeServices.getAllOffers(data.country_id);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getAllCoupons = createAsyncThunk(
    "home/getAllCoupons",
    async (data, thunkAPI) => {
        try {
            const response = await homeServices.getAllCoupons(data.country_id);
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


const initialState = {
    showServiceDetails: false,
    showScheduleOpt: false,
    isSchedule: false,
    showAddressOpt: false,
    ServiceChooseCar:false,
    showPaymentOpt: false,
    showConfirmBooking: false,
    carBrand: [],
    carFuel: [],
    categories: [],
    offers: [],
    coupons: [],
    isCategoriesFetched: false,
    dashboardSections: [],
    isDashboardSectionsFetched: false,
    isgetAllOffersFetched: false,
    isgetAllCouponsFetched: false,
    serviceDetails: {}
}

const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        showServiceDetails: (state, action) => {
            state.showServiceDetails = action.payload
            state.showScheduleOpt = false
            state.showAddressOpt = false
            state.showPaymentOpt = false
            state.ServiceChooseCar = false
            
        },
        showScheduleOpt: (state, action) => {
            state.showServiceDetails = false
            state.showScheduleOpt = action.payload
            state.showAddressOpt = false
            state.showPaymentOpt = false
            state.ServiceChooseCar = false
            state.isSchedule = true
        },
        serviceChooseCar: (state, action) => {
          state.showServiceDetails = false
          state.showScheduleOpt = false
          state.showAddressOpt = false
          state.showPaymentOpt = false
          state.ServiceChooseCar = action.payload
          state.isSchedule = false
      },
        showAddressOpt: (state, action) => {
          switch(action.payload.type){
            case("isSchedule"):
              state.showServiceDetails = false
              state.showScheduleOpt = false
              state.showAddressOpt = action.payload
              state.showPaymentOpt = false
              state.ServiceChooseCar = false
              state.isSchedule = true
              break;
            default:
              state.showServiceDetails = false
              state.showScheduleOpt = false
              state.showAddressOpt = action.payload
              state.showPaymentOpt = false
              state.ServiceChooseCar = false
              state.isSchedule = false
          }
        },
        showPaymentOpt: (state, action) => {
            state.showServiceDetails = false
            state.showScheduleOpt = false
            state.showAddressOpt = false
            state.ServiceChooseCar = false
            state.showPaymentOpt = action.payload
        },
        showConfirmBooking: (state, action) => {
            state.showServiceDetails = false
            state.showScheduleOpt = false
            state.showAddressOpt = false
            state.showPaymentOpt = false
            state.ServiceChooseCar = false
            state.showConfirmBooking = action.payload
        },
        showAllOffers: (state, action) => {
            state.showServiceDetails = false
            state.showScheduleOpt = false
            state.showAddressOpt = false
            state.showPaymentOpt = false
            state.ServiceChooseCar = false
            state.showConfirmBooking = action.payload
        },
    },
    extraReducers: {
        [getBrands.fulfilled]: (state, action) => {
            state.carBrand = action.payload.data.result.data
        },
        [getBrands.rejected]: (state) => {
            state.carBrand = []
        },
        [getFuel.fulfilled]: (state, action) => {
            state.carFuel = action.payload.data.result.data
        },
        [getFuel.rejected]: (state) => {
            state.carFuel = []
        },
        [getAllCategories.pending]: (state, action) => {
            state.categories = []
            state.isCategoriesFetched = false
        },
        [getAllCategories.fulfilled]: (state, action) => {
            state.categories = action.payload.data.result.data
            state.isCategoriesFetched = true
        },
        [getAllCategories.rejected]: (state, action) => {
            state.categories = []
            state.isCategoriesFetched = false
        },
        [getDashboard.pending]: (state) => {
          state.isDashboardSectionsFetched = false
          state.dashboardSections = []
        },
        [getDashboard.fulfilled]: (state, action) => {
          state.isDashboardSectionsFetched = true
          state.dashboardSections = action.payload.data.result.data
        },
        [getDashboard.rejected]: (state) => {
          state.isDashboardSectionsFetched = false
          state.dashboardSections = []
        },
        [getServiceDetails.fulfilled]: (state, action) => {        
          state.serviceDetails = action.payload.data.result.data
          state.showServiceDetails = true
        },
        [getAllOffers.pending]: (state, action) => {
            state.offers = []
            state.isgetAllOffersFetched = false
        },
        [getAllOffers.fulfilled]: (state, action) => {
            state.offers = action.payload.data.result.data
            state.isgetAllOffersFetched = true
        },
        [getAllOffers.rejected]: (state, action) => {
            state.offers = []
            state.isgetAllOffersFetched = false
        },
        [getAllCoupons.pending]: (state, action) => {
            state.coupons = []
            state.isgetAllCouponsFetched = false
        },
        [getAllCoupons.fulfilled]: (state, action) => {
            state.coupons = action.payload.data.result.data
            state.isgetAllCouponsFetched = true
        },
        [getAllCoupons.rejected]: (state, action) => {
            state.coupons = []
            state.isgetAllCouponsFetched = false
        },
    }
})

export const {
  showServiceDetails,
  serviceChooseCar,
  showAddressOpt,
  showPaymentOpt,
  showScheduleOpt,
  showServeDetails,
  showConfirmBooking
} = homeSlice.actions;

const { reducer } = homeSlice

export default reducer