const firebaseConfig = {
    // AIzaSyDdTkGEGa8fA7WddbKJHfIY8Beqe1wpfbo
  apiKey: "AIzaSyDdTkGEGa8fA7WddbKJHfIY8Beqe1wpfbo",
  authDomain: "cwgreenshine.firebaseapp.com",
  projectId: "cwgreenshine",
  storageBucket: "cwgreenshine.appspot.com",
  messagingSenderId: "939113211673",
  appId: "1:939113211673:web:a6dfc8c27615281e218788",
  measurementId: "G-RKVVFYKQ5G",
};

export default firebaseConfig;