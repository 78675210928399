import React, { useEffect, useState } from "react";
import "./ServiceDetailChooseCar.scss";
import clock from "../../Assets/img/clock.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showScheduleOpt, showServiceDetails, showAddressOpt,showPaymentOpt,serviceChooseCar } from "../../Slices/home";
import { toast } from 'react-toastify';
import { handleBookingDetails,handleResetBookingDetails, showLogin } from "../../Slices/auth";

const ServiceDetailChooseCar = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const { serviceDetails } = useSelector((state) => state.home)

  const { user_car_id_default } = useSelector((state) => state.auth)
  const { isLoggedIn, bookingDetails,userCars, user } = useSelector((state) => state.auth) 
  const [selectedCar, setSelectedCar] = useState(user_car_id_default)
  const [car, setCar] = useState("Select car")
  // const [addTofav, setAddToFav] = useState(false)

  const handleNextClick = () => {
     if(selectedCar <= 0){
       toast.error("Select Car to Continue.")
     } else {
       dispatch(showAddressOpt(true)); 
        //dispatch(showAddressOpt(true))
        dispatch(handleBookingDetails({car_id:selectedCar}))
     }
  }

  const handleClose = () => {
    dispatch(serviceChooseCar(false))
    dispatch(handleResetBookingDetails())
  }

  const checkAuth = (page) => {
    if(isLoggedIn){
      switch(page){
        case "schedule":
          if(!bookingDetails.car_id){
            toast.error("Select your car first to book a service.")
          } else {
            dispatch(showScheduleOpt(true))
            dispatch(handleBookingDetails({service_id: serviceDetails.id, duration: serviceDetails.duration}))
          }
          break;
        case "book":
          if(!bookingDetails.car_id){
            toast.error("Select your car first to book a service.")
          } else {
            dispatch(showAddressOpt(true))
            dispatch(handleBookingDetails({service_id: serviceDetails.id, duration: serviceDetails.duration}))
          }
          break;
          default:
            break
      }
    }
    if(!isLoggedIn){
      toast.error("Please Login For Service Booking.")
      dispatch(showServiceDetails(false))
      dispatch(showLogin(true))
    }
  }

  const handleAddCar = (car) =>{
    setCar(car);
  }

  return (
    <>

<section className="container service-details"> 
    <div className="service-details-title">
      <i class="fa fa-angle-left" onClick={() => navigate(-1)}></i>
      <span className="service-details-title-text">Choose Car</span>
    </div>

    <div className="service-details-body">
      <div className="row">
          <div className="col-12 col-lg-5">
              <div className="">
                  <img className="service-details-img" src={require("../../Assets/img/service.png")} alt=""/>
              </div>
              <div className='service-details-information marginTop-19 Schedule'>
                <div className="service-details-info">
                    <h6 className="service-details-name">Delux Body Wash</h6>
                    <p className="Schedule-service-details-description">Perfect for clients who need their interior cleaned but want most of the focus on the exterior of their vehicle. Lorem Ipsum is simply dummy text…</p>
                </div>
                <div className="service-details-time">
                    <img src={clock} alt="" width={15} height={15}/>
                    <span className="time">35 Minutes.</span>
                </div>
              </div>
          </div>

          <div className="col-12 col-lg-7 ">
            <div className="choose-provider">
                <div className='schedule-title-box'>
                    <div>
                        <span className='schedule-box'>Choose your car</span>
                    </div>    
                </div>
                <div className="choose-body">
                      <div className="choose-body-title">
                        <span className="choose-car-here">Choose Car Here</span>
                        <span className="add-new-car">+ Add New Car</span>
                      </div>
                  </div>  
                  <div>
                  <div className="filter-box add-car-filter-box">
                  <div class="dropdown car-dropworn">
                    <button
                      class="btn dropdown-toggle custom-dropdown"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      <span className="discount-text">
                       {car}
                      </span>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <button class="dropdown-item car-item" onClick={()=>{handleAddCar("Car 1")}}> Car 1 </button>
                      <button class="dropdown-item car-item" onClick={()=>{handleAddCar("Car 2")}}> Car 2 </button>
                      <button class="dropdown-item car-item" onClick={()=>{handleAddCar("Car 3")}}> Car 3 </button>
                    </div>
                  </div>
                </div>
                </div>
                <div className='choose-provider-footer'>
                   <span className='choose-provider-price'>Total : ₹ 499</span>
                   <Link to={"/address-options"}>
                    <button className='next-button'>CONTINUE</button>
                   </Link>
                </div> 
          </div>
          </div>
      </div>
    </div>
  </section>
    {/* <section className='ServDetailPopOut servLoc'>
    <div className='contianer-fluid ServDetailPop'>
      <div className='serveBack'>
        
        <h6>
          <span className='backbt'>
            <i className='fa fa-angle-left'></i>
          </span>
          Location
        </h6>
      </div>
       <div
        style={{ cursor: "pointer",zIndex:200 }}
        className='closeIcon'
        onClick={() =>handleClose() }
      >
        <i className='fa fa-times'></i>
      </div> 
      <div
        style={{ cursor: "pointer" }}
        className='closeIcon'
      >
        <i className='fa fa-times'></i>
      </div>
      <div className='contetTextArea' >
        <div className='headerFilterInfo'>
          <strong className='serviceName'>
          
            {serviceDetails.service_name}
            <p> {serviceDetails.description}</p>
          </strong>
          
          <div className='contInfo'>
            <div className='contInfoIn'>
              <ul className='startTask'>
                <li>
                  <img src={clock} alt='time' />
                  <span className='time flex-grow-1'>
                     {serviceDetails.duration + " Minutes"} 
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='listMenu'>
        <ul className='listTex paym'>
          <li>
            <a href='' className='active'>
            Choose your car
            </a>
          </li>
        </ul>
      </div>
      
        <div className='listMenu listLOc'>
          <div className="row choosecar">
              <h4>Choose Car Here</h4> <Link className="choosecarR" to={"myProfile?page=cars"}> <i className="fa fa-plus">{"Add New Car"}</i></Link>
          </div>
          <div className="choseCarSecOut">
          <select  className="choseCarSec" defaultValue={selectedCar} onChange={(e) => setSelectedCar(e.target.value)}>
            <option>Select Default Car</option>
            {(userCars && userCars.length > 0) && userCars?.map((list, key) => { 
            return  <option selected={list.is_default == 1?true:false} value={list.id} >{list.car_name}</option>
            })}
          </select>
          </div>
        </div>

        <div className='detOpFoot'>
          <div className='col priceNum'>
            <div className='totalPrice'>
              Total : <span className='price'>₹ {serviceDetails.price}</span>
              <span className='price'>{serviceDetails.currency.symbol +" "+ serviceDetails.price}</span> */}
             {/*</div>
          </div>
          <div className='col rightDet'>
            {/* <button
              style={{ cursor: "pointer" }}
              onClick={() => handleNextClick()}
            >
              Continue
            </button> */}

                {/* <button className='continueBtn' style={{cursor: "pointer"}} onClick={() => handleNextClick()} >
                        CONTINUE
                </button>
          </div>
        </div>
      </div>
    </div>
   </section> */}
    </>
  );
};

export default ServiceDetailChooseCar;
