import React, { useEffect } from "react";
import "./ServiceListing.scss";
import saleTag from "../../Assets/img/icons8-price_tag.png";
import priceTag from "../../Assets/img/icons8-sale_price_tag.png";
import SalonCatSlider from "../Common/SalonCatSlider/salonCatSlider";
import ProductBox from "../ProductBox/ProductBox";
import service from "../../Assets/img/service.png";
import filtIcon from "../../Assets/img/icons8-filter.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardById } from "../../Slices/home";
import ServiceDetail from "../ServiceDetail/ServiceDetail";
import ServiceDetailSchedule from "../ServiceDetailSchedule/ServiceDetailSchedule";
import ServiceDetailAddress from "../ServiceDetailAddress/ServiceDetailAddress";
import PaymentOption from "../PaymentOption/PaymentOption";
import ConFirmBooking from "../ConFirmBooking/ConFirmBooking";
import { useState } from "react";
import { List } from "react-content-loader";

const ServiceListing = () => {
  const {
    showServiceDetails,
    showScheduleOpt,
    showAddressOpt,
    showPaymentOpt,
    showConfirmBooking,
    dashboardSections,
    isDashboardSectionsFetched,
  } = useSelector((state) => state.home);

  const dispatch = useDispatch();
  const { id } = useParams();
  const [dashboardById, setDashboardById] = useState();
  const [dashLoading, setDashLoading] = useState(false);
  const [discount, setDiscount] = useState("Select Discount");
  const [price, setPrice] = useState("Select Price");

  const navigate = useNavigate();

  const getDashById = () => {
    setDashLoading(true);
    dispatch(getDashboardById({ id: id, data: { country_id: "103" } }))
      .then((response) => {
        setDashboardById(response.payload.data.result.data);
        setDashLoading(false);
      })
      .catch((err) => {
        console.info(err);
        setDashLoading(true);
      });
  };

  const data = [
    {
      id: 1,
      title: "Full Car Deep Cleaning & Polish",
      info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
      price: "299",
      mrp: "399",
      save_price: "100",
      img: require("../../Assets/img/service.png"),
      icon: require("../../Assets/img/clock.png"),
    },
    {
      id: 2,
      title: "Full Car Deep Cleaning & Polish",
      info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
      price: "299",
      mrp: "399",
      save_price: "100",
      img: require("../../Assets/img/service.png"),
      icon: require("../../Assets/img/clock.png"),
    },
    {
      id: 3,
      title: "Full Car Deep Cleaning & Polish",
      info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
      price: "299",
      mrp: "399",
      save_price: "100",
      img: require("../../Assets/img/service.png"),
      icon: require("../../Assets/img/clock.png"),
    },
    {
      id: 4,
      title: "Full Car Deep Cleaning & Polish",
      info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
      price: "299",
      mrp: "399",
      save_price: "100",
      img: require("../../Assets/img/service.png"),
      icon: require("../../Assets/img/clock.png"),
    },
    {
      id: 5,
      title: "Full Car Deep Cleaning & Polish",
      info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
      price: "299",
      mrp: "399",
      save_price: "100",
      img: require("../../Assets/img/service.png"),
      icon: require("../../Assets/img/clock.png"),
    },
    {
      id: 6,
      title: "Full Car Deep Cleaning & Polish",
      info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
      price: "299",
      mrp: "399",
      save_price: "100",
      img: require("../../Assets/img/service.png"),
      icon: require("../../Assets/img/clock.png"),
    },
    {
      id: 7,
      title: "Full Car Deep Cleaning & Polish",
      info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
      price: "299",
      mrp: "399",
      save_price: "100",
      img: require("../../Assets/img/service.png"),
      icon: require("../../Assets/img/clock.png"),
    },
    {
      id: 8,
      title: "Full Car Deep Cleaning & Polish",
      info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
      price: "299",
      mrp: "399",
      save_price: "100",
      img: require("../../Assets/img/service.png"),
      icon: require("../../Assets/img/clock.png"),
    },
  ];

  useEffect(() => {
    getDashById();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section>
        <div>
          <SalonCatSlider />
          <div className="service-box">
            {!dashLoading ? dashboardById?.map((filteredSection) => 
            <>
              <div className="container">
              <div className="section-title-box">
                <div className="title-box-bax">
                  <i class="fa fa-angle-left" onClick={() => navigate(-1)}></i>
                  <h2 className="sectionTitle centerText">
                  {filteredSection.section_title}
                  </h2>
                </div>
                <div className="filter-box">
                  <div class="dropdown">
                    <button
                      class="btn dropdown-toggle custom-dropdown"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={priceTag}
                        alt=""
                        width={20}
                      />
                      <span className="discount-text">
                        {"Discount: " + discount || "Select Discount"}{" "}
                      </span>
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        class="dropdown-item discount-text"
                        href="#"
                        onClick={() => {
                          setDiscount("10%-20%");
                        }}
                      >
                        10%-20%
                      </button>
                      <button
                        class="dropdown-item discount-text"
                        href="#"
                        onClick={() => {
                          setDiscount("20%-25%");
                        }}
                      >
                        20%-25%
                      </button>
                      <button
                        class="dropdown-item discount-text"
                        href="#"
                        onClick={() => {
                          setDiscount("25%-30%");
                        }}
                      >
                        25%-30%
                      </button>
                    </div>
                  </div>

                  <div className="">
                    <div class="dropdown">
                      <button
                        class="btn dropdown-toggle custom-dropdown"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={saleTag} alt="" />
                        <span className="discount-text">
                          {"Price Range : " + price}{" "}
                        </span>
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          class="dropdown-item discount-text"
                          href="#"
                          onClick={() => {
                            setPrice("₹500 - ₹1000");
                          }}
                        >
                          ₹500 - ₹1000
                        </button>
                        <button
                          class="dropdown-item discount-text"
                          href="#"
                          onClick={() => {
                            setPrice("₹1500 - ₹2000");
                          }}
                        >
                          ₹1500 - ₹2000
                        </button>
                        <button
                          class="dropdown-item discount-text"
                          href="#"
                          onClick={() => {
                            setPrice("₹3000 - ₹5000");
                          }}
                        >
                          ₹3000 - ₹5000
                        </button>
                      </div>
                    </div>
                    <span className="price-filter-text"> </span>
                  </div>
                </div>
              </div>
              <hr className="hr" />
            </div>
            
              <div className="container">
              <div className="box row">
                  {filteredSection.dashboard_services && filteredSection.dashboard_services.map((services, key) => 
                    <>
                      <div className="box-item col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
                         <ProductBox services={services} key={key} />
                      </div>
                    </>
                   )}
              </div>
            </div>
            </>
          ) :  <div className="container mt-5"><List /></div>  } 
          </div>
        </div>
        
        {showServiceDetails && <ServiceDetail />}
        {showScheduleOpt && <ServiceDetailSchedule />}
        {showAddressOpt && <ServiceDetailAddress />}
        {showPaymentOpt && <PaymentOption />}
        {showConfirmBooking && <ConFirmBooking />}
      </section>
    </>
  );
};

export default ServiceListing;
