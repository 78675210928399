import axios from "axios";
// import config from "../Config/Config.json"
import authHeader from "./auth-header";
let config={
    CARWASH_URL:process.env.REACT_APP_BASE_URL
}
const getCarsBrand = () => {
    return axios.get(config.CARWASH_URL + "/v1/get-car-list")
}

const getCarsFuel = () => {
    return axios.get(config.CARWASH_URL + "/v1/fuel/list")
}

const getCategories = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/category/list", data.type === "sub" ? data.action : "")
}

const getCMSPages = () => {
    return axios.get(config.CARWASH_URL + "/v1/get-cms-pages")
}

const getDashboardSection = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/dashboard", data)
}

const getDashboardSectionById = (data) => {
    return axios.post(config.CARWASH_URL + `/v1/dashboard/sections/${data.id}`, data.data)
}

const getServiceDetails = (data) => {
    return axios.get(config.CARWASH_URL + `/v1/service/detail?service_id=${data.id}&country_id=${data.code}&car_id=${data.car_id}`, {headers: authHeader()})
}

const getCMSContent = (data) => {
    return axios.get(config.CARWASH_URL + `/v1/get-cms?slug=${data}`)
}

const getAllOffers = (data) => {
    return axios.get(config.CARWASH_URL + `/v1/offer/list?country_id=${data}`)
}
const getAllCoupons = (data) => {
    return axios.get(config.CARWASH_URL + `/v1/coupon/list?country_id=${data}`)
}


const homeServices = {
    getCarsBrand,
    getCarsFuel,
    getCategories,
    getCMSPages,
    getDashboardSection,
    getServiceDetails,
    getDashboardSectionById,
    getCMSContent,
    getAllOffers,
    getAllCoupons,
}

export default homeServices
