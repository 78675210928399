import { useState, React, useEffect } from "react";
import "./ProductBox.scss";
import LazyLoad from "react-lazyload";
import { toast } from "react-toastify";
import Price from "../Common/Price/Price.jsx";
import Pricepackages from "../Common/Price/Pricepackages.jsx";
import {
  showAddressOpt,
  showPaymentOpt,
  showScheduleOpt,
  showServiceDetails,
  serviceChooseCar,
} from "../../Slices/home";

import paytm from "../../Assets/img/paytm.png";
import stripe from "../../Assets/img/stripe.png";
import cashon from "../../Assets/img/cashon.png";
import payU from "../../Assets/img/payU.png";
import voucher from "../../Assets/img/voucher.png";
import topImg from "../../Assets/img/Group3.png";
import service from "../../Assets/img/service.png";
import AddAddress from "../AddAddress/AddAddress";

import Button from "../Common/Button/Button";
import ButtonPackages from "../Common/Button/Button copy";
import {
  handleBookingDetails,
  handleResetBookingDetails,
  handlePaymentSummaryDetails,
  createPackageBooking,
  getScheduleServiceSlots,
  createPackageDetails,
  GetSpList,
  GetpackageService,
} from "../../Slices/auth";
import { useDispatch, useSelector } from "react-redux";
import clock from "../../Assets/img/clock.png";
import home from "../../Assets/img/home.png";
import office from "../../Assets/img/flat.png";

const ProductBoxPackages = ({ packages, key }) => {
  const dispatch = useDispatch();
  const [popup, setPopup] = useState("");

  const [SP, SelectSP_select] = useState(0);
  const [SPlist, SelectSP_list] = useState([]);
  const [packages_detail, SetPackagesdetail] = useState({});
  const [packages_id, Setpackages_id] = useState(0);
  const [paymentDetails, setpaymentDetails] = useState({});
  const [ConfirmBooking, SetConfirmBooking] = useState({});
  const [locationType, setLocationType] = useState({
    home: true,
    outlet: false,
  });

  const todayDate = new Date().toLocaleDateString("af-ZA");
  const days = [...Array(7).keys()].map((days) =>
    new Date(Date.now() + 86400000 * days).toDateString().split(" ")
  );

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const getSlots = (date, month, year) => {
    const fullDate = new Date(date + month + year).toLocaleDateString("af-ZA");
    setSelectedDate(fullDate);
    setSelectedTime("");
    dispatch(
      getScheduleServiceSlots({ start_date: fullDate, end_date: fullDate })
    );
  };

  const { scheduleSlots } = useSelector((state) => state.auth);

  const scheduleset = () => {
    setSelectedDate(todayDate);
    dispatch(
      getScheduleServiceSlots({ start_date: todayDate, end_date: todayDate })
    );
  };

  const [showAddAddress, setShowAddAddress] = useState(false);
  const { user_address } = useSelector((state) => state.auth.user);
  const { address_id } = useSelector((state) => state.auth.bookingDetails);
  const { isSchedule, serviceDetails } = useSelector((state) => state.home);
  const [coupon, setCoupon] = useState("");
  const [couponDetails, SetcouponDetails] = useState({});

  const { isLoggedIn, bookingDetails, userCars, user } = useSelector(
    (state) => state.auth
  );
  const { user_car_id_default } = useSelector((state) => state.auth);
  const [selectedCar, setSelectedCar] = useState(user_car_id_default);

  const handleLocationSelect = (location) => {
    if (location === "home") {
      setLocationType({ home: true, outlet: false });
      dispatch(handleBookingDetails({ location_type: "home" }));
    }
    if (location === "outlet") {
      setLocationType({ home: false, outlet: true });
      dispatch(handleBookingDetails({ location_type: "outlet" }));
    }
  };

  const handleNextClick = () => {
    if (bookingDetails.location_type === undefined) {
      toast.error("Select Location to Continue");
    } else if (locationType.home && bookingDetails.location === undefined) {
      toast.error("Select Address to Continue");
    } else {
      dispatch(showPaymentOpt(true));
      //dispatch(serviceChooseCar({type: "isSchedule",payload: true}))
      dispatch(handleBookingDetails({ total_price: serviceDetails.price }));
    }
  };

  useEffect(() => {}, []);

  const data = {
    // id: serviceId,
    code: "103",
    // car_id:user_car_id_default
  };
  const Packagesbookingdetail = (packages) => {
    var data = {
      package_id: packages.id,
      car_id: localStorage.getItem("user_car_id_default"),
    };
    dispatch(GetpackageService(data))
      .then((response) => {
        console.log({ ...response.payload.result.data });
        SetPackagesdetail({ ...response.payload.result.data });
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err);
        // setLoading(false);
      });
  };

  const SpList = (packages) => {
    const formData = new FormData();
    formData.append("service_id", packages.service_id);
    dispatch(GetSpList(formData))
      .then((response) => {
        SelectSP_list([...response.payload.result.data]);
        // setpaymentDetails({ ...response.payload.result.data });
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err);
        // setLoading(false);
      });
  };

  const PaymentSummaryDetailslist = () => {
    const formData = new FormData();
    formData.append("country_id", 103);
    formData.append("package_id", packages_id);
    formData.append("sp_id", SP);
    formData.append("car_id", localStorage.getItem("user_car_id_default"));

    dispatch(createPackageDetails(formData))
      .then((response) => {
        setpaymentDetails({ ...response.payload.result.data });
        console.log({ ...response.payload.result.data });
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err);
        // setLoading(false);
      });
  };

  const handleBookService = () => {
    const formData = new FormData();
    formData.append("sp_id", SP);
    formData.append("reminder_id", 1);
    formData.append("country_id", 103);
    formData.append("payment_summary", JSON.stringify(paymentDetails));
    formData.append("package_id", packages_id);
    formData.append("car_id", localStorage.getItem("user_car_id_default"));

    dispatch(createPackageBooking(formData))
      .then((response) => {
        if (response.meta.rejectedWithValue) {
          toast.error(response.payload.message);
        } else {
          setPopup("success");
          SetConfirmBooking("");
          toast.success(response.payload.message);
        }
      })
      .catch((err) => console.info("Booking Err", err));
  };

  return (
    <>
      <div className="headerFilterSec product" key={key}>
        <div className="headerFilterImg product-img-box">
          <LazyLoad>
            <img
              src={packages.package_image_url}
              className="img-radius product-img"
              alt="img1"
            />
          </LazyLoad>
        </div>
        <div className="headerFilterInfo">
          <strong className="serviceName title">{packages.package_name}</strong>
          <div
            className="textEllips contText card-info"
            dangerouslySetInnerHTML={{ __html: packages.package_description }}
          />
          <div className="contInfo">
            <Pricepackages
              packagesPrice={packages.final_price}
              packages={packages}
            />
            <>
              <div className="book-box">
                <div className="time-box">
                  <img src={clock} alt="clock Img" />
                  <span className="time">
                    {packages.package_service_limit + " Minutes"}
                  </span>
                </div>

                <button
                  key={packages.id}
                  className="book-button"
                  onClick={() => {
                    setPopup("detail"); 
                    Packagesbookingdetail(packages);
                    Setpackages_id(packages.id);
                    SpList(packages);
                  }}
                >
                  BOOK
                </button>
              </div>
            </>
          </div>
        </div>
      </div>

      {packages_detail.id == packages.id && popup == "detail" ? (
        <div className="packagepopup">
          <div id="popup1" className="overlay">
            <div className="popup">
              <div class="popup-image">
                <img src={packages.package_image_url} alt="" />
              </div>
              <div class="tittle d-flex align-items-center justify-content-between">
                <h2>{packages.package_name}</h2>
              </div>
              <div class="float-right ">
                <div class="w-100">
                  <button
                    class="btn btn-sm serFilBTn"
                    onClick={() => {
                      setPopup("select_car");
                    }}
                  >
                    BOOK
                  </button>
                  <button
                    class="btn btn-sm serFilBTn serFilBTnSedul"
                    onClick={() => {
                      scheduleset();
                      setPopup("schedule");
                    }}
                  >
                    SCHEDULE
                  </button>
                </div>
              </div>
              <div className="content">{packages.package_description}</div>
              <span>
                {packages.currency.symbol}
                {packages.currency.final_price}
              </span>
              <small>{packages.package_validity}Times</small>

              <div className="services">
                <h2>{packages_detail.service.service_name}</h2>

                {packages_detail.service.length >= 1 ??
                  packages_detail.service.map(({ key, value }) => {
                    return (
                      <>
                        <h3>{value.product_attribute_text}</h3>
                        <p>{value.attribute_name.attribute_name}</p>
                      </>
                    );
                  })}
              </div>

              <a
                className="close"
                onClick={(event) => {
                  event.preventDefault();
                  setPopup("");
                  Packagesbookingdetail({});
                }}
              >
                X
              </a>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {packages_detail.id == packages.id && popup == "schedule" ? (
        <section className="ServDetailPopOut servDetShe">
          <div className="contianer-fluid ServDetailPop">
            <div className="serveBack">
              <h6
                onClick={() => dispatch(showServiceDetails(true))}
                style={{ cursor: "pointer" }}
              >
                <span className="backbt">
                  <i className="fa fa-angle-left"></i>
                </span>
                Schedule
              </h6>
            </div>
            <div
              className="closeIcon"
              onClick={() => dispatch(showServiceDetails(false))}
            >
              <i className="fa fa-times"></i>
            </div>
            <div className="contetTextArea">
              <div className="headerFilterInfo">
                <strong className="serviceName">Delux Body Wash</strong>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book…
                  <a href="">Read more</a>
                </p>
                <div className="contInfo">
                  <div className="contInfoIn">
                    <ul className="startTask">
                      <li>
                        <img src={clock} alt="time" />
                        <span className="time flex-grow-1">30 Minutes</span>
                      </li>
                    </ul>

                    <ul className="price">
                      <li>₹ 599</li>
                      <li>
                        <span className="textCut">₹ 799</span>
                      </li>
                      <li>
                        <span className="textSave">Save ₹ 200</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="listMenu aftNone">
                <ul className="listTex">
                  <li>
                    <span className="active">Schedule</span>
                  </li>
                </ul>
              </div>
              <div className="row listBox mb15">
                <div className="leftBrandLocation">
                  <h4>
                    Select Date of Service <span>Agust 2022</span>
                  </h4>
                  <ul className="listRadio locationradio">
                    {days.map((value) => {
                      return (
                        <li>
                          <input
                            type="radio"
                            onClick={() =>
                              getSlots(value[2], value[1], value[3])
                            }
                            name="date"
                          />
                          <label>
                            <span>{value[0]}</span>
                            <h5>{value[2]}</h5>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="row listBox aftNone mb0">
                <div className="leftBrandLocation">
                  <h4>Time to Start The Service</h4>
                  <ul className="listRadio locationradioList">
                    {Object.keys(scheduleSlots).map((key, index) => {
                      return (
                        <>
                          {" "}
                          <strong>{key}</strong>
                          {scheduleSlots[key].map((list, id) => {
                            return (
                              <li key={id}>
                                <input
                                  type="radio"
                                  name="time"
                                  value={list}
                                  onClick={() => setSelectedTime(list)}
                                />
                                <label>
                                  <span>{list}</span>
                                </label>
                              </li>
                            );
                          })}
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="detOpFoot">
              <div className="col priceNum">
                <div className="totalPrice">
                  Total : <span className="price"> ₹ 599</span>
                </div>
              </div>
              <div className="col rightDet">
                <button
                  className="continueBtn"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPopup("select_car");
                  }}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {packages_detail.id == packages.id && popup == "select_car" ? (
        <section className="ServDetailPopOut servLoc">
          <div className="contianer-fluid ServDetailPop">
            <div className="serveBack">
              <h6>
                <span className="backbt">
                  <i className="fa fa-angle-left" />
                </span>
                Location
              </h6>
            </div>

            <div
              onClick={(event) => {
                event.preventDefault();
                setPopup("");
                Packagesbookingdetail({});
              }}
              className="closeIcon"
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-times" />
            </div>
            <div className="contetTextArea">
              <div className="headerFilterInfo">
                <strong className="serviceName">
                  {packages_detail.package_name}
                </strong>
                <div className="contInfo">
                  <div className="contInfoIn">
                    <ul className="startTask">
                      <li>
                        <img
                          src={
                            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAADAAAAAATDPpdAAAA6UlEQVQoFY2SwQqCQBCGd8a1SAIPHeocRs8QvVqCUC8W1EMEnrqERy9GbO42v7YJYdLCOvrPN+PM7JJ6L+ccpekhMcYmSrm4lakMQ873+11ORA4a4ZFlWVRVtBVtju/vJbmKKHJn4SpG5h74KkHYzUIiMGCDutYra+3aO2G1VgWzelqrZl4nUtPj8XTntmYvD1uw3DU4DLdeF+s+zBjFzOOL1vVN6h8ZU288JwFUyl8+tcIh9S6tfSykB4mkwMNgGXPuhM8bo0lsmczEq2AZh4I5e/GXBQNWMpHDoQwFwQcGbHPSyPrv1XgBAQd3YAwhalQAAAAASUVORK5CYII="
                          }
                          alt="time"
                        />
                        <span className="time flex-grow-1">
                          {packages_detail.package_service_limit} Minutes
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="listMenu">
                <ul className="listTex paym">
                  <li>
                    <a href className="active">
                      Choose your car
                    </a>
                    {/* <p className="text-danger">{selectedCar == 0? "select car":""}</p> */}
                  </li>
                </ul>
              </div>
              <div className="listMenu listLOc">
                <div className="row choosecar">
                  <h4>Choose Car Here</h4>{" "}
                  <a className="choosecarR" href="/myProfile?page=cars">
                    <i className="fa fa-plus">Add New Car</i>
                  </a>
                </div>
                <div className="choseCarSecOut">
                  <select
                    className="choseCarSec"
                    defaultValue={selectedCar}
                    onChange={(e) => setSelectedCar(e.target.value)}
                  >
                    <option>Select Default Car</option>
                    {userCars &&
                      userCars.length > 0 &&
                      userCars?.map((list, key) => {
                        return (
                          <option
                            selected={list.is_default == 1 ? true : false}
                            value={list.id}
                          >
                            {list.car_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="detOpFoot">
                <div className="col priceNum">
                  <div className="totalPrice">
                    Total :{" "}
                    <span className="price">
                      ₹ {packages_detail.final_price}
                    </span>
                  </div>
                </div>
                <div className="col rightDet">
                  <button
                    disabled={selectedCar == 0 ? true : false}
                    className="continueBtn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPopup("SP_select");
                    }}
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {packages_detail.id == packages.id && popup == "SP_select" ? (
        <div className="packagepopup">
          <div id="popup1" className="overlay">
            <div className="popup">
              <ul className="listRadio adressradio">
                {SPlist &&
                  SPlist.length > 0 &&
                  SPlist?.map((list, key) => {
                    return (
                      <li className="wonder" key={key}>
                        <input
                          type="radio"
                          name="address"
                          defaultValue={SP}
                          onChange={() => {
                            SelectSP_select(list.users.id);
                          }}
                        />
                        <div class="">
                          <div class="wonder-img">
                            <img src="image/Group 54.png" alt="" />
                          </div>
                          <div class="wonder-content">
                            <h2>Wonder Car Spa {SP}</h2>
                            <p>{list.sp_address.location}</p>
                            <a href="#">5 Km Away</a>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                {SPlist && SPlist.length === 0 && (
                  <strong>No service provider found.</strong>
                )}
              </ul>
              <p>{SP == 0 ? "Select service provider" : ""}</p>

              <div class="footer">
                <span>
                  {" "}
                  {packages_detail.currency.symbol}{" "}
                  {packages_detail.final_price}
                </span>
                <button
                  disabled={SP == 0 ? true : false}
                  onClick={() => {
                    setPopup("addredd");
                  }}
                >
                  Next
                </button>
              </div>

              <a
                class="close"
                onClick={(event) => {
                  event.preventDefault();
                  setPopup("");
                  Packagesbookingdetail({});
                }}
              >
                X
              </a>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {packages_detail.id == packages.id && popup == "addredd" ? (
        <section className="ServDetailPopOut servAdd">
          <div className="contianer-fluid ServDetailPop">
            <div className="serveBack">
              <h6 style={{ cursor: "pointer" }}>
                <span className="backbt">
                  <i className="fa fa-angle-left" />
                </span>
                Location
              </h6>
            </div>
            <div
              onClick={(event) => {
                event.preventDefault();
                setPopup("");
                Packagesbookingdetail({});
              }}
              className="closeIcon"
              style={{ cursor: "pointer" }}
            ></div>

            <div className="contetTextArea" style={{ height: "30em" }}>
              <div className="headerFilterInfo">
                <strong className="serviceName">
                  {packages_detail.package_name}
                  <p>{packages_detail.package_description}</p>
                </strong>
                <div className="contInfo">
                  <div className="contInfoIn">
                    <ul className="startTask">
                      <li>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAADAAAAAATDPpdAAAA6UlEQVQoFY2SwQqCQBCGd8a1SAIPHeocRs8QvVqCUC8W1EMEnrqERy9GbO42v7YJYdLCOvrPN+PM7JJ6L+ccpekhMcYmSrm4lakMQ873+11ORA4a4ZFlWVRVtBVtju/vJbmKKHJn4SpG5h74KkHYzUIiMGCDutYra+3aO2G1VgWzelqrZl4nUtPj8XTntmYvD1uw3DU4DLdeF+s+zBjFzOOL1vVN6h8ZU288JwFUyl8+tcIh9S6tfSykB4mkwMNgGXPuhM8bo0lsmczEq2AZh4I5e/GXBQNWMpHDoQwFwQcGbHPSyPrv1XgBAQd3YAwhalQAAAAASUVORK5CYII="
                          alt="time"
                        />
                        <span className="time flex-grow-1">
                          {" "}
                          {packages_detail.package_validity} Minutes
                        </span>
                      </li>
                    </ul>
                    <ul className="price">
                      <li>
                        {" "}
                        {packages_detail.currency.symbol}{" "}
                        {packages_detail.final_price}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="listMenu">
                <ul className="listTex paym">
                  <li>
                    <a href="" className="active">
                      Location
                    </a>
                  </li>
                </ul>
              </div>
              <div className="listMenu listLOc">
                <h4>Choose Location</h4>
                <ul className="listTex">
                  <li>
                    <span className="active"></span>
                  </li>
                </ul>
                <ul className="listRadio addlocationradio">
                  <li
                    className="homeLoc"
                    onClick={() => handleLocationSelect("home")}
                  >
                    <label className={locationType.home && "selected"}>
                      <img src={home} alt="home" />
                      <span>At Home</span>
                    </label>
                  </li>
                  <li
                    className="homeLoc"
                    onClick={() => handleLocationSelect("outlet")}
                  >
                    <label className={locationType.outlet && "selected"}>
                      <img src={office} alt="home" />
                      <span>At Outlet</span>
                    </label>
                  </li>
                </ul>
              </div>
              {locationType.home && (
                <div className="row listBox">
                  <div className="leftBrandLocation">
                    <h4>Saved Address</h4>
                    <h6 className="text-danger">
                      {address_id >= 0 ? "" : "Select addresss"}
                    </h6>
                    <ul className="listRadio adressradio">
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowAddAddress(!showAddAddress)}
                      >
                        + Add New Address
                      </li>
                      {user_address &&
                        user_address.length > 0 &&
                        user_address?.map((list, key) => {
                          return (
                            <li
                              key={key}
                              onClick={() =>
                                dispatch(
                                  handleBookingDetails({
                                    location: list.location,
                                    address_id: list.id,
                                    country_id: "103",
                                  })
                                )
                              }
                            >
                              <input
                                type="radio"
                                name="address"
                                defaultValue={address_id}
                              />
                              <label>
                                <h5>{list.block_shop_no} </h5>
                                <p>
                                  {list.location}
                                  <br />
                                  {list.address_type}
                                  {/* <span>Mo : missing in api</span> */}
                                </p>
                              </label>
                            </li>
                          );
                        })}
                      {user_address && user_address.length === 0 && (
                        <strong>No Address found.</strong>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              <div className="detOpFoot">
                <div className="col priceNum">
                  <div className="totalPrice">
                    Total :
                    <span className="price">
                      {/* {serviceDetails.currency.symbol} */}
                      {serviceDetails.offer_price != ""
                        ? serviceDetails.offer_price
                        : serviceDetails.price}
                    </span>
                  </div>
                </div>
                <div className="col rightDet">
                  <button
                    disabled={address_id >= 0 ? false : true}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPopup("payment");
                      PaymentSummaryDetailslist();
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
          {showAddAddress && (
            <AddAddress
              showAddAddress={showAddAddress}
              showEditAddress={false}
              selectedAddress={{}}
              setShowAddress={setShowAddAddress}
              setShowEditAddress={null}
            />
          )}
        </section>
      ) : (
        ""
      )}

      {packages_detail.id == packages.id && popup == "payment" ? (
        <section className="ServDetailPopOut">
          <div className="contianer-fluid ServDetailPop servPay">
            <div className="serveBack">
              <h6 style={{ cursor: "pointer" }}>
                <span className="backbt">
                  <i className="fa fa-angle-left" />
                </span>
                Payments
              </h6>
            </div>
            <div
              className="closeIcon"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.preventDefault();
                setPopup("");
                Packagesbookingdetail({});
              }}
            >
              <i className="fa fa-times" />
            </div>
            <div className="headerFilterInfo">
              <strong className="serviceName">
                {packages_detail.package_name}
              </strong>
              <div className="contInfo">
                <div className="contInfoIn">
                  <ul className="startTask">
                    <li>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAADAAAAAATDPpdAAAA6UlEQVQoFY2SwQqCQBCGd8a1SAIPHeocRs8QvVqCUC8W1EMEnrqERy9GbO42v7YJYdLCOvrPN+PM7JJ6L+ccpekhMcYmSrm4lakMQ873+11ORA4a4ZFlWVRVtBVtju/vJbmKKHJn4SpG5h74KkHYzUIiMGCDutYra+3aO2G1VgWzelqrZl4nUtPj8XTntmYvD1uw3DU4DLdeF+s+zBjFzOOL1vVN6h8ZU288JwFUyl8+tcIh9S6tfSykB4mkwMNgGXPuhM8bo0lsmczEq2AZh4I5e/GXBQNWMpHDoQwFwQcGbHPSyPrv1XgBAQd3YAwhalQAAAAASUVORK5CYII="
                        alt="time"
                      />
                      <span className="time flex-grow-1">
                        {" "}
                        {packages_detail.package_validity}min
                      </span>
                    </li>
                  </ul>
                  <ul className="price">
                    <li>₹40</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="contetTextArea">
              <div className="listMenu">
                <ul className="listTex paym">
                  <li>
                    <a href className="active">
                      Payment
                    </a>
                  </li>
                </ul>
              </div>
              <div className="row listBox">
                <div className="leftBrandLocation">
                  <h4>Payment Options</h4>
                  <ul className="listRadio locationradio payMentOpt">
                    <li>
                      <input type="radio" name="payment" />
                      <label className="strip">
                        <img src={stripe} />
                        <span>Stripe</span>
                      </label>
                    </li>
                    <li>
                      <input type="radio" name="payment" />
                      <label className="paytm">
                        <img src={paytm} />
                        <span>Paytm</span>
                      </label>
                    </li>
                    <li>
                      <input type="radio" name="payment" />
                      <label className="payu">
                        <img src={payU} />
                        <span>PayU Money</span>
                      </label>
                    </li>
                    <li>
                      <input type="radio" name="payment" />
                      <label className="cod">
                        <img src={cashon} />
                        <span>C.O.D</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="summarRight">
                <h3>Payment Summary</h3>
                <ul>
                  <li>
                    <span>Item total</span>
                    <b>
                      {" "}
                      {packages.currency.symbol} {paymentDetails.item_total}
                    </b>
                  </li>
                  <li>
                    <span>Tax</span>
                    <b>
                      {" "}
                      {packages.currency.symbol} {paymentDetails.tax_amount}
                    </b>
                  </li>
                </ul>
              </div>
              <div className="detOpFoot">
                <div className="col priceNum">
                  <div className="totalPrice">
                    Total :
                    <span className="price">
                      {packages.currency.symbol} {paymentDetails.total}
                    </span>
                  </div>
                </div>
                <div className="col rightDet">
                  <button
                    style={{ cursor: "pointer" }}
                    // onClick={() => dispatch(showConfirmBooking(true))}
                    onClick={() => handleBookService()}
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {packages_detail.id == packages.id && popup == "success" ? (
        <section className="ServDetailPopOut serviceOpt confirm">
          <div className="contianer-fluid ServDetailPop">
            <div
              className="closeIcon"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.preventDefault();
                setPopup("");
                Packagesbookingdetail({});
              }}
            >
              <i className="fa fa-times"></i>
            </div>

            <div className="contetTextArea">
              <img src={topImg} className="topImg" />
              <div className="profdetHeadText">
                <span className="textalert">{"Thank You !!!"}</span>
                <h2>{"Package booking Confirmed"} </h2>
              </div>

              <div className="summarLeft">
                <div className="summarLeftIn">
                  <div className="summarLeftImgText">
                    <div className="summarLeftImg">
                      <img src={packages_detail.package_image_url} />
                    </div>
                    <div className="headerFilterInfo">
                      <strong className="serviceName">
                        {packages_detail.package_name}
                      </strong>
                      <p>{packages_detail.package_description}</p>
                    </div>
                  </div>
                </div>
                <div className="summarLeftAdd">
                  {/* <ul className='listRadio addlocationradio'>
                            <li>
                                <ul className='listRadio locationradioList'>
                                    <li className='date'>
                                        <label>
                                            <h5>26 August</h5>
                                        </label>
                                    </li>
                                    <li className='date'>
                                        <label>
                                            <h5>02:00 PM</h5>
                                        </label>
                                    </li>
                                    <li className='date'>
                                        <label>
                                            <h5>45 Minutes</h5>
                                        </label>
                                    </li>
                                    <li className='date'>
                                        <label>
                                            <h5>At Home</h5>
                                        </label>
                                    </li>
                                </ul>
                            </li>
                        </ul> */}
                  <ul className="listRadio addlocationradio">
                    <li className="addText">
                      <label>
                        <h5>Office</h5>
                        <p>{bookingDetails.location}</p>
                      </label>
                    </li>
                  </ul>
                </div>
                <button
                  className="confirm"
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    event.preventDefault();
                    setPopup("");
                    Packagesbookingdetail({});
                  }}
                >
                  Go to My Package booking
                </button>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default ProductBoxPackages;
