import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../Slices/auth"
import homeReducer from "../Slices/home"

const reducer = {
    auth: authReducer,
    home: homeReducer,
}

const store = configureStore({
    reducer: reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
})

export default store