import React, { useEffect } from "react";
import "./ServiceSummary.scss";
import home from "../../Assets/img/home.png";
import clock from "../../Assets/img/clock.png";
import { Link, useNavigate } from "react-router-dom";
import service from "../../Assets/img/service.png";

const ServiceSummary = () => {
  return (
    <>
      <section className='ServiceSummaryOut'>
        <div className='summarText container'>
          <div className='row ml0'>
            <Link to=''>
              <i className='fa fa-angle-left'></i>Summary
            </Link>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='summarLeft'>
              <div className='summarLeftIn'>
                <div className='summarLeftImgText'>
                  <div className='summarLeftImg'>
                    <img src={service} alt='img' />
                  </div>
                  <div className='headerFilterInfo'>
                    <strong className='serviceName'>product name</strong>
                    <div className='contInfo'>
                      <div className='contInfoIn'>
                        <ul className='startTask'>
                          <li>
                            <img src={clock} alt='time' />
                            <span className='time flex-grow-1'>30 min</span>
                          </li>
                        </ul>
                        <ul className='price'>
                          <li>₹ 399</li>
                          <li>
                            <span className='textCut'>₹ 799</span>
                          </li>
                          <li>
                            <span className='textSave'>Save ₹ 200</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='summarLeftAdd'>
                <ul className='listRadio addlocationradio'>
                  <li className='homeLoc'>
                    <h4>Location</h4>
                    <label>
                      <img src={home} alt='home' />
                      <span>At Home</span>
                    </label>
                  </li>
                  <li>
                    <h4>Schedule</h4>
                    <ul className='listRadio  locationradioList'>
                      <li className='date'>
                        <label>
                          <span>Thus</span>
                          <h5>26</h5>
                        </label>
                      </li>
                      <li className='time'>
                        <label>
                          <p>30 mins</p>
                        </label>
                      </li>
                    </ul>
                  </li>
                </ul>
                <h4>Address</h4>
                <ul className='listRadio addlocationradio'>
                  <li className='addText'>
                    <label>
                      <h5>Address</h5>
                      <p>
                        G-57, Goving Park, Ghodasar Road,
                        manekchowk,Ahmedabad-380050
                        <span>Mo : 987456878</span>
                      </p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className='summarRight'>
              <h3>Payment Summary</h3>
              <ul>
                <li>
                  <span>Item </span> <b>₹ 1,198</b>
                </li>
                <li>
                  <span>{"L’Oreal Paris > Black"}</span> <b>₹ 100</b>
                </li>
                <li>
                  <span>{"L’Oreal Paris > Golden"}</span> <b>₹ 160</b>
                </li>
                <li className='totalPrice'>
                  <span>Total</span>
                  <b>₹ 598</b>
                </li>
              </ul>
              <button style={{ cursor: "pointer" }}>Pay Now</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceSummary;
