import React from 'react';
import './Cms.scss';
import banner from "../../Assets/img/terms-conditions4.jpeg";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getCMSPageContent } from '../../Slices/home';
import { useState } from 'react';


const AboutUs = () => {
    const dispatch = useDispatch()
    const [slug, setSlug] = useSearchParams()
    const [pageContent, setPageContent] = useState({content: "", loading: true})

    useEffect(() => {
        getContent()
        // eslint-disable-next-line
    },[slug])

    const getContent = () => {
        dispatch(getCMSPageContent(slug.get('slug')))
        .then((response) => {
            if(response.payload.status === 200){
                setPageContent({...pageContent, content: response.payload.data.result.data[0], loading: false})
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            } else {
                setPageContent({content: "", loading: false})
            }
        })
        .catch((err) => console.info("err CMS", err))
    }

  return (
    <>
    <section className='bodystruct'>
        <div className='bannerDesign'>
            <img src={banner} alt='banner' />
            <div className="opacity-medium bg-extra-dark-gray"></div>
            <div className="textPosi">
                <div className="row ">
                    <h1 className="alt-font text-white font-weight-500 letter-spacing-minus-1 mb-0">
                        {pageContent.content.title}
                    </h1>
                </div>
            </div>
        </div>
    </section>
    <section className="big-section bg-light-gray">
        <div dangerouslySetInnerHTML={{__html: pageContent.content.content}} />
    </section>
    {/* <section className="big-section bg-light-gray">
        <div className="container">
            <div className="row align-items-center justify-content-center">
                    <div className='col-12 text-center titleText'>
                            <span className="">Terms </span>
                            <h5 className="">Conditions of use</h5>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-2 justify-content-center aboutBoxes">
                        <div className="col col-md-9 margin-30px-bottom xs-margin-15px-bottom">
                            <div className="feature-box h-100 feature-box-left-icon border-radius-5px   box-shadow-small feature-box-dark-hover overflow-hidden padding-4-rem-all">
                                <div className="feature-box-icon">
                                    <i className="fa fa-tv"></i>
                                </div>
                                <div className="feature-box-content last-paragraph-no-margin">
                                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray">Powerfull theme options</span>
                                    <p>Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text. Adipiscing eiusmod tempor incididunt magna.</p>
                                </div>
                                <div className="feature-box-overlay bg-gradient-magenta-orange-2"></div>
                            </div>
                        </div>
                        <div className="col col-md-9 margin-30px-bottom xs-margin-15px-bottom">
                            <div className="feature-box h-100 feature-box-left-icon border-radius-5px   box-shadow-small feature-box-dark-hover overflow-hidden padding-4-rem-all">
                                <div className="feature-box-icon">
                                    <i className="line-icon-Cursor-Click2 icon-medium text-fast-blue"></i>
                                </div>
                                <div className="feature-box-content last-paragraph-no-margin">
                                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray">Unlimited layouts and styles</span>
                                    <p>Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text. Adipiscing eiusmod tempor incididunt magna.</p>
                                </div>
                                <div className="feature-box-overlay bg-gradient-magenta-orange-2"></div>
                            </div>
                        </div>
                        <div className="col col-md-9 md-margin-30px-bottom xs-margin-15px-bottom">
                            <div className="feature-box h-100 feature-box-left-icon border-radius-5px   box-shadow-small feature-box-dark-hover overflow-hidden padding-4-rem-all">
                                <div className="feature-box-icon">
                                    <i className="line-icon-Like-2 icon-medium text-fast-blue"></i>
                                </div>
                                <div className="feature-box-content last-paragraph-no-margin">
                                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray">Developing an effective strategy</span>
                                    <p>Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text. Adipiscing eiusmod tempor incididunt magna.</p>
                                </div>
                                <div className="feature-box-overlay bg-gradient-magenta-orange-2"></div>
                            </div>
                        </div>
                        <div className="col col-md-9">
                            <div className="feature-box h-100 feature-box-left-icon border-radius-5px   box-shadow-small feature-box-dark-hover overflow-hidden padding-4-rem-all">
                                <div className="feature-box-icon">
                                    <i className="line-icon-One-FingerTouch icon-medium text-fast-blue"></i>
                                </div>
                                <div className="feature-box-content last-paragraph-no-margin">
                                    <span className="alt-font font-weight-500 margin-10px-bottom d-block text-extra-dark-gray">Automated testing and support</span>
                                    <p>Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been text. Adipiscing eiusmod tempor incididunt magna.</p>
                                </div>
                                <div className="feature-box-overlay bg-gradient-magenta-orange-2"></div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section className="big-section teamSect" >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 md-margin-50px-bottom wow animate__fadeIn teamImg" >
                         <img src={team}/>
                      
                    </div>
                    <div className="col-12 col-lg-5 offset-lg-1 wow animate__fadeIn" >
                        <h5 className="alt-font text-extra-dark-gray font-weight-500">It is teamwork that makes the dream work.</h5>
                        <p>With years of experience in the website design and development industry ThemeZaa pride ourselves on creating unique, creative &amp; quality designs that are developed upon the latest coding.</p>
                        <ul className="p-0 list-style-02 margin-2-rem-top margin-3-rem-bottom">
                            <li className="margin-15px-bottom"><i className="fa fa-arrow-right" ></i><span className="text-extra-dark-gray alt-font">Beautiful and easy to understand animations</span></li>
                            <li className="margin-15px-bottom"><i className="fa fa-arrow-right" ></i><span className="text-extra-dark-gray alt-font">Theme advantages are pixel perfect design</span></li>
                            <li className="margin-15px-bottom"><i className="fa fa-arrow-right" ></i><span className="text-extra-dark-gray alt-font">Find more creative ideas for your projects</span></li>
                        </ul>
                        <a href="contact-us-modern.html" className="btn btn-medium btn-gradient-magenta-orange-2 btn-round-edge">Get Started Now</a>
                    </div>
                </div>
            </div>
        </section>
    <section className="big-section">
        <div className="container">
            <div className="row align-items-center justify-content-center">
                    <div className='col-12 text-center titleText'>
                            <span className="">No helpful answer? </span>
                            <h5 className="">Here are some options</h5>
                    </div>
                    <div className="col-12 col-lg-6 col-md-9  ansCard">
                        <div className="feature-box feature-box-left-icon border-radius-4px bg-neon-orange overflow-hidden padding-4-rem-all">
                            <div className="feature-box-icon">
                                <i className="line-icon-Hipster-Headphones fa fa-phone icon-extra-large text-white"></i>
                            </div>
                            <div className="feature-box-content padding-15px-left">
                                <span className="alt-font text-extra-medium font-weight-500 margin-10px-bottom d-block text-white">Help desk support</span>
                                <p className="text-white opacity-6 w-90 lg-w-100 margin-20px-bottom">Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.</p>
                                <a href="#" className="btn btn-link btn-large text-fast-blue">Browse topics</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-9  ansCard">
                        <div className="feature-box feature-box-left-icon border-radius-4px bg-neon-orange overflow-hidden padding-4-rem-all">
                            <div className="feature-box-icon">
                                <i className="line-icon-Hipster-Headphones fa fa-users icon-extra-large text-white"></i>
                            </div>
                            <div className="feature-box-content padding-15px-left">
                                <span className="alt-font text-extra-medium font-weight-500 margin-10px-bottom d-block text-white">Help desk support</span>
                                <p className="text-white opacity-6 w-90 lg-w-100 margin-20px-bottom">Lorem ipsum is simply dummy text of the and typesetting industry lorem ipsum.</p>
                                <a href="#" className="btn btn-link btn-large text-fast-blue">Browse topics</a>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section className="big-section bg-light-gray ourteam" >
            <div className="container">
                <div className="row justify-content-center">
                <div className='col-12 text-center titleText'>
                            <span className="">Terms </span>
                            <h5 className="">Conditions of use</h5>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-3 row-cols-sm-1 justify-content-center">
                    
                    <div className="col team-style-02 text-center sm-margin-15px-bottom wow animate__fadeIn">
                        <figure>
                            <div className="team-member-image border-radius-5px overflow-hidden">
                                <img alt="" src="https://via.placeholder.com/800x1000" data-no-retina=""/>
                                <div className="team-member-details bg-transparent-gradient-magenta-orange padding-2-half-rem-lr align-items-center justify-content-center d-flex flex-column">
                                    <div className="text-white text-uppercase alt-font font-weight-500">I am Designer</div>
                                    <div className="social-icon w-100   bottom-40px left-0px">
                                        <a href="https://www.facebook.com/" target="_blank" className="icon-very-small text-white"><i aria-hidden="true" className="fa fa-facebook"></i></a>
                                        <a href="https://twitter.com/" target="_blank" className="icon-very-small text-white"><i aria-hidden="true" className="fa fa-twitter"></i></a>
                                        <a href="https://www.linkedin.com/" target="_blank" className="icon-very-small text-white"><i aria-hidden="true" className="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                            <figcaption className="team-member-position padding-35px-tb text-center">
                                <div className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">Jemmy Watson</div>
                                <span className="text-small text-uppercase">Graphic Designer</span>
                            </figcaption>

                        </figure>
                    </div>
                     
                    
                    <div className="col team-style-02 text-center sm-margin-15px-bottom wow animate__fadeIn" >
                        <figure>
                            <div className="team-member-image border-radius-5px overflow-hidden">
                                <img alt="" src="https://via.placeholder.com/800x1000" data-no-retina="" />
                                <div className="team-member-details bg-transparent-gradient-magenta-orange padding-2-half-rem-lr align-items-center justify-content-center d-flex flex-column">
                                    <div className="text-white text-uppercase alt-font font-weight-500">I am Devloper</div>
                                    <div className="social-icon w-100   bottom-40px left-0px">
                                        <a href="https://www.facebook.com/" target="_blank" className="icon-very-small text-white"><i aria-hidden="true" className="fa fa-facebook"></i></a>
                                        <a href="https://twitter.com/" target="_blank" className="icon-very-small text-white"><i aria-hidden="true" className="fa fa-twitter"></i></a>
                                        <a href="https://www.linkedin.com/" target="_blank" className="icon-very-small text-white"><i aria-hidden="true" className="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                            <figcaption className="team-member-position padding-35px-tb text-center">
                                <div className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">Bryan Jonhson</div>
                                <span className="text-small text-uppercase">App Devloper</span>
                            </figcaption>
                        </figure>
                    </div>
                     
                    
                    <div className="col team-style-02 text-center wow animate__fadeIn" data-wow-delay="0.6s" >
                        <figure>
                            <div className="team-member-image border-radius-5px overflow-hidden">
                                <img alt="" src="https://via.placeholder.com/800x1000" data-no-retina=""/>
                                <div className="team-member-details bg-transparent-gradient-magenta-orange padding-2-half-rem-lr align-items-center justify-content-center d-flex flex-column">
                                    <div className="text-white text-uppercase alt-font font-weight-500">I am Developer</div>
                                    <div className="social-icon w-100   bottom-40px left-0px">
                                        <a href="https://www.facebook.com/" target="_blank" className="icon-very-small text-white"><i aria-hidden="true" className="fa fa-facebook"></i></a>
                                        <a href="https://twitter.com/" target="_blank" className="icon-very-small text-white"><i aria-hidden="true" className="fa fa-twitter"></i></a>
                                        <a href="https://www.linkedin.com/" target="_blank" className="icon-very-small text-white"><i aria-hidden="true" className="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                            <figcaption className="team-member-position padding-35px-tb text-center">
                                <div className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">Jeremy Dupont</div>
                                <span className="text-small text-uppercase">Web Developer</span>
                            </figcaption>
                        </figure>
                    </div>
                     
                </div>
            </div>
        </section> */}
</>
  );
};

export default AboutUs

