import React, { useEffect, useState } from "react";
import "./PaymentOption.scss";
import { useDispatch, useSelector } from "react-redux";
import clock from "../../Assets/img/clock.png";
import paytm from "../../Assets/img/paytm.png";
import stripe from "../../Assets/img/stripe.png";
import cashon from "../../Assets/img/cashon.png";
import payU from "../../Assets/img/payU.png";
import voucher from "../../Assets/img/voucher.png";
import {
  showAddressOpt,
  showConfirmBooking,
  showPaymentOpt,
} from "../../Slices/home";
import {
  createBooking,
  handleResetBookingDetails,
  handlePaymentSummaryDetails,
  editUserProfile,
  getUserProfile,
  addCouponApply,
  handleCouponApply,
} from "../../Slices/auth";
import { toast } from "react-toastify";

import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";

const payments = [
  {
    id: 1,
    name: "Stripe",
    url: require("../../Assets/img/stripe.png"),
    color: "stripe-color"
  },
  {
    id: 2,
    name: "Paytm",
    url: require("../../Assets/img/paytm.png"),
    color: "paytm-color"
  },
  {
    id: 3,
    name: "PayU Money",
    url: require("../../Assets/img/payU.png"),
    color: "payUmoney-color"
  },
  {
    id: 4,
    name: "C.O.D",
    url: require("../../Assets/img/cashon.png"),
    color: "cod-color"
  }
]

const PaymentOption = () => {
  const dispatch = useDispatch();
  const [paymentDetails, setpaymentDetails] = useState({});
  const [count, setCount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [couponDetails, SetcouponDetails] = useState({});
  const { serviceDetails } = useSelector((state) => state.home);
  const { bookingDetails } = useSelector((state) => state.auth);
  const [payment,setPayment] = useState ("");

  useEffect(() => {
    PaymentSummaryDetails();
  }, []);

  const PaymentSummaryDetails = () => {
    const formData = new FormData();
    formData.append("country_id", 103);
    formData.append("total_price",serviceDetails.offer_price != ""
        ? serviceDetails.offer_price
        : serviceDetails.price
    );
    if (couponDetails && couponDetails.coupon_code) {
      const coupon_value = {
        total_price: serviceDetails.price,
        discount_amount: couponDetails.discount_amount || "",
        final_amount: couponDetails.final_amount,
        coupon_code: couponDetails.coupon_code,
        coupon_name: couponDetails.coupon_name,
        // "coupon_id": couponDetails.id
      };
      formData.append("coupon_array", JSON.stringify(coupon_value));
    }
    dispatch(handlePaymentSummaryDetails(formData))
      .then((response) => {
        setpaymentDetails({ ...response.payload.result.data});
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err);
        // setLoading(false);
      });
  };

  const couponApply = () => {
    const formData = new FormData();
    formData.append("country_id", 103);
    formData.append("service_id", serviceDetails.id);
    formData.append("coupon_code", coupon);
    dispatch(handleCouponApply(formData))
      .then((response) => {
        SetcouponDetails({ ...response.payload.result.data });

        setTimeout(() => {
          PaymentSummaryDetails();
        }, 100);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err);
        // setLoading(false);
      });
  };

  const handleBookService = () => {
    const formData = new FormData();
    const data = { ...serviceDetails, ...bookingDetails };

    const PaymentDetails = { payment_summary: JSON.stringify(paymentDetails) };

    var dataDetails = {
      ...data,
      ...PaymentDetails,
      car_id: bookingDetails.car_id,
    };

    dispatch(createBooking(dataDetails))
      .then((response) => {
        if (response.meta.rejectedWithValue) {
          toast.error(response.payload.message);
        } else {
          toast.success(response.payload.message);
          dispatch(showConfirmBooking(true));
        }
      })
      .catch((err) => console.info("Booking Err", err));
  };

  const handleClose = () => {
    dispatch(showPaymentOpt(false));
    dispatch(handleResetBookingDetails());
  };

  const is_cuponeadded = () => {
    return (
      <>
        {paymentDetails.is_coupon_applied == true ? (
          <li key="dsadsad">
            <span key="dsadsad">{"80% amount after service"}</span>
            <b key="item_total2">- ₹ {paymentDetails.total ?? ""}</b>
          </li>
        ) : (
          ""
        )}
      </>
    );
  };
  const navigate = useNavigate();
  return (
    <>
    <section className="container service-details"> 
    
    <div className="service-details-title" >
      <i class="fa fa-angle-left" onClick={() => {navigate(-1)}}></i>
      <span className="service-details-title-text">Location</span>
    </div>

    <div className="service-details-body">
      <div className="row">
      <div className="col-12 col-lg-5">
              <div className="">
                  <img className="service-details-img" src={require("../../Assets/img/service.png")} alt="" />
              </div>
              <div className='service-details-information marginTop-19 Schedule'>
                <div className="service-details-info">
                    <h6 className="service-details-name">Delux Body Wash</h6>
                    <p className="Schedule-service-details-description">Perfect for clients who need their interior cleaned but want most of the focus on the exterior of their vehicle. Lorem Ipsum is simply dummy text…</p>
                </div>
                <div className="service-details-time">
                    <img src={clock} alt="" width={15} height={15}/>
                    <span className="time">35 Minutes.</span>
                </div>
              </div>
          </div>

          <div className="col-12 col-lg-7 ">
            <div className="choose-provider address-options">
                <div className='schedule-title-box m-0'>
                    <div>
                        <span className='schedule-box p-0'>Payment</span>
                    </div>    
                </div>
                  <div className="choose-body">
                      <div className="choose-body-title">
                        <span className="choose-car-here p-0">Payment Options</span>
                      </div>
                  </div>    

                  <div className="payment-box row m-0">
                      {
                        payments.map((item,index)=>{
                          return (
                              <>
                                  <div className={`payment-list ${item.color}`}  onClick={()=>{
                                    setPayment(item?.name);
                                  }}>
                                    <div className={`payment-item`}>
                                      <img className="payment-icon" src={item?.url} alt="" />
                                      <h3 className="payment-name">{item?.name}</h3>
                                    </div>
                                    <div className='radio-body-pink'>
                                      {
                                        payment === item?.name ? <> <div className='radio-dot-pink'></div></> : <></>
                                      }
                                    </div>
                                  </div>
                              </>
                          )
                        })
                      }
                  </div>

                  <div className="row coupon-box m-3 p-2">  
                        <div className="col-12 col-sm-4  p-0">
                        <div className="title-box mt-0"> 
                          <img src={require("../../Assets/img/voucher.png")} alt="" className="img-voucher" />
                          <h3 className="title-box-text">Apply Coupon :</h3>
                        </div>
                        </div>
                        <div className="col-12 col-sm-6  pe-1 pl-0"><input type="text" placeholder="Enter coupon code" className="coupon-input w-100" /></div>
                        <div className="col-12 col-sm-2  p-0"><button className="apply-coupon-btn"> Apply </button></div>
                  </div>

                  <div className="payment-info-box">
                        <h3 className="payment-main-title">Payment Summary</h3>
                        <div className="payment-item">
                            <span className="payment-title">Item total</span>
                            <span className="payment-price">₹ 599</span>
                        </div>
                        <div className="payment-item">
                            <span className="payment-title">Tax</span>
                            <span className="payment-price">₹ 31</span>
                        </div>
                        <div className="payment-item">
                            <span className="payment-title-green">Coupon Discount (GET100OFF)</span>
                            <span className="payment-title-green">- ₹ 100</span>
                        </div>
                        <hr />
                        <div className="payment-item">
                            <span className="payment-title-total">Total</span>
                            <span className="payment-price">₹ 430</span>
                        </div>
                        <div className="payment-item">
                            <span className="payment-title">80% amount after service</span>
                            <span className="payment-price"> - ₹ 344</span>
                        </div>
                        <hr />
                        <div className="payment-item">
                            <span className="payment-title-total">Amount to be Pay Now</span>
                            <span className="payment-price">₹ 86</span>
                        </div>
                  </div>
                <div className='choose-provider-footer'>
                   <span className='choose-provider-price'>Total : ₹ 499</span>
                   <Link to={"/booking-confirmed"}>
                    <button className='next-button'>CONTINUE</button>
                   </Link>
                </div> 
          </div>
          </div>
      </div>
    </div>
  </section>
    {/* <section className="ServDetailPopOut">
      <div className="contianer-fluid ServDetailPop servPay">
        <div className="serveBack">
          <h6
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(showAddressOpt(true))}
          >
            <span className="backbt">
              <i className="fa fa-angle-left"></i>
            </span>
            Payments
          </h6>
        </div>

        <div
          className="closeIcon"
          style={{ cursor: "pointer" }}
          onClick={() => handleClose()}
        >
          <i className="fa fa-times"></i>
        </div>
        <div className="headerFilterInfo">
          <strong className="serviceName">{serviceDetails?.service_name}</strong>
          <div className="contInfo">
            <div className="contInfoIn">
              <ul className="startTask">
                <li>
                  <img src={clock} alt="time" />
                  <span className="time flex-grow-1">
                    {serviceDetails?.duration + "min"}
                  </span>
                </li>
              </ul>

              <ul className="price">
                <li>
                  {serviceDetails?.currency.symbol}
                  {serviceDetails?.offer_price != ""
                    ? serviceDetails?.offer_price
                    : serviceDetails?.price}
                </li>
                {serviceDetails?.offer_price != "" ? (
                  <>
                    <li>
                      <span className="textCut">
                        
                        {serviceDetails?.offer_price != ""
                          ? serviceDetails?.price
                          : serviceDetails?.offer_price}
                      </span>
                    </li>
                    <li>
                      <span className="textSave">
                        Save {serviceDetails?.offer_discount_price}
                      </span>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            </div>
            {/* <PricIncDec /> */}
          {/* </div>
        </div>
        <div className="contetTextArea">
          <div className="listMenu">
            <ul className="listTex paym">
              <li>
                <a href="" className="active">
                  Payment
                </a>
              </li>
            </ul>
          </div>

          <div className="row listBox">
            <div className="leftBrandLocation">
              <h4>Payment Options</h4>
              <ul className="listRadio locationradio payMentOpt">
                <li>
                  <input type="radio" name="payment" />
                  <label className="strip">
                    <img src={stripe} />
                    <span>Stripe</span>
                  </label>
                </li>
                <li>
                  <input type="radio" name="payment" />
                  <label className="paytm">
                    <img src={paytm} />
                    <span>Paytm</span>
                  </label>
                </li>
                <li>
                  <input type="radio" name="payment" />
                  <label className="payu">
                    <img src={payU} />
                    <span>PayU Money</span>
                  </label>
                </li>
                <li>
                  <input type="radio" name="payment" />
                  <label className="cod">
                    <img src={cashon} />
                    <span>C.O.D</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="row listBox">
            <ul className="voucherOut">
              <li className="img">
                <img src={voucher} />
              </li>
              <li className="text">Type Promo Code :</li>
              <li className="input">
                <input
                  type="text"
                  name="last_name"
                  defaultValue={coupon}
                  onChange={(e, value, d) => {
                    setCoupon(e.target.value);
                  }}
                />
              </li>

              <li className="btnClass">
                <button
                  onClick={(e) => {
                    couponApply();
                  }}
                >
                  Apply
                </button>
              </li>
            </ul>
          </div>

          <div className="summarRight">
            <h3>Payment Summary</h3>
            <ul>
              <li>
                <span>Item total</span>
                <b key="item_total">
                  ₹ {paymentDetails ? paymentDetails?.item_total : ""}
                </b>
              </li>
              {paymentDetails?.is_tax_applied == true ? (
                <li>
                  <span>Tax</span>
                  <b key="item_total1">₹ {paymentDetails?.tax_amount || ""}</b>
                </li>
              ) : (
                ""
              )}

              {paymentDetails?.is_coupon_applied == true ? (
                <li key={paymentDetails.total}>
                  <span key={paymentDetails.total}>
                    {"Coupon Discount ("+ paymentDetails?.coupon_data.coupon_code+")"} 
                  </span>
                  <b key={paymentDetails?.total}>
                    - ₹ {paymentDetails?.total ?? ""}
                  </b>
                </li>
              ) : (
                ""
              )}

              <li className="totalPrice">
                <span>Total</span>
                <b>₹ {paymentDetails?.total}</b>
              </li>
              <li>
                <span>{"80% amount after service"}</span>
                <b>- ₹ {paymentDetails?.second_installment ?? ""} </b>
              </li>

              <li className="totalPrice">
                <span>Amount to be Pay Now</span>
                <b>₹ {paymentDetails?.first_installment ?? ""} </b>
              </li>
            </ul>
          </div>
          <div className="detOpFoot">
            <div className="col priceNum">
              <div className="totalPrice">
                Total :
                <span className="price" key="item_total5">
                  {serviceDetails?.currency?.symbol + " " + paymentDetails?.total}
                </span>
              </div>
            </div>
            <div className="col rightDet">
              <button
                style={{ cursor: "pointer" }}
                // onClick={() => dispatch(showConfirmBooking(true))}
                onClick={() => handleBookService()}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    </>
  );
};

export default PaymentOption;
