import React,{useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDetails } from "../../../Slices/home";

import { Link} from "react-router-dom";
import { toast } from "react-toastify";
import "./Button.scss";

const Button = ({ serviceId, car_id }) => {
  // 
  const dispatch = useDispatch();
  const { user_car_id_default } = useSelector((state) => state.auth);

  const data = {
    id: serviceId,
    code: "103",
    car_id:53 // changed
  };

  return (
    <> 
    <Link  to={`/ServiceDetail`}>
      <button
        className="btn btn-sm serFilBTn book-button"
        onClick={() => {
          dispatch(getServiceDetails(data))
        }}>
        BOOK
      </button> 
      </Link>
    </>
  );
};

export default Button;
