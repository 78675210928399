import React, { useEffect, useState } from 'react'
import './ChooseProvider.scss';
import { useDispatch, useSelector } from "react-redux";
import clock from "../../Assets/img/clock.png";
import { useNavigate,Link } from 'react-router-dom';
// import { GetSpList } from "../../../Slices/auth";
import { toast } from "react-toastify";
import { GetSpList, handleServiceProvider } from '../../Slices/auth';

export default function ChooseProvider() {
    const [SPlist, SelectSP_list] = useState([]);
    const [provider,setProvider] = useState();
    const [loading,setLoading]=useState(true);
    const { serviceDetails } = useSelector((state) => state.home);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
      const formData = new FormData();
      formData.append("service_id", serviceDetails.id);
      dispatch(GetSpList(formData))
        .then((response) => {
          console.log(response.payload.result.data,"response.payload.result.data")
          SelectSP_list([...response.payload.result.data]);
          setLoading(false)
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(err);
        });
    },[]);    


  return (
    <section className="container service-details"> 
    <div className="service-details-title">
      <i class="fa fa-angle-left" onClick={() => navigate(-1)}></i>
      <span className="service-details-title-text">Service Provider</span>
    </div>

    <div className="service-details-body">
      <div className="row">
          <div className="col-12 col-lg-5">
              <div className="">
                  <img className="service-details-img" src={serviceDetails?.service_image_url} alt="" />
              </div>
              <div className='service-details-information marginTop-19'>
                <div className="service-details-info">
                    <h6 className="service-details-name">{serviceDetails?.service_name}</h6>
                    <p className="service-details-description">{serviceDetails?.description}</p>
                </div>
                <div className="service-details-price-box">
                {
                      serviceDetails?.offer_price ?
                      <><span className="service-details-price">{serviceDetails?.currency?.symbol} {" "} {serviceDetails?.offer_price}</span></>
                      :
                      <></>
                    }
                    {
                      serviceDetails?.price ?
                      <><span className="service-details-mrp">{serviceDetails?.currency?.symbol} {" "} {serviceDetails?.price}</span></> :
                      <></>
                    }
                    {
                    serviceDetails?.offer_discount_price ? 
                      <><span className="service-details-save-price">Save {serviceDetails?.currency?.symbol} {serviceDetails?.offer_discount_price}</span></>
                      : <></>
                    }
                </div>
                <div className="service-details-time">
                    <img src={clock} alt="" width={15} height={15}/>
                    <span className="time">{serviceDetails?.duration} Minutes.</span>
                </div>
              </div>
          </div>
          <div className="col-12 col-lg-7 choose-provider">
                <div className='choose-provider-item-list'>
                {
                    SPlist && SPlist.length === 0 ? 
                    
                      loading ?  
                      <>
                        <h3 className='name'>Loading...</h3>
                      </>
                      :
                      <h3 className='name'>No service provider found.</h3>
                    :
                   SPlist.map((item,index)=>{
                        return( 
                            <div className={`choose-provider-item ${item.users.id === provider ? `active-border` : `white-border`}`} 
                             onClick={()=>{  setProvider(item.users.id); dispatch(handleServiceProvider(item.users.id)); } }
                             key={item?.id}>
                                <img src={item?.users?.business_id_url} alt="" className='choose-provider-item-img'/>
                                <div className='choose-provider-item-info'>
                                    <h3 className='name'>{item?.users?.name}</h3>
                                    <p className='info'>{item?.users?.email}</p>
                                    <span className='distance'>{item?.users?.country_code} {item?.users?.mobile_no}</span>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
                <div className='choose-provider-footer'>
                   <span className='choose-provider-price'>Total : {serviceDetails?.currency?.symbol} {serviceDetails?.offer_price}</span>
                   <Link className={`${provider >= 0 ?'' : 'btn-disabled' }`} to={"/packageDetailSchedule"} onClick={()=>{ }}>
                        <button className={`next-button`}>NEXT</button>
                   </Link>
                </div>
          </div>
      </div>
    </div>
  </section>
  )
}
