import axios from "axios";
// import config from "../Config/Config.json"
import authHeader from "./auth-header";
let config={
    CARWASH_URL:process.env.REACT_APP_BASE_URL
}

const userSignIn = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/signIn", data)
}

const checkUserExist = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/check_exists", data)
}

const registerUser = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/signup", data)
}

const countryList = () => {
    return axios.get(config.CARWASH_URL + "/v1/country_list")
}

const userProfile = () => {
    return axios.get(config.CARWASH_URL + "/v1/profile", {headers: authHeader()})
}

const editProfile = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/profile/edit", data, {headers: authHeader()})
}

const addressList = () => {
    return axios.get(config.CARWASH_URL + "/v1/user-address/all", {headers: authHeader()})
}

const deleteAddress = (id) => {
    return axios.delete(config.CARWASH_URL + `/v1/user-address/delete/${id}`, {headers: authHeader()})
}

const addAddress = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/user-address/add", data, {headers: authHeader()})
}

const editAddress = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/user-address/edit", data, {headers: authHeader()})
}

const getAllCars = () => {
    return axios.get(config.CARWASH_URL + "/v1/car-detail/all", {headers: authHeader()})
}

const deleteCars = (id) => {
    return axios.delete(config.CARWASH_URL + `/v1/car-detail/delete/${id}`, {headers: authHeader()})
}

const addCars = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/car-detail/add", data, {headers: authHeader()})
}

const logout = () => {
    return axios.post(config.CARWASH_URL + "/v1/logout", null, {headers: authHeader()})
}

const getOtp = (data) => {
    return axios.post(config.SERVICE_MARKETPLACE + "/signIn", data)
}

const verifyOtp = (data) => {
    return axios.post(config.SERVICE_MARKETPLACE + "/verifyOTP", data)
}

const bookService = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/bookings/add", data, {headers: authHeader()})
}

const getSpList = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/bookings/get/sp/list", data, {headers: authHeader()})
}

const bookpackageService = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/package/bookings/add", data, {headers: authHeader()})
}

const packageService = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/package/payment/summary", data, {headers: authHeader()})
}

const getpackageService = (data) => {
    return axios.get(config.CARWASH_URL + `/v1/package/detail/${data.package_id}?car_id=${data.car_id}&country_id=103`,{headers: authHeader()})
}

const getBookingList = (data) => {
    return axios.get(config.CARWASH_URL + `/v1/bookings/list?booking_type=${data}`, {headers: authHeader()})
}
const getBookingDetails = (data) => {
    return axios.get(config.CARWASH_URL + `/v1/bookings/detail/${data}`, {headers: authHeader()})
}
const getPackageDetails = (data) => {
    return axios.get(config.CARWASH_URL + `/v1/package/bookings/detail/${data}`, {headers: authHeader()})
}
const getpackageBookingList = (data) => {
    return axios.get(config.CARWASH_URL + `/v1/package/bookings/list?booking_type=${data}`, {headers: authHeader()})
}

const getUserFavoriteList = () => {
    return axios.get(config.CARWASH_URL + "/v1/favorites/all", {headers: authHeader()})
}

const addFavorite = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/favorites/add", data, {headers: authHeader()})
}

const removeFavorite = (data) => {
    return axios.delete(config.CARWASH_URL + `/v1/favorites/delete/${data}`, {headers: authHeader()})
}

const getScheduleSlots = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/slots", data)
}
const addPaymentSummaryDetails = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/payment/summary", data,{headers: authHeader()})
}

const addCouponApply = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/coupon/apply", data,{headers: authHeader()})
}

const setCancelRequst = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/sp/bookings/cancel", data,{headers: authHeader()})
}

const payRemainPayment = (data) => {
    return axios.post(config.CARWASH_URL + "/v1/bookings/complete/remain/payment", data,{headers: authHeader()})
}

const authService = {
    userSignIn,
    checkUserExist,
    countryList,
    userProfile,
    editProfile,
    addressList,
    deleteAddress,
    addAddress,
    editAddress,
    getAllCars,
    deleteCars,
    addCars,
    logout,
    getOtp,
    verifyOtp,
    registerUser,
    bookService,
    getBookingList,
    getBookingDetails,
    getPackageDetails,
    getUserFavoriteList,
    addFavorite,
    removeFavorite,
    getScheduleSlots,
    addPaymentSummaryDetails,
    payRemainPayment,
    addCouponApply,
    setCancelRequst,
    packageService,
    bookpackageService,
    getSpList,
    getpackageService,
    getpackageBookingList
}

export default authService