import React from 'react';
import './TopSerices.scss';
import { Link } from 'react-router-dom';
import ProductBox from '../ProductBox/ProductBox'
import "react-slick/lib/slider";
import { List } from 'react-content-loader';
import Slider from "react-slick";
import { useTranslation, withTranslation, Trans } from 'react-i18next';

const TopSerices = ({ dashboardSections, isDashboardSectionsFetched, sections}) => {

  const settings = {  
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:1,
    slidesToScroll: 1,
    className: "pwaSlide",
    responsive: [
    
       {
         breakpoint: 767,
         settings: {
           slidesToShow: 2,
           slidesToScroll: 2
         }
       },
       {
         breakpoint: 374,
         settings: {
         slidesToShow: 1,
           slidesToScroll: 1
         }
       }
     ]
  };
  const { t, i18n } = useTranslation();
  const data = [{
    id:1,
    title : "Full Car Deep Cleaning & Polish",
    info : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
    price: "299",
    mrp: "399",
    save_price: "100",
    img: require("../../Assets/img/service.png"),
    icon: require("../../Assets/img/clock.png")
  },
  {
    id:2,
    title : "Full Car Deep Cleaning & Polish",
    info : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
    price: "299",
    mrp: "399",
    save_price: "100",
    img: require("../../Assets/img/service.png"),
    icon: require("../../Assets/img/clock.png")
  },
  {
    id:3,
    title : "Full Car Deep Cleaning & Polish",
    info : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
    price: "299",
    mrp: "399",
    save_price: "100",
    img: require("../../Assets/img/service.png"),
    icon: require("../../Assets/img/clock.png")
  },
  {
    id:4,
    title : "Full Car Deep Cleaning & Polish",
    info : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
    price: "299",
    mrp: "399",
    save_price: "100",
    img: require("../../Assets/img/service.png"),
    icon: require("../../Assets/img/clock.png")
  },
  {
    id:5,
    title : "Full Car Deep Cleaning & Polish",
    info : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
    price: "299",
    mrp: "399",
    save_price: "100",
    img: require("../../Assets/img/service.png"),
    icon: require("../../Assets/img/clock.png")
  },
  {
    id:6,
    title : "Full Car Deep Cleaning & Polish",
    info : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
    price: "299",
    mrp: "399",
    save_price: "100",
    img: require("../../Assets/img/service.png"),
    icon: require("../../Assets/img/clock.png")
  },
  {
    id:7,
    title : "Full Car Deep Cleaning & Polish",
    info : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
    price: "299",
    mrp: "399",
    save_price: "100",
    img: require("../../Assets/img/service.png"),
    icon: require("../../Assets/img/clock.png")
  },
  {
    id:8,
    title : "Full Car Deep Cleaning & Polish",
    info : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has…",
    price: "299",
    mrp: "399",
    save_price: "100",
    img: require("../../Assets/img/service.png"),
    icon: require("../../Assets/img/clock.png")
  }];

  return (
    <>

    {/* <div className='service-box'>
      <div className='container'>
        <hr className="hr"/>
          <div className="section-title-box">
              <h2 className='sectionTitle centerText'>Top Trending <span>Services</span>
              </h2>
              <span className='sectionSeeAll centerText'>
                <Link className="see-all-text" to={"/allSpecialities"}>SEE ALL</Link>
              </span>
          </div>
          <hr className="hr"/>
      </div>
      <div className='container'>
      <div className='box'>
          <div className='box-item-list row'>
              {data.map((item,index) => (
                  <>  
                    <div className='box-item col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2'>
                              <img src={item.img} alt="" />
                              <div className='title-box'>
                                <h3 className='title'>{item.title}</h3>
                                <p className='card-info'>{item.info}</p>
                              </div>

                              <div className='price-box'>
                                  <span className='price'>₹ {item.price}</span>
                                  {item.mrp ? <> <span className='mrp'>₹ {item.mrp}</span></> : <></>}
                                  {item.save_price ? <> <span className='save-price'>₹ {item.save_price}</span></> : <></>}
                              </div>
                              <div className='book-box'>
                                  <div className='time-box'>
                                    <img src={item.icon} alt="" />
                                    <span className='time'>35 Minutes</span></div>
                                  <div>
                                    <Link  to={"/"} className="book-button">
                                      BOOK
                                    </Link>
                                  </div>
                              </div>

                    </div>
                  </>
                ))}
                
          </div>
      </div>
      </div>
    </div> */}
      
    {sections && 
      <section className='mb-5'>
          {!isDashboardSectionsFetched ? <div className='container'> <List /> </div> : 
            dashboardSections.length === 0 ? <strong>{t('common.no_specialities_found')}</strong> : 
            dashboardSections?.map((section, key) => {
              return (
                <div className='producrCateIn spaceSect container mb-2' key={key}>
                  <div className="section-title-box">
                        <h2 className='sectionTitle centerText'> {section.section_title}
                        </h2>
                        <span className='sectionSeeAll centerText'>               
                          <Link className='see-all-text'  to={`/serviceListing/${section}`}>{t('common.see_all')}</Link>
                        </span>
                    </div>
                  <div className=''>
                    <div className='mySlider row'>
                    {/* <Slider {...settings} > */}
                        {section.dashboard_services?.map((services, key) => (
                      <div className='box-item col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2'>
                            <ProductBox
                              services={services}
                              key={key}
                            />
                        </div>
                        ))}
                        {/* </Slider> */}
                    </div>
                  </div>
                </div>
              );
          })
      }
      </section>
    }

    {!sections && 
      <section className='mb-5 mt-5'>
          {!isDashboardSectionsFetched ?  <div className='container'><List /></div> : 
          dashboardSections.dashboard_services.length === 0 ? <strong>{t('common.no_specialities_found')}</strong> : 
            <div className='producrCateIn spaceSect container'>
            <div className='container'>
                    
                      <div className="section-title-box">
                          <h2 className='sectionTitle centerText'>{dashboardSections.section_title}
                          </h2>
                          <span className='sectionSeeAll centerText'>
                          <Link className='see-all-text' to={`/serviceListing/${dashboardSections.id}`}>{t('common.see_all')}</Link>
                          </span>
                      </div>
                      <hr className="hr"/>
              </div>
              <div className=''>
                <div className='row'>
             
                    {dashboardSections.dashboard_services?.map((services, key) => (
                   <div className='box-item col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2'>
                      <ProductBox
                        services={services}
                        key={key}
                      />
                  </div>
                    ))}
                  </div>
              </div>
            </div>
          }
        </section> 
    } 

    </>
  );
}

export default TopSerices
